import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import clsx from 'clsx';
import type { ReactNode } from 'react';

type Fit = 'small' | 'medium' | 'large';

export type TooltipProps = TooltipPrimitive.TooltipProps & {
    align?: TooltipPrimitive.TooltipContentProps['align'];
    appearance?: 'default' | 'inverse';
    className?: string;
    content: ReactNode;
    fit?: Fit;
    preserveLineBreaks?: boolean;
    side?: TooltipPrimitive.TooltipContentProps['side'];
};

export const Tooltip = ({
    appearance = 'default',
    children,
    className,
    content,
    defaultOpen,
    fit = 'medium',
    onOpenChange,
    open,
    preserveLineBreaks = true,
    side = 'top',
    ...props
}: TooltipProps) => {
    const arrowOffset = '-bottom-[1px]';
    return (
        <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange}>
            <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
            <TooltipPrimitive.Portal>
                <TooltipPrimitive.Content
                    side={side}
                    align="center"
                    sideOffset={10}
                    collisionPadding={10}
                    {...props}
                >
                    <div
                        className={clsx('group absolute z-0 size-full bg-white shadow', {
                            'border-gray-500 bg-white text-gray-900': appearance === 'default',
                            'border-gray-700 bg-gray-700 text-white': appearance === 'inverse',
                            'rounded-sm': fit === 'small',
                            rounded: fit === 'medium',
                            'rounded-md': fit === 'large',
                        })}
                    ></div>
                    <TooltipPrimitive.Arrow
                        className={`${arrowOffset} z-10 [&>*]:fill-gray-50 [&>polygon]:stroke-gray-100 [&>polygon]:drop-shadow-[0_1px_1px_rgba(0,0,0,0.1)]`}
                    />
                    <div
                        className={clsx(className, 'relative z-20 bg-white', {
                            'whitespace-pre-wrap': preserveLineBreaks,
                            'rounded-sm px-2 py-1': fit === 'small',
                            'rounded px-3 py-2': fit === 'medium',
                            'rounded-md px-4 py-3': fit === 'large',
                        })}
                    >
                        {content}
                    </div>
                </TooltipPrimitive.Content>
            </TooltipPrimitive.Portal>
        </TooltipPrimitive.Root>
    );
};

export { TooltipProvider } from '@radix-ui/react-tooltip';
