import { SvgArrowRight } from '@maersk-global/apmt-react-icons';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

type LinkProps = {
    href: string;
    iconPosition?: 'before' | 'after';
};

export const Anchor = ({
    href,
    children,
    iconPosition = 'before',
}: PropsWithChildren<LinkProps>) => {
    return (
        <Link to={href} className="flex items-center">
            {iconPosition === 'before' && <SvgArrowRight className="mr-2 text-2xl" />}
            {children}
            {iconPosition === 'after' && <SvgArrowRight className="ml-2 text-2xl" />}
        </Link>
    );
};
