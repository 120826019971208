import { useShallow } from 'zustand/shallow';

import { useTruckStatusStore } from '@/store/truckStatus';
import { TerminalTruckStatus } from '@/types';

export const useTruckStatusPerQuayCrane = (
    quayCraneName: string,
): TerminalTruckStatus[] | undefined => {
    const trucksPerQuayCrane = useTruckStatusStore(useShallow(store => store.trucksPerQuayCrane));
    return trucksPerQuayCrane[quayCraneName];
};
