import { PropsWithChildren } from 'react';

export const ApplicationError = ({ children }: PropsWithChildren) => {
    return (
        <div className="p-8">
            <div className="mb-4 rounded-lg bg-red-50 p-4 text-sm text-red-800 dark:bg-gray-800 dark:text-red-400">
                {children}
            </div>
        </div>
    );
};
