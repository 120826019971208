import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowRight(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="m14 9.23-3.325-2.85.65-.76 3.326 2.85a1 1 0 0 1 .349.76v1.54a1 1 0 0 1-.35.76l-3.325 2.85-.65-.76L14 10.77v-.27H5v-1h9v-.27z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowRight);
export default ForwardRef;
