import { PropsWithChildren } from 'react';

import { cn } from '@/utils/cn';

export const GridOfCards = ({
    as = 'ul',
    children,
    className,
}: PropsWithChildren<{
    as?: keyof Pick<HTMLElementTagNameMap, 'ul' | 'ol' | 'div'>;
    className?: string;
}>) => {
    const Node = as;

    return (
        <Node
            className={cn(
                'grid grid-cols-1 gap-4',
                'md:grid-cols-2',
                'lg:grid-cols-3',
                '2xl:grid-cols-4',
                '4xl:grid-cols-5',
                '5xl:grid-cols-6',
                className,
            )}
        >
            {children}
        </Node>
    );
};
