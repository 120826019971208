import { PropsWithChildren } from 'react';

import { cn } from '@/utils/cn';

export const ErrorComponent = ({
    children,
    className,
}: PropsWithChildren<{
    className?: string;
}>) => {
    return (
        <div className={cn('rounded bg-feedback-danger-light px-4 py-3 text-sm', className)}>
            {children}
        </div>
    );
};
