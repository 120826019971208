import { Codec, GetType, Maybe } from 'purify-ts';

import { stringToNumberDecoder } from './util/stringToNumber';

export const coordinateDecoder = Codec.interface({
    latitude: stringToNumberDecoder,
    longitude: stringToNumberDecoder,
});

export type Coordinate = GetType<typeof coordinateDecoder>;

export const hasInaccurateSignal = (now: number, hdop?: number): boolean => {
    return Maybe.fromNullable(hdop)
        .map(hdop => hdop > 2.5)
        .orDefault(false);
};

export const hasStaleSignal = (
    now: number,
    decodedGpsSignalStaleAfterMinutes: number,
    updatedAt?: string,
): boolean => {
    return Maybe.fromNullable(updatedAt)
        .map(u => new Date(u))
        .map(u => now - u.getTime())
        .map(diff => diff > 60 * 1000 * decodedGpsSignalStaleAfterMinutes)
        .orDefault(false);
};
