import { YardStackingEquipmentService } from '@maersk-global/digital-pull-operating-system-spec';
import { array } from 'purify-ts';

import { yardStackingEquipment } from '@/types/yardStackingEquipment';
import { safeDecode } from '@/utils/safeCodec';

export const getAllYardStackingEquipment = async (terminalId: string) => {
    const response = await YardStackingEquipmentService.getAllYardStackingEquipment({
        terminalId,
    });
    return safeDecode(array(yardStackingEquipment), response).caseOf({
        Left: e => {
            throw new Error(
                `Error fetching yard stacking equipment for terminal [${terminalId}]: ${e}`,
            );
        },
        Right: r => r,
    });
};
