import { Codec, array, number, string } from 'purify-ts';

import { coordinateDecoder } from '@/types/coordinates';
import { optionalNullToUndefined } from '@/types/util/optionalNullToUndefined';

export const routesUpdateMetaDecoder = Codec.interface({
    quayCraneName: optionalNullToUndefined(string),
    standByDistance: optionalNullToUndefined(number),
    standByRoute: optionalNullToUndefined(array(coordinateDecoder)),
    underCraneDistance: optionalNullToUndefined(number),
    underCraneRoute: optionalNullToUndefined(array(coordinateDecoder)),
});
