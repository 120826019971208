import { SvgPause } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/shallow';

import { FloatingBlock } from '@/components/atoms/FloatingBlock/FloatingBlock';
import { FloatingBlocksContainer } from '@/components/atoms/FloatingBlocksContainer/FloatingBlocksContainer';
import { useDelayCodeStore } from '@/store/delayCodesStore';
import { QuayCranes } from '@/types';
import { naturalSort } from '@/utils/naturalSort';

export const FloatingDelayCodes = ({ quayCranes }: { quayCranes: QuayCranes }) => {
    const { t } = useTranslation();

    const allDelayCodes = useDelayCodeStore(useShallow(state => state.delayCodes));
    const delayCodesForSelectedQuayCranes = Object.entries(allDelayCodes)
        .filter(([quayCraneName]) =>
            quayCranes.map(({ quayCraneId }) => quayCraneId).includes(quayCraneName),
        )
        .filter(item => {
            const [, data] = item;

            return data.delayCodes.length > 0;
        })
        .sort(([quayCraneNameA], [quayCraneNameB]) => naturalSort(quayCraneNameA, quayCraneNameB));

    return (
        <>
            {delayCodesForSelectedQuayCranes.length > 0 && (
                <FloatingBlocksContainer testid="delay-codes">
                    {delayCodesForSelectedQuayCranes.map(([quayCraneName, data]) => {
                        const { delayCodes } = data;

                        return (
                            <span key={quayCraneName} className="flex flex-col gap-2">
                                {delayCodes.map((delayCode, index) => {
                                    const { code, description } = delayCode;

                                    return (
                                        <FloatingBlock
                                            key={`${code}=${index}`}
                                            className="bg-feedback-warning-light"
                                            icon={
                                                <SvgPause className="size-6 stroke-feedback-warning" />
                                            }
                                            testid="delay-code"
                                        >
                                            <strong>
                                                {quayCraneName} - {description}
                                            </strong>
                                            <span className="-mx-1 inline-block">-</span>
                                            <span>
                                                {t('labels.delayCode').toLowerCase()} {code}
                                            </span>
                                        </FloatingBlock>
                                    );
                                })}
                            </span>
                        );
                    })}
                </FloatingBlocksContainer>
            )}
        </>
    );
};
