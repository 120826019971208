import { FlowPositionEnum } from '@maersk-global/digital-pull-operating-system-spec';

import { FlowStatusOption } from '@/constants/enums';
import { WorkQueues, YardWorkInstructions } from '@/types';

import { calculateFlowStatus } from './calculateFlowStatus';

export type FlowStatus = {
    flowStatus: FlowStatusOption;
};

export const getFlowStatus = (workQueues: WorkQueues[]): FlowStatus => {
    const instructionsForThisQuayCrane = workQueues.map(wq => wq.instructions).flat(1);
    const flowStatus = calculateFlowStatus(instructionsForThisQuayCrane);

    return {
        flowStatus,
    };
};

export const yardStatus = (yardInstructions: YardWorkInstructions): FlowStatusOption => {
    const pulls = yardInstructions.jobs.filter(
        job => job.type !== 'ExternalJob' && job.flowPosition === FlowPositionEnum.PULL,
    ).length;
    const standby = yardInstructions.jobs.filter(
        job => job.type !== 'ExternalJob' && job.flowPosition === FlowPositionEnum.STANDBY,
    ).length;
    const uc = yardInstructions.jobs.filter(
        job => job.type !== 'ExternalJob' && job.flowPosition === FlowPositionEnum.UNDER_CRANE,
    ).length;
    return yardStatusForAmounts(pulls, standby, uc);
};

export const yardStatusForYardWork = (
    yardWork: { flowPosition: FlowPositionEnum }[],
): FlowStatusOption => {
    const pulls = yardWork.filter(job => job.flowPosition === FlowPositionEnum.PULL).length;
    const standby = yardWork.filter(job => job.flowPosition === FlowPositionEnum.STANDBY).length;
    const uc = yardWork.filter(job => job.flowPosition === FlowPositionEnum.UNDER_CRANE).length;

    return yardStatusForAmounts(pulls, standby, uc);
};

const yardStatusForAmounts = (
    pull: number,
    standby: number,
    underCrane: number,
): FlowStatusOption => {
    if (underCrane === 0) {
        return 'STOPPED';
    }
    if (pull === 0) {
        return 'RISK';
    }
    if (pull >= 3) {
        return 'OVER_DEPLOYED';
    }
    return 'ACTIVE';
};
