import type { PropsWithChildren, ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

interface CriticalAlertState {
    criticalAlert: ReactNode | null;
    setCriticalAlert: (criticalAlert: ReactNode) => void;
}

const CriticalAlertContext = createContext<CriticalAlertState | null>(null);

export const CriticalAlertProvider = ({ children }: PropsWithChildren) => {
    const [criticalAlert, setCriticalAlert] = useState<ReactNode>(null);

    const state: CriticalAlertState = {
        criticalAlert,
        setCriticalAlert,
    };
    return <CriticalAlertContext.Provider value={state}>{children}</CriticalAlertContext.Provider>;
};

export const useCriticalAlert = () => {
    const context = useContext(CriticalAlertContext);
    if (!context) {
        throw new Error('useCriticalAlert must be used within a CriticalAlertProvider');
    }
    return context;
};
