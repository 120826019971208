import { Codec, array, string, Either, oneOf, exactly } from 'purify-ts';

import { SortState } from '@/store';
const qcOrRtcId = Codec.interface({
    kind: oneOf([exactly('qc'), exactly('rtg')]),
    id: string,
});
const sortStateDecoder = Codec.interface({
    root: array(qcOrRtcId),
    container1: array(qcOrRtcId),
    container2: array(qcOrRtcId),
    container3: array(qcOrRtcId),
});

const defaultSortState = {
    root: [],
    container1: [],
    container2: [],
    container3: [],
};

export const convertSortStateToSearchParams = (sortState: SortState): string => {
    return JSON.stringify(sortState);
};

export const convertSearchParamsToSortState = (searchParams: string): SortState => {
    return Either.encase(() => JSON.parse(searchParams))
        .chain(sortStateDecoder.decode)
        .orDefault(defaultSortState);
};
