import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiCheckCircle(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M10 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm-8 7a8 8 0 1 1 16 0 8 8 0 0 1-16 0zm11.425-2.487-3.103 5.013a1 1 0 0 1-.85.474h-.727a1 1 0 0 1-.79-.386l-1.6-2.057.79-.614L8.745 12h.726l3.104-5.013.85.526z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiCheckCircle);
export default ForwardRef;
