import { Button } from '@maersk-global/apmt-react-components';
import {
    SvgArrowAntiClockwise,
    SvgBarsHorizontalFunnelDown,
} from '@maersk-global/apmt-react-icons';
import type { PropsWithChildren } from 'react';

import { FiltersSection } from '../FiltersSection/FiltersSection';

type FiltersPropsOptional = {
    showResetButton: false;
};

type FiltersPropsRequired = {
    showResetButton: true;
    resetButtonLabel: string;
    handleReset: () => void;
};

type FiltersProps = PropsWithChildren<FiltersPropsOptional | FiltersPropsRequired>;

export const FiltersBar = (props: FiltersProps) => {
    const { showResetButton, children } = props;

    return (
        <div className="flex items-center gap-3">
            <FiltersSection>
                <SvgBarsHorizontalFunnelDown />
            </FiltersSection>
            <FiltersSection className="divide-x divide-gray-300">{children}</FiltersSection>
            {showResetButton && (
                <FiltersSection>
                    <Button
                        variant="plainAlt"
                        fit="small"
                        onClick={props.handleReset}
                        icon={<SvgArrowAntiClockwise />}
                    >
                        {props.resetButtonLabel}
                    </Button>
                </FiltersSection>
            )}
        </div>
    );
};
