import {
    SvgCheckCircle,
    SvgCircleSlash,
    SvgExclamationOctagon,
    SvgExclamationTriangle,
    SvgInfoCircle,
} from '@maersk-global/apmt-react-icons';

import { cn } from '@/utils/cn';

const fitClass = {
    small: 'px-3 gap-2 leading-3',
    medium: 'gap-3 leading-6 px-4 py-1',
} as const;

const appearanceClass = {
    error: 'bg-feedback-danger-light',
    warning: 'bg-feedback-warning-light text-feedback-warning-weak-text',
    success: 'bg-feedback-success-light',
    info: 'bg-feedback-info-light',
    cancel: 'bg-feedback-warning-weak',
} as const;

const iconClass = {
    error: <SvgExclamationOctagon className="w-5 text-xl" />,
    warning: <SvgExclamationTriangle className="w-5 text-xl" />,
    success: <SvgCheckCircle className="w-5 text-xl" />,
    info: <SvgInfoCircle className="w-5 text-xl" />,
    cancel: <SvgCircleSlash className="w-5 text-xl" />,
} as const;

type Appearance = keyof typeof appearanceClass;
type Fit = keyof typeof fitClass;

type NotificationProps = {
    body: React.ReactNode;
    heading?: string;
    fit?: Fit;
    appearance?: Appearance;
    icon?: boolean;
    className?: string;
};

export const Notification = ({
    heading,
    body,
    appearance = 'info',
    fit = 'medium',
    icon = true,
    className,
}: NotificationProps) => {
    return (
        <div className="flex flex-col">
            <div
                className={cn(
                    'box-border flex h-auto w-auto flex-row justify-start rounded align-top text-sm',
                    appearanceClass[appearance],
                    fitClass[fit],
                    className,
                )}
            >
                <div className={cn({ 'mt-2': heading }, 'flex gap-2 items-center')}>
                    {icon && iconClass[appearance]}
                </div>
                <div className={cn({ 'mt-2': heading }, 'flex-col')}>
                    {heading && <h3 className="gap-2 font-bold">{heading}</h3>}
                    <div>{body}</div>
                </div>
            </div>
        </div>
    );
};
