export const ProgressDots = (value: string) => {
    return (
        <>
            <span className="flex items-center">
                <span className="relative inline-flex size-5 items-center">
                    <span className="absolute left-px size-1 animate-dot-1 rounded bg-blue-600"></span>
                    <span className="absolute left-[7px] size-1 animate-dot-2 rounded bg-blue-600"></span>
                    <span className="absolute left-[13px] size-1 animate-dot-3 rounded bg-blue-600"></span>
                </span>
            </span>
            <span className="font-sansNumeric">{value}</span>
        </>
    );
};
