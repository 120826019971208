import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { AndonEvent, Andons } from '@/types/andons';
import { organizeAndons } from '@/utils/organizeAndons';

type AndonsPerQuayCraneStore = {
    andons: Andons;
    updateAndons: (andons: AndonEvent) => void;
};
export const useAndonsStore = create<AndonsPerQuayCraneStore>()(
    devtools(
        set => {
            return {
                updateAndons: newAndon => {
                    set(state => {
                        const andons = organizeAndons(state.andons, newAndon);

                        return {
                            ...state,
                            andons,
                        };
                    });
                },
                andons: [],
            };
        },
        { name: 'andonsStore' },
    ),
);
