import { SvgCheck } from '@maersk-global/apmt-react-icons';

export const CheckMark = (value: string) => {
    return (
        <>
            <span className="flex size-5 items-center justify-center">
                <SvgCheck className="text-green-500" />
            </span>
            <span>{value}</span>
        </>
    );
};
