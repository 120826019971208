import { PropsWithChildren, ReactNode } from 'react';

import { cn } from '@/utils/cn';

const variantsClassNames = {
    active: {
        light: '',
        dark: '',
    },
    default: {
        light: 'bg-gray-200',
        dark: 'bg-gray-400',
    },
    warn: {
        light: 'bg-feedback-warning-light',
        dark: 'bg-feedback-warning',
    },
    error: {
        light: 'bg-feedback-danger-light',
        dark: 'bg-feedback-danger text-white',
    },
    info: {
        light: 'bg-feedback-info-light',
        dark: 'bg-blue-600 text-white',
    },
} as const;

const fitClassNames = {
    small: 'mds-font--small gap-1 px-2 py-[2px]',
    medium: 'mds-font--small gap-2 px-3 py-1',
    large: 'mds-font--default gap-2 px-3 py-[6px]',
} as const;

const shapeClassNames = {
    block: '',
    pill: 'rounded-full',
} as const;

export type Variants = keyof typeof variantsClassNames;
export type Fit = keyof typeof fitClassNames;
export type Shape = keyof typeof shapeClassNames;

export type LabelProps = PropsWithChildren<{
    fit?: Fit;
    variant?: Variants;
    shape?: Shape;
    weight?: 'light' | 'dark';
    className?: string;
    icon?: ReactNode;
}>;

export const Label = ({
    fit = 'small',
    variant = 'active',
    shape = 'block',
    weight = 'light',
    icon,
    className,
    children,
}: LabelProps) => {
    return (
        <div
            className={cn(
                'flex items-center',
                fitClassNames[fit],
                shapeClassNames[shape],
                className,
                {
                    [variantsClassNames[variant].light]: weight === 'light',
                    [variantsClassNames[variant].dark]: weight === 'dark',
                },
            )}
        >
            {icon && icon}
            {children}
        </div>
    );
};
