import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import {
    Button,
    Dialog,
    DialogCloseButton,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogTitle,
    DialogTopSection,
    DialogTrigger,
    InputTextArea,
} from '@maersk-global/apmt-react-components';
import { useState } from 'react';

import config from '@/config';
import {
    useAndonsStore,
    useDelayCodeStore,
    useQuayCraneStore,
    useTruckPositionsStore,
} from '@/store';
import { useTruckStatusStore } from '@/store/truckStatus';
export type DebugDownloadButton = {
    quayCraneName: string;
};

export function DebugDownloadButton({ quayCraneName: quayCraneName }: DebugDownloadButton) {
    const [open, setOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [frontendState, setFrontendState] = useState({});
    const [backendState, setBackendState] = useState<undefined | Promise<object>>(undefined);
    const [loading, setLoading] = useState(false);
    const auth = useAuth();

    const createSnapshot = () => {
        const frontendSnapshot = {
            quayCraneStore: useQuayCraneStore.getState(),
            andonStore: useAndonsStore.getState(),
            delayCodesStore: useDelayCodeStore.getState(),
            truckPositions: useTruckPositionsStore.getState(),
            trckStatus: useTruckStatusStore.getState(),
        };
        setFrontendState(frontendSnapshot);
        const requestPromise = getBackendState(auth?.fetchWithAuth ?? fetch);
        setBackendState(requestPromise);
    };
    return (
        <span className="inline-flex min-w-[100px] gap-2 text-left font-sansNumeric text-sm">
            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>
                    <Button
                        variant="plain"
                        onClick={() => {
                            createSnapshot();
                        }}
                    >
                        Download bug report
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <DialogTopSection>
                        <DialogTitle>Download a bug report</DialogTitle>
                        <DialogDescription>
                            Enter an optional description for the bug report:
                            <InputTextArea
                                onChange={evt => {
                                    const value = evt.target.value;
                                    setTitle(value);
                                }}
                                label=""
                                placeholder="Eg. TG17 is not in under crane position"
                                name="title"
                            />
                        </DialogDescription>
                    </DialogTopSection>
                    <DialogFooter>
                        <DialogCloseButton>Close</DialogCloseButton>
                        <Button
                            fit="small"
                            onClick={async () => {
                                setLoading(true);
                                await downloadJSObject(
                                    frontendState,
                                    backendState ?? Promise.resolve({}),
                                    title,
                                    quayCraneName,
                                );
                                setLoading(false);
                                setOpen(false);
                            }}
                            loading={loading}
                        >
                            Download
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </span>
    );
}

const downloadJSObject = async (
    frontendState: object,
    backendDataPromise: Promise<object>,
    title: string,
    quayCraneName: string,
) => {
    let backendState: object = {};
    try {
        backendState = await backendDataPromise;
    } catch (e) {
        backendState = { error: e };
    }

    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.cssText = 'display: none';
    const json = JSON.stringify({ frontendState, backendState, title, quayCraneName });
    const blob = new Blob([json], { type: 'octet/stream' });
    const url = window.URL.createObjectURL(blob);
    const now = new Date().toISOString();
    a.href = url;
    a.download = `DPOS_bug-report-${now}.json`;

    a.click();
    window.URL.revokeObjectURL(url);
};

const getBackendState = async (fetchWithAuth: typeof fetch) => {
    return fetchWithAuth(`${config.backendApiUrl()}/terminal-data`).then(response => {
        if (response.ok) {
            return response.json();
        } else {
            return Promise.resolve({ status: response.status, statusText: response.statusText });
        }
    });
};
