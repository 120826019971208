export enum Role {
    ReadOnly = "ReadOnly",
    Normal = "Normal",
    Admin = "Admin",
}

export type User = {
    email: string;
    name: string;
    roles: Role[];
    isAdmin: boolean;
    isNormal: boolean;
    isReadOnly: boolean;
};
