/* istanbul ignore file */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const debounce = <T extends (...args: any[]) => any>(callback: T, waitFor: number) => {
    let timeout: ReturnType<typeof setTimeout>;
    return (...args: Parameters<T>): void => {
        timeout && clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback(...args);
        }, waitFor);
    };
};
