import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiExclamationCircle(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M10 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm-8 7a8 8 0 1 1 16 0 8 8 0 0 1-16 0zm7.5 1V6h1v5h-1zm-.25 1.5h1.5V14h-1.5v-1.5z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiExclamationCircle);
export default ForwardRef;
