import { Codec, GetType, boolean, date, exactly, string } from 'purify-ts';

export const craneTriggerWarningUpdatedEventPayloadDecoder = Codec.interface({
    quayCraneId: string,
    active: boolean,
    interval: string,
    statusChangedTime: date,
});

export type CraneTriggerWarningUpdatedEventPayload = GetType<
    typeof craneTriggerWarningUpdatedEventPayloadDecoder
>;

export const craneTriggerWarningUpdatedEventDecoder = Codec.interface({
    event: exactly('CRANE_TRIGGER_WARNING_UPDATED'),
    data: craneTriggerWarningUpdatedEventPayloadDecoder,
});
