import {
    KeycloakAuthProvider,
    keycloakInit,
    createGroupsDecoder,
} from '@maersk-global/apmt-flow-keycloak';
import { Loader } from '@maersk-global/apmt-react-components';
import { OpenAPI } from '@maersk-global/digital-pull-operating-system-spec';
import Keycloak from 'keycloak-js';
import { Suspense, StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { Application } from '@/components/Application';

import './i18n';

import './styles/globals.scss';
import { getTerminalSettings, TerminalSettings } from './api';
import { ApplicationError } from './components/molecules/ApplicationError/ApplicationError';

(async () => {
    const settingsResult = await getTerminalSettings();
    settingsResult.caseOf({
        Left: error => {
            console.error('Could not load terminal settings', error);
            renderError();
        },
        Right: terminalSettings => {
            if (terminalSettings.useKeycloak) {
                console.info('Using keycloak');
                keycloakInit({
                    url: terminalSettings.keycloakUrl,
                    realm: 'DPOS',
                    clientId: 'dpos-spa',
                }).then(
                    keycloak => {
                        OpenAPI.TOKEN = () =>
                            keycloak.updateToken(5).then(() => {
                                if (!keycloak.token)
                                    throw Error('Missing access token on keycloak object');
                                return keycloak.token;
                            });

                        renderReactApp(terminalSettings, keycloak);
                    },
                    err => console.error('Failed to initialize keycloak adapter', err),
                );
            } else {
                console.info('Not using keycloak');
                renderReactApp(terminalSettings, undefined);
            }
        },
    });
})().catch(reason => console.error('Failed to load application', reason));

const renderReactApp = (terminalSettings: TerminalSettings, keycloak: Keycloak | undefined) => {
    const elm = document.getElementById('root') as HTMLElement;
    const root = ReactDOM.createRoot(elm);
    const groupsDecoder = createGroupsDecoder(terminalSettings.terminalId.toLowerCase());

    root.render(
        <StrictMode>
            <Suspense fallback={<Loader />}>
                <KeycloakAuthProvider keycloak={keycloak} groupsDecoder={groupsDecoder}>
                    <Application terminalSettings={terminalSettings} />
                </KeycloakAuthProvider>
            </Suspense>
        </StrictMode>,
    );
};

const renderError = () => {
    const elm = document.getElementById('root') as HTMLElement;
    const root = ReactDOM.createRoot(elm);

    root.render(
        <ApplicationError>
            DPOS is not correctly configured, please contact support at{' '}
            <a href="mailto:APMTDPOSL2Team@apmterminals.com">APMTDPOSL2Team@apmterminals.com</a>
        </ApplicationError>,
    );
};
