import { Maybe } from 'purify-ts';
import { useShallow } from 'zustand/shallow';

import { CheType } from '@/constants/enums';
import { CheMapData, useChePositionsStore } from '@/store/chePositions';
import { YardWorkInstructions } from '@/types';

import { YardStackingEquipmentMapMarker } from './YardStackingEquipmentMapMarker';

export const QuayCraneYardStackingEquipmentMarkers = ({
    quayCraneNames,
    yardStackingEquipment,
    onYardStackingEquipmentClicked,
    yardWorkInstructions,
}: {
    terminalId: string;
    quayCraneNames: string[];
    yardStackingEquipment: string[];
    onYardStackingEquipmentClicked?: (type: CheType, id: string) => void;
    yardWorkInstructions: { [che: string]: YardWorkInstructions };
}) => {
    const chePositions = useChePositionsStore(useShallow(state => state.positions));
    const rtgPositions = Maybe.fromNullable(
        Object.values(chePositions[CheType.RUBBER_TYRED_GANTRY]),
    );
    const rtgsWorkingForQCs: string[] = Object.values(yardWorkInstructions)
        .filter(ywi => {
            return ywi.jobs.some(
                j => j.type === 'InternalJob' && quayCraneNames.includes(j.serving),
            );
        })
        .map(ywi => {
            return ywi.che;
        });
    const rtgsToShow = [...new Set(rtgsWorkingForQCs.concat(yardStackingEquipment))];
    const ysesForQuayCrane: CheMapData[] = Maybe.catMaybes(
        rtgsToShow.map(rtg =>
            rtgPositions.chainNullable(pos =>
                pos.find(rtgPos => rtgPos.containerHandlingEquipmentId == rtg),
            ),
        ),
    );

    return (
        <>
            {ysesForQuayCrane.map(yse => (
                <YardStackingEquipmentMapMarker
                    key={`${yse.containerHandlingEquipmentType}-${yse.containerHandlingEquipmentId}`}
                    yardStackingEquipment={yse}
                    onYardStackingEquipmentClicked={
                        onYardStackingEquipmentClicked &&
                        (() =>
                            onYardStackingEquipmentClicked(
                                yse.containerHandlingEquipmentType,
                                yse.containerHandlingEquipmentId,
                            ))
                    }
                />
            ))}
        </>
    );
};
