import MiArrowClockwiseClock from '@maersk-global/apmt-react-icons/src/MiArrowClockwiseClock';

export const RemainingWorkInstructions = (props: { instructionsLeft: number }) => {
    return (
        <div className="mr-1 flex flex-row text-gray-600">
            <MiArrowClockwiseClock />
            <span className="mr-2 text-xs leading-5">
                <abbr className="mr-1 !no-underline" title="work instructions">
                    WIs:
                </abbr>
                {props.instructionsLeft}
            </span>
        </div>
    );
};
