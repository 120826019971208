import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@maersk-global/apmt-react-components';
import { useTranslation } from 'react-i18next';

import { TruckMapColors } from '@/constants/enums';
import { cn } from '@/utils/cn';

export const TerminalMapLegend = () => {
    const { t } = useTranslation();

    return (
        <Accordion
            type="single"
            collapsible={true}
            className="absolute bottom-2 left-2 z-10 bg-white"
        >
            <AccordionItem value="map-legend" className="mds-font--default text-base">
                <AccordionTrigger direction="up">
                    <span className="pl-1">{t('pages.map.legend.title')}</span>
                </AccordionTrigger>
                <AccordionContent className="px-5 py-2">
                    <ul>
                        <TerminalMapLegendItem
                            indicatorColor={TruckMapColors.BLUE}
                            label={t('pages.map.legend.load')}
                        />
                        <TerminalMapLegendItem
                            indicatorColor={TruckMapColors.LIGHT_BLUE}
                            label={t('pages.map.legend.discharge')}
                        />
                        <TerminalMapLegendItem
                            indicatorColor={TruckMapColors.GREEN}
                            label={t('pages.map.legend.completed')}
                        />
                        <TerminalMapLegendItem
                            indicatorColor={TruckMapColors.GRAY}
                            label={t('pages.map.legend.in_yard')}
                        />
                        <TerminalMapLegendItem
                            indicatorColor={TruckMapColors.RED}
                            label={t('pages.map.legend.unavailable')}
                        />
                    </ul>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export const TerminalMapLegendItem = ({
    label,
    indicatorColor = 'bg-pink-500',
}: {
    label: string;
    indicatorColor?: string;
}) => {
    return (
        <li className="mds-font--x-small relative mb-2 flex items-center">
            <svg
                height="20"
                width="20"
                className={cn('mr-2', indicatorColor)}
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="10" cy="10" r="10" fill="currentColor" strokeWidth="none" />
            </svg>
            {label}
        </li>
    );
};
