import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiTruckSide(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M0 4a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v6h2V6a1 1 0 0 1 1-1h3.26a1 1 0 0 1 .78.375l1.74 2.176a1 1 0 0 1 .22.624V14a1 1 0 0 1-1 1h-1.55a2.5 2.5 0 0 1-4.9 0h-5.1a2.5 2.5 0 0 1-4.9 0H1a1 1 0 0 1-1-1V4zm2.55 10a2.5 2.5 0 0 1 4.9 0h5.1a2.5 2.5 0 0 1 4.9 0H19v-3H1v3h1.55zM1 10h9V4H1v6zm18 0V8.175L17.26 6H14v4h5zM5 13a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm10 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiTruckSide);
export default ForwardRef;
