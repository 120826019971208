import { SvgArrowDown, SvgArrowsDownUp, SvgArrowUp } from '@maersk-global/apmt-react-icons';
import type {
    ColumnDef,
    Row,
    SortingState,
    TableOptions,
    VisibilityState,
} from '@tanstack/react-table';
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table';
import type { MouseEvent } from 'react';
import { useRef, useState } from 'react';

import { cn } from '../common/cn';
import { isDefined } from '../common/guards';
import { useIntersectionObserver } from '../common/hooks';

export const fitClass = {
    xsmall: 'py-px',
    small: 'py-[6px] px-3',
    medium: 'py-2 px-4',
    large: 'py-3 px-6',
} as const;

export type TableFit = keyof typeof fitClass;

export type MultiSelectTableProps<TData extends object> = Omit<
    TableProps<TData>,
    'enableRowSelection'
> & {
    enableRowSelection: true;
    multiSelectedData: Record<string, boolean>;
    onRowSelectionChange: TableOptions<TData>['onRowSelectionChange'];
};

export interface TableProps<TData extends object> {
    data: TData[];
    noDataMessage?: React.ReactNode;
    // TODO: Remove any from ColumnDef once issue is resolved: https://github.com/TanStack/table/issues/4382
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    columns: ColumnDef<TData, any>[];
    newRowId?: string | null;
    newRowIsPositive?: boolean;
    selectedRowId?: string | null;
    selectedRowClassName?: string;
    rowIdentifier?: keyof TData;
    fit?: TableFit;
    tableLayout?: 'auto' | 'fixed';
    defaultSorting?: SortingState;
    defaultVisibility?: VisibilityState;
    disableManualSorting?: boolean;
    onRowClicked?: (row: Row<TData>) => void;
    onMouseEnterRow?: (event: MouseEvent<HTMLTableRowElement>, row: Row<TData>) => void;
    onMouseLeaveRow?: () => void;
    onMouseEnterCell?: (event: MouseEvent<HTMLTableCellElement>, row: Row<TData>) => void;
    onMouseLeaveCell?: () => void;
    isSelected?: boolean;
    stickyHeader?: boolean;
    tableWrapperClassName?: string;
    tableClassName?: string;
    headerClassName?: string;
    rowClassName?: string;
    tableBodyClassName?: string;
    cellClassName?: string;
    enableRowSelection?: false;
    showNoDataMessageOnTable?: boolean;
}

export type props<TData extends object> = TableProps<TData> | MultiSelectTableProps<TData>;

export const SortIcon = ({ sortDirection }: { sortDirection: 'asc' | 'desc' | false }) => {
    switch (sortDirection) {
        case 'asc':
            return <SvgArrowUp className="text-xl" />;
        case 'desc':
            return <SvgArrowDown className="text-xl" />;
        case false:
            return <SvgArrowsDownUp className="text-xl" />;
    }
};

/**
 * Returns the rootMargin for the intersection observer based on the fit of the table.
 * This is used to determine to add extra classes to indicate a sticky header.
 * The values are based on the row height with some added pixels to make sure that is triggers on time.
 */
export const getRootMargin = (fit: TableFit) => {
    switch (fit) {
        case 'xsmall':
            return '-28px';
        case 'small':
            return '-35px';
        case 'medium':
            return '-39px';
        case 'large':
            return '-47px';
    }
};

export const Table = <TData extends object>({
    data,
    noDataMessage,
    columns,
    fit = 'small',
    tableLayout = 'auto',
    rowIdentifier = undefined,
    newRowId = null,
    newRowIsPositive = true,
    selectedRowId = null,
    selectedRowClassName,
    defaultSorting = [],
    defaultVisibility = {},
    disableManualSorting = false,
    onRowClicked: onRowClick,
    onMouseEnterRow: onMouseEnterRow,
    onMouseLeaveRow: onMouseLeaveRow,
    onMouseEnterCell: onMouseEnterCell,
    onMouseLeaveCell: onMouseLeaveCell,
    isSelected = false,
    tableWrapperClassName = '',
    tableClassName = '',
    headerClassName = '',
    rowClassName = '',
    tableBodyClassName = '',
    cellClassName = '',
    stickyHeader = false,
    disableHeader = false,
    showNoDataMessageOnTable = false,
    ...props
}: props<TData> & { disableHeader?: boolean }) => {
    // Local sorting state
    const [sorting, setSorting] = useState<SortingState>(defaultSorting);
    const [columnVisibility] = useState<VisibilityState>(defaultVisibility);
    const TableRef = useRef<HTMLTableSectionElement>(null);

    // Make it possible to change classNames when the head row is sticky
    const { setRef, entry } = useIntersectionObserver({
        threshold: 0,
        rootMargin: getRootMargin(fit),
        root: TableRef.current,
    });

    // If we pass a rowIdentifier, we override the default getRowId function and set the row ID as the value of the rowIdentifier
    // NB all other IDs are set by the table itself
    let getRowId = undefined;
    if (isDefined(rowIdentifier)) {
        getRowId = (originalRow: TData, _index: number, parent?: Row<TData>) => {
            return parent
                ? [parent.id, originalRow[rowIdentifier]].join('.')
                : `${originalRow[rowIdentifier]}`;
        };
    }

    // Disable sorting on all columns by default
    const columnsDisableSort = columns.map(column => {
        return {
            ...column,
            enableSorting: column.enableSorting ?? false,
        };
    });

    const table = useReactTable({
        data,
        enableRowSelection: props.enableRowSelection ?? false,
        columns: columnsDisableSort,
        getRowId,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        onRowSelectionChange: props.enableRowSelection ? props.onRowSelectionChange : undefined,
        enableSortingRemoval: false,
        state: {
            columnVisibility,
            sorting,
            rowSelection: props.enableRowSelection ? props.multiSelectedData : undefined,
        },
    });

    if (table.getRowModel().rows.length == 0 && noDataMessage && !showNoDataMessageOnTable) {
        return noDataMessage;
    }

    return (
        // wrapper div is needed to make the table scrollable
        <div
            className={cn(
                'relative max-h-full rounded border border-gray-300',
                isSelected ? 'border-blue-300' : 'border-gray-300',
                {
                    'overflow-auto': tableLayout === 'auto' && !stickyHeader,
                },
                tableWrapperClassName,
            )}
            ref={TableRef}
        >
            <table
                className={cn(
                    'mds-font--small w-full border-separate border-spacing-0',
                    {
                        'table-fixed': tableLayout === 'fixed',
                    },
                    tableClassName,
                )}
            >
                <thead
                    ref={setRef}
                    className={cn('text-left', {
                        'sticky top-0': stickyHeader,
                        'shadow-bottom': entry?.isIntersecting,
                    })}
                >
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                const { isPlaceholder, id, column } = header;
                                const canSort = disableManualSorting ? false : column.getCanSort();

                                return (
                                    <th
                                        className={cn(
                                            'whitespace-nowrap bg-gray-100 first:rounded-tl last:rounded-tr',
                                            fitClass[fit],

                                            {
                                                'box-border border-b border-t border-gray-300 first:rounded-none last:rounded-none':
                                                    entry?.isIntersecting,
                                            },
                                            headerClassName,
                                            { 'p-0 ': disableHeader },
                                        )}
                                        style={
                                            tableLayout === 'fixed' &&
                                            column.columnDef.size &&
                                            column.columnDef.size > -1
                                                ? { width: `${column.columnDef.size}px` }
                                                : {}
                                        }
                                        key={id}
                                    >
                                        {disableHeader ? null : isPlaceholder ? null : canSort ? (
                                            <button
                                                className="flex w-full cursor-pointer select-none gap-2"
                                                onClick={column.getToggleSortingHandler()}
                                            >
                                                {flexRender(
                                                    column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                                <SortIcon sortDirection={column.getIsSorted()} />
                                            </button>
                                        ) : (
                                            <div>
                                                {flexRender(
                                                    column.columnDef.header,
                                                    header.getContext(),
                                                )}
                                            </div>
                                        )}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody className={tableBodyClassName}>
                    {table.getRowModel().rows.map((row, rowIndex) => {
                        const isSelected = row.id === selectedRowId;
                        const isNewRow = row.id === newRowId;
                        const isNewRowPositive = isNewRow && newRowIsPositive;
                        const isNewRowNegative = isNewRow && !newRowIsPositive;
                        const hasRowClassNameOnRowLevel =
                            'rowClassName' in row.original && !isSelected
                                ? `${row.original.rowClassName}`
                                : '';

                        return (
                            <tr
                                key={row.id}
                                className={cn(
                                    rowClassName,
                                    isSelected ? selectedRowClassName || 'bg-blue-25' : 'bg-white',
                                    isNewRowPositive ? 'animate-newRowPositiveHighlight' : '',
                                    isNewRowNegative ? 'animate-newRowNegativeHighlight' : '',
                                    hasRowClassNameOnRowLevel,
                                )}
                                {...(onRowClick && {
                                    onClick: (event: MouseEvent<HTMLTableRowElement>) => {
                                        if (isAnchorOrButton(event.target as HTMLElement)) {
                                            return;
                                        }

                                        onRowClick(row);
                                    },
                                })}
                                {...(onMouseEnterRow && {
                                    onMouseEnter: event => onMouseEnterRow(event, row),
                                })}
                                {...(onMouseLeaveRow && { onMouseLeave: onMouseLeaveRow })}
                            >
                                {row.getVisibleCells().map(cell => (
                                    <td
                                        className={cn(
                                            'h-1 border-t border-gray-300 align-middle',
                                            onRowClick ? 'cursor-pointer' : '',
                                            fitClass[fit],
                                            cellClassName,
                                            { 'border-t-0': disableHeader && rowIndex === 0 },
                                        )}
                                        data-id={cell.id}
                                        key={cell.id}
                                        {...(onMouseEnterCell && {
                                            onMouseEnter: event => onMouseEnterCell(event, row),
                                        })}
                                        {...(onMouseLeaveCell && {
                                            onMouseLeave: onMouseLeaveCell,
                                        })}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                    {data.length === 0 && noDataMessage && showNoDataMessageOnTable && (
                        <tr>
                            <td
                                className={cn(
                                    'text-center',
                                    fitClass[fit],
                                    'border-t border-gray-300',
                                    cellClassName,
                                )}
                                colSpan={columns.length}
                            >
                                {noDataMessage}
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

const isAnchorOrButton = (target: HTMLElement) => {
    return target.nodeName === 'A' || target.nodeName === 'BUTTON';
};
