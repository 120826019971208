import { array, Codec, GetType } from 'purify-ts';

import { coordinateDecoder } from './coordinates';
import { optionalNullToUndefined } from './util/optionalNullToUndefined';

export const flowPositionsDecoder = Codec.interface({
    underQuayCrane: array(coordinateDecoder),
    standby: array(coordinateDecoder),
    pointOfNoReturn: optionalNullToUndefined(array(coordinateDecoder)),
    quayArea: optionalNullToUndefined(array(coordinateDecoder)),
});

export type FlowPositions = GetType<typeof flowPositionsDecoder>;
