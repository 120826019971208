import { QuayCraneService } from '@maersk-global/digital-pull-operating-system-spec';

import { PiecesOfEquipment, piecesOfEquipmentDecoder } from '@/types';
import { safeDecode } from '@/utils/safeCodec';

export const getAllYardStackingEquipmentWorkingForQuayCrane = async (
    terminalId: string,
    quayCraneName: string,
): Promise<PiecesOfEquipment> => {
    const response = await QuayCraneService.getAllYardStackingEquipmentWorkingForQuayCrane({
        terminalId,
        quayCraneId: quayCraneName,
    });
    return safeDecode(piecesOfEquipmentDecoder, response).caseOf({
        Left: e => {
            throw new Error(`Error fetching equipment for quay crane [${quayCraneName}]: ${e}`);
        },
        Right: r => r,
    });
};
