import { AndonTypeOption } from '@/constants/enums';
import { AndonEvent, Andons } from '@/types/andons';

export const organizeAndons = (andons: Andons, newAndon: AndonEvent): Andons => {
    return andons
        .filter(a => a.cheName !== newAndon.cheName)
        .concat([newAndon])
        .filter(a => a.andonType !== AndonTypeOption.NONE)
        .sort(sortAlphabetically);
};

const sortAlphabetically = (a: AndonEvent, b: AndonEvent) =>
    a.cheName.toLowerCase().localeCompare(b.andonType.toLowerCase());
