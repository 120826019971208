import type { ClassValue } from 'clsx';
import React from 'react';

import { cn } from '../../common/cn';

type NavigationRailProps = { children: React.ReactNode; className?: ClassValue };

export const NavigationRail = ({ children, className }: NavigationRailProps) => {
    return (
        <div className={cn('flex flex-col items-center text-center text-xs', className)}>
            {children}
        </div>
    );
};
