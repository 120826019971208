import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiRotate(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M10 2.94 8.126.832l.747-.664 1.874 2.108A1 1 0 0 1 11 2.94V4.1a1 1 0 0 1-.168.554L9.416 6.777l-.832-.554L10 4.099V4a6 6 0 0 0-2.77 11.324l-.461.887A7 7 0 0 1 10 3v-.06zM16 10a6 6 0 0 0-3.231-5.324l.462-.887A7 7 0 0 1 10 17v.06l1.873 2.108-.747.664-1.874-2.108A1 1 0 0 1 9 17.06V15.9a1 1 0 0 1 .168-.554l1.416-2.124.832.554L10 15.901V16a6 6 0 0 0 6-6z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiRotate);
export default ForwardRef;
