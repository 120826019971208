import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiCog(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M7.93 2.804A1 1 0 0 1 8.91 2h2.18a1 1 0 0 1 .98.804l.339 1.692s0 .002.002.003c.003.004.01.01.019.014.383.17.745.378 1.08.62a.05.05 0 0 0 .02.01h.003l1.7-.51a1 1 0 0 1 1.146.443l1.085 1.808a1 1 0 0 1-.2 1.267l-1.3 1.138v.003a.054.054 0 0 0-.003.024c.05.451.05.917 0 1.368-.001.01 0 .019.002.024 0 .002.002.003.002.003l1.3 1.137a1 1 0 0 1 .199 1.268l-1.085 1.808a1 1 0 0 1-1.145.443l-1.7-.51h-.004a.05.05 0 0 0-.02.01 5.995 5.995 0 0 1-1.08.62.05.05 0 0 0-.019.014.02.02 0 0 0-.002.003l-.339 1.692a1 1 0 0 1-.98.804H8.91a1 1 0 0 1-.98-.804l-.34-1.692s0-.002-.002-.003a.05.05 0 0 0-.018-.013 5.996 5.996 0 0 1-1.08-.622.05.05 0 0 0-.02-.009h-.004l-1.7.51a1 1 0 0 1-1.145-.443l-1.085-1.808a1 1 0 0 1 .2-1.268l1.299-1.137.002-.004a.053.053 0 0 0 .002-.023 6.188 6.188 0 0 1 0-1.368.053.053 0 0 0-.002-.024l-.002-.003-1.3-1.138a1 1 0 0 1-.199-1.267l1.085-1.808a1 1 0 0 1 1.145-.443l1.7.51h.004a.05.05 0 0 0 .02-.01 5.993 5.993 0 0 1 1.08-.62.051.051 0 0 0 .018-.014l.003-.003.338-1.692zM11.09 3H8.91l-.34 1.693c-.068.34-.303.604-.596.734-.319.141-.62.315-.9.517-.248.18-.577.252-.896.157l-1.7-.51-1.085 1.808 1.3 1.137c.264.231.374.57.339.891a5.189 5.189 0 0 0 0 1.146c.035.32-.075.66-.34.89l-1.3 1.138L4.48 14.41l1.7-.51c.319-.095.648-.023.896.157.28.202.581.376.9.517.293.13.528.394.596.734L8.91 17h2.18l.338-1.693c.069-.34.304-.604.596-.734.32-.141.62-.315.9-.517.25-.18.578-.252.896-.157l1.701.51 1.085-1.808-1.3-1.137a1.029 1.029 0 0 1-.339-.891 5.197 5.197 0 0 0 0-1.146c-.036-.32.075-.66.34-.89l1.3-1.138L15.52 5.59l-1.7.51a1.027 1.027 0 0 1-.896-.157 4.991 4.991 0 0 0-.9-.517 1.028 1.028 0 0 1-.597-.734L11.09 3zM10 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiCog);
export default ForwardRef;
