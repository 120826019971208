import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { CraneTriggerWarningUpdatedEventPayload } from '@/types/craneActivity';

export type WarningsPerQuayCrane = {
    [quayCraneName: string]: CraneTriggerWarningUpdatedEventPayload;
};

type CraneActivityStore = {
    warnings: WarningsPerQuayCrane;
    setWarning: (warning: CraneTriggerWarningUpdatedEventPayload) => void;
};

export const useCraneActivityStore = create<CraneActivityStore>()(
    devtools(
        set => {
            return {
                setWarning: warning => {
                    set(previousState => {
                        return {
                            ...previousState,
                            warnings: {
                                ...previousState.warnings,
                                [warning.quayCraneId]: warning,
                            },
                        };
                    });
                },
                warnings: {},
            };
        },
        { name: 'craneActivityStore' },
    ),
);
