import { FeatureFlagV1 } from '@maersk-global/digital-pull-operating-system-spec';
import { VisibilityState } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/shallow';

import { Block } from '@/components/atoms/Block/Block';
import { ErrorComponent } from '@/components/atoms/Error/ErrorComponent';
import { Notification } from '@/components/atoms/Notification/Notification';
import { Title } from '@/components/atoms/Title/Title';
import { QuayCraneInstructionsButtons } from '@/components/organisms/QuayCraneInstructions/QuayCraneInstructionsButtons';
import {
    WorkQueuesOverview,
    YardWorkQueues,
} from '@/components/organisms/WorkQueuesOverview/WorkQueuesOverview';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useTerminalStore } from '@/store/terminalStore';
import { YardWork } from '@/store/yardStore';
import { WorkQueues } from '@/types';

export const QuayCraneInstructions = ({
    workQueues,
    columnVisibilityOptions,
    showButtonLabels = true,
    quayCraneName,
    yardWork,
    vesselVisitId,
    yardWorkQueues,
    consideredCompletedTruckNames,
}: {
    yardWorkQueues: YardWorkQueues;
    workQueues: WorkQueues[];
    vesselVisitId: string;
    yardWork: YardWork;
    columnVisibilityOptions?: VisibilityState;
    showButtonLabels?: boolean;
    quayCraneName: string;
    consideredCompletedTruckNames: string[];
}) => {
    const { t } = useTranslation();
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));

    const { data: sendingInstructions } = useFeatureFlag({
        cheType: 'QUAY_CRANE',
        flag: FeatureFlagV1.VMT_INSTRUCTIONS,
    });
    const showVmtWarning = !sendingInstructions?.includes(quayCraneName);
    if (workQueues.length === 0) {
        return (
            <ErrorComponent>
                {t('pages.flows.workQueuesError', { name: quayCraneName })}
            </ErrorComponent>
        );
    }

    return (
        <div className="flex flex-col gap-3" data-testid="quay_crane_instructions_panel">
            <Block className="items-center justify-between">
                <Title className="mds-font--display-1 flex items-center gap-1">
                    <span className="mds-font--default--bold">{t('labels.instructions')}</span>
                </Title>
                <QuayCraneInstructionsButtons
                    quayCraneName={quayCraneName}
                    terminalId={terminalId}
                    vesselVisitId={vesselVisitId || ''}
                    showButtonLabels={showButtonLabels}
                />
            </Block>
            <Block className="items-center justify-between">
                {showVmtWarning && (
                    <Notification
                        fit="medium"
                        className="mb-2 py-2"
                        appearance="cancel"
                        body={
                            <div className="flex flex-row items-center ">
                                <span>
                                    DPOS is <span className="underline">not</span> sending
                                    instructions to the VMT
                                </span>
                            </div>
                        }
                    />
                )}
            </Block>

            <WorkQueuesOverview
                quayCraneName={quayCraneName}
                yardWorkQueues={yardWorkQueues}
                terminalId={terminalId}
                vesselVisitId={vesselVisitId}
                consideredCompletedTruckNames={consideredCompletedTruckNames}
                workQueues={workQueues}
                yardWork={yardWork}
                columnVisibilityOptions={columnVisibilityOptions}
            />
        </div>
    );
};
