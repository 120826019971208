import {
    SvgLock,
    SvgArrowDown,
    SvgArrowUp,
    SvgArrowsDownUp,
} from '@maersk-global/apmt-react-icons';
import { InstructionCycleType } from '@maersk-global/digital-pull-operating-system-spec';

type InstructionStatusProps = {
    cycleType?: InstructionCycleType;
    isLocked: boolean;
    isCompleted: boolean;
    instruction: string;
    handledCraneTriggers: number | undefined;
    expectedCraneTriggers: number | undefined;
};

export const InstructionStatus = ({
    isLocked,
    instruction,
    isCompleted,
    handledCraneTriggers,
    expectedCraneTriggers,
    cycleType,
}: InstructionStatusProps) => {
    return (
        <span
            className={`flex flex-row items-center gap-1 truncate ${
                isCompleted ? 'opacity-30' : ''
            }`}
        >
            <CycleTypeIcon cycleType={cycleType} />

            {instruction}

            <>
                {isLocked && <SvgLock width={24} height={24} />}
                <CraneTriggers
                    cycleType={cycleType}
                    handledCraneTriggers={handledCraneTriggers}
                    expectedCraneTriggers={expectedCraneTriggers}
                />
            </>
        </span>
    );
};

const CycleTypeIcon = ({ cycleType }: { cycleType?: InstructionCycleType }) => {
    switch (cycleType) {
        case InstructionCycleType.LOAD:
            return <SvgArrowUp width={24} height={24} />;
        case InstructionCycleType.DISCHARGE:
            return <SvgArrowDown width={24} height={24} />;
        case InstructionCycleType.DUAL_CYCLE:
            return <SvgArrowsDownUp width={24} height={24} />;
        default:
            return <></>;
    }
};

const CraneTriggers = ({
    handledCraneTriggers,
    expectedCraneTriggers,
    cycleType,
}: {
    handledCraneTriggers: number | undefined;
    expectedCraneTriggers: number | undefined;
    cycleType?: InstructionCycleType;
}) => {
    if (
        handledCraneTriggers !== undefined &&
        expectedCraneTriggers !== undefined &&
        (expectedCraneTriggers > 1 || cycleType)
    ) {
        return (
            <span className="ml-auto text-neutral-subtle">
                {handledCraneTriggers}/{expectedCraneTriggers}
            </span>
        );
    }
    return null;
};
