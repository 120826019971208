import { QuayCraneService } from '@maersk-global/digital-pull-operating-system-spec';

import { quayCranesDecoder } from '@/types';
import { safeDecode } from '@/utils/safeCodec';

let apiRequestSucceededOnce = false;
export const getAllQuayCranesById = async (terminalId: string) => {
    try {
        const response = await QuayCraneService.getAllQuayCranes({
            terminalId,
        });
        apiRequestSucceededOnce = true;
        return safeDecode(quayCranesDecoder, response).caseOf({
            Left: e => {
                throw new Error(`Error fetching quay cranes for terminal [${terminalId}]: ${e}`);
            },
            Right: r => r,
        });
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
        if (apiRequestSucceededOnce && err?.response === undefined && err?.code === 'ERR_NETWORK') {
            window.location.reload();
        }
        throw err;
    }
};
