import { SvgStopCircle, SvgExclamationCircle, SvgTruckSide } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';

import { Fit, Label, Shape } from '@/components/atoms/Label/Label';
import { FlowStatusOption } from '@/constants/enums';

export type FlowStatusLabelProps = {
    flowStatus: FlowStatusOption;
    className?: string;
    fit?: Fit;
    weight?: 'light' | 'dark';
    shape?: Shape;
    showIcon?: boolean;
};

export const FlowStatusLabel = ({
    flowStatus,
    className,
    fit = 'medium',
    shape = 'block',
    weight = 'dark',
    showIcon = true,
}: FlowStatusLabelProps) => {
    const { t } = useTranslation();
    const labelProps = { fit, weight, shape, className };

    switch (flowStatus) {
        case 'STOPPED':
            return (
                <Label
                    variant="error"
                    icon={showIcon ? <SvgStopCircle className="text-xl" /> : null}
                    {...labelProps}
                >
                    {t('labels.flowStatus.stops')}
                </Label>
            );
        case 'OVER_DEPLOYED':
            return (
                <Label
                    variant="info"
                    icon={showIcon ? <SvgTruckSide className="text-xl" /> : null}
                    {...labelProps}
                >
                    {t('labels.flowStatus.overDeployed')}
                </Label>
            );
        case 'RISK':
            return (
                <Label
                    variant="warn"
                    icon={showIcon ? <SvgExclamationCircle className="text-xl" /> : null}
                    {...labelProps}
                >
                    {t('labels.flowStatus.atRisk')}
                </Label>
            );

        default:
            break;
    }
};
