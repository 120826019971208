import { useQuery } from '@tanstack/react-query';

import { getAllYardStackingEquipmentWorkingForQuayCrane } from '@/api';
import { reactQueryKeys } from '@/constants/queryKeys';

export const useStackingEquipment = ({
    terminalId,
    quayCraneName,
    pollInterval = 5000,
}: {
    terminalId: string;
    quayCraneName: string;
    pollInterval?: number;
}) => {
    return useQuery({
        queryKey: [reactQueryKeys.YARD_STACKING_EQUIPMENT_FOR_QUAYCRANE, terminalId, quayCraneName],
        queryFn: () => getAllYardStackingEquipmentWorkingForQuayCrane(terminalId, quayCraneName),
        enabled: !!terminalId && !!quayCraneName,
        refetchInterval: pollInterval,
    });
};
