import { Drawer } from '@maersk-global/apmt-react-drawer';
import clsx from 'clsx';
import type { ComponentProps, ReactNode } from 'react';

type DrawerProps = ComponentProps<typeof Drawer> & {
    title?: string | ReactNode;
    children: ReactNode;
};

export const DrawerWrapper = ({ title, children, open, onClose }: DrawerProps) => {
    return (
        <div
            className={clsx(
                'fixed bottom-0 right-0 w-[640px] bg-transparent',
                !open && 'pointer-events-none',
            )}
        >
            <Drawer
                canBeClosed={onClose !== undefined}
                open={open}
                onClose={onClose}
                className="h-screen-min-navigation"
            >
                {title && (
                    <header className="border-b border-b-gray-300 py-6 pl-4 pr-10">
                        {typeof title === 'string' ? (
                            <h2 className="mds-font--display-1">
                                <span className="mr-4">{title}</span>
                            </h2>
                        ) : (
                            title
                        )}
                    </header>
                )}
                {children}
            </Drawer>
        </div>
    );
};
