import { SvgArrowLeft } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

export const BackButton = ({ href }: { href?: string }) => {
    const navigate = useNavigate();

    return (
        <div className="flex items-center">
            {href && (
                <Link className="flex items-center py-2 pl-2 pr-6" to={href}>
                    <BackButtonContent />
                </Link>
            )}
            {!href && (
                <button onClick={() => navigate(-1)} className="flex items-center py-2 pl-2 pr-6">
                    <BackButtonContent />
                </button>
            )}
        </div>
    );
};

export const BackButtonContent = () => {
    const { t } = useTranslation();

    return (
        <>
            <SvgArrowLeft className="text-2xl" />
            <span className="sr-only">{t('labels.back')}</span>
        </>
    );
};
