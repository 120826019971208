import { Button } from '@maersk-global/apmt-react-components';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import type { ComponentPropsWithoutRef, ElementRef, HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '../common/cn';

const DialogContent = forwardRef<
    ElementRef<typeof DialogPrimitive.Content>,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
    <DialogPrimitive.Portal>
        <DialogPrimitive.Overlay className="fixed inset-0 z-50 bg-gray-950/50 backdrop-blur-sm transition-all duration-100">
            <DialogPrimitive.Content
                className={cn(
                    'fixed left-1/2 top-1/2 z-[50] w-full max-w-[440px] -translate-x-1/2 -translate-y-1/2 rounded bg-white',
                    className,
                )}
                {...props}
                ref={forwardedRef}
            >
                {children}
            </DialogPrimitive.Content>
        </DialogPrimitive.Overlay>
    </DialogPrimitive.Portal>
));

const DialogTitle = forwardRef<
    ElementRef<typeof DialogPrimitive.Title>,
    ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
    <DialogPrimitive.Title
        ref={ref}
        className={cn('mds-font--default--bold', className)}
        {...props}
    />
));

const DialogTopSection = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('flex flex-col gap-2 px-5 pb-3 pt-5', className)} {...props} />
);

const DialogDescription = forwardRef<
    ElementRef<typeof DialogPrimitive.DialogDescription>,
    ComponentPropsWithoutRef<typeof DialogPrimitive.DialogDescription>
>(({ children, className, ...props }, forwardedRef) => (
    <DialogPrimitive.DialogDescription
        className={cn('text-sm px-5 pb-5', className)}
        {...props}
        ref={forwardedRef}
    >
        {children}
    </DialogPrimitive.DialogDescription>
));

const DialogFooter = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div
        className={cn('flex flex-row justify-end gap-2 rounded-b bg-gray-100 px-5 py-3', className)}
        {...props}
    />
);

const DialogCloseButton = forwardRef<
    ElementRef<typeof Button>,
    ComponentPropsWithoutRef<typeof Button>
>(({ children, variant = 'secondary', fit = 'small', ...props }, forwardedRef) => (
    <DialogPrimitive.Close aria-label="Close" asChild {...props} ref={forwardedRef}>
        <Button variant={variant} fit={fit}>
            {children}
        </Button>
    </DialogPrimitive.Close>
));

const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;

export {
    Dialog,
    DialogCloseButton,
    DialogTitle,
    DialogContent,
    DialogDescription,
    DialogTopSection,
    DialogFooter,
    DialogTrigger,
};
