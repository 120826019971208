import type { ClassValue } from 'clsx';
import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * A helper function to merge Tailwind classes with clsx
 * @param inputs - A list of Tailwind classes and clsx inputs
 * @returns A merged Tailwind class string
 */
export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}
