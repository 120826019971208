import { PropsWithChildren, ReactNode } from 'react';

import { cn } from '@/utils/cn';

export const FloatingBlock = ({
    className,
    icon,
    children,
    testid,
}: PropsWithChildren<{ className?: string; icon: ReactNode; testid: string }>) => {
    return (
        <span
            className={cn(
                'flex items-center gap-3 rounded-[3px] bg-feedback-danger-light px-3 py-2',
                className,
            )}
            data-testid={testid}
        >
            <span className="flex w-6 items-center justify-center">{icon}</span>
            {children}
        </span>
    );
};
