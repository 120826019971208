import clsx from 'clsx';
import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

type FitClasses = {
    small: string;
    medium: string;
};

type Fit = keyof FitClasses;

const labelFitClasses: FitClasses = {
    small: 'leading-5',
    medium: 'leading-6',
};

interface InputTextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    label: string;
    fit?: Fit;
    type?: string;
    placeholder?: string;
    rows?: number;
    invalid?: boolean;
}

export const InputTextArea = forwardRef<HTMLTextAreaElement, InputTextAreaProps>(
    ({ name, label, fit = 'medium', invalid = false, rows = 6, ...otherProps }, ref) => {
        return (
            <>
                <label
                    htmlFor={`textarea-${name}`}
                    className={clsx('mb-1 text-base text-gray-900', labelFitClasses[fit])}
                >
                    {label}
                </label>
                <textarea
                    {...otherProps}
                    name={name}
                    ref={ref}
                    rows={rows}
                    id={`textarea-${name}`}
                    className={clsx(
                        'w-full rounded border bg-white p-3 transition duration-200 ease-in-out placeholder:text-gray-600 focus:shadow-input focus:outline-none ',
                        'disabled:bg-gray-100 disabled:text-gray-500',
                        invalid
                            ? 'border-feedback-danger focus:border-red-900 focus:shadow-feedback-invalidShadow'
                            : 'border-gray-300 focus:border-blue-400',
                    )}
                />
            </>
        );
    },
);
