import { MoveStatus } from '@maersk-global/digital-pull-operating-system-spec';

import { MoveKind, TruckMapColors } from '@/constants/enums';
import { TerminalTruckStatus } from '@/types';

export const calculateTruckColorForMap = (
    status: TerminalTruckStatus | undefined,
): TruckMapColors => {
    if (status === undefined) {
        return TruckMapColors.BLUE;
    }

    const isNotWorking = !status.equipmentStatusIsWorking;
    if (isNotWorking) {
        return TruckMapColors.RED;
    }

    switch (status.moveKind) {
        case MoveKind.DISCHARGE:
            return calculateTruckColorForDischarge(status);
        case MoveKind.LOAD:
            if (status.workInstructionConsideredCompleted) {
                return TruckMapColors.GREEN;
            }

            return calculateTruckColorForLoad(status.moveStatus);
    }
};

const calculateTruckColorForDischarge = (
    status: Pick<TerminalTruckStatus, 'moveStatus' | 'workInstructionConsideredCompleted'>,
): TruckMapColors => {
    if (status.moveStatus === MoveStatus.MOVE_FETCHED) {
        if (status.workInstructionConsideredCompleted) {
            return TruckMapColors.GREEN;
        }

        return TruckMapColors.LIGHT_BLUE;
    }

    if (status.moveStatus === MoveStatus.UNAVAILABLE) {
        return TruckMapColors.LIGHT_GRAY;
    }

    return TruckMapColors.GRAY;
};

const calculateTruckColorForLoad = (status: TerminalTruckStatus['moveStatus']): TruckMapColors => {
    if (status === MoveStatus.MOVE_IN_PROGRESS) {
        return TruckMapColors.BLUE;
    }

    if (status === MoveStatus.UNAVAILABLE) {
        return TruckMapColors.LIGHT_GRAY;
    }

    return TruckMapColors.GRAY;
};
