import { produce } from 'immer';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
    hasInaccurateSignal,
    hasStaleSignal,
    QuayCraneStatusInfo,
    SpreaderInfoPerQuayCrane,
    TerminalTruckPosition,
} from '@/types';

export type TruckMapData = Omit<TerminalTruckPosition, 'updatedAt' | 'hdop'> & {
    hasInaccurateSignal: boolean;
    hasStaleSignal: boolean;
};
type TruckPositionsStore = {
    truckPositions: { [truckName: string]: TruckMapData };
    updateTruckPositions: (
        decodedGpsSignalStaleAfterMinutes: number,
        positions: TerminalTruckPosition[],
    ) => void;
    setQuayCraneStatusInfoPerQuayCrane: (
        setQuayCraneStatusInfoPerQuayCrane: QuayCraneStatusInfo,
    ) => void;
    quayCraneStatusInfoPerQuayCrane: SpreaderInfoPerQuayCrane;
};

export const useTruckPositionsStore = create<TruckPositionsStore>()(
    devtools(
        set => {
            return {
                updateTruckPositions: (
                    decodedGpsSignalStaleAfterMinutes: number,
                    positions: TerminalTruckPosition[],
                ) => {
                    set(previousState => {
                        const now = Date.now();
                        const newTrucks = produce(previousState.truckPositions, draft => {
                            positions.forEach(pos => {
                                draft[pos.terminalTruckId] = {
                                    terminalTruckId: pos.terminalTruckId,
                                    latitude: pos.latitude,
                                    longitude: pos.longitude,
                                    hasInaccurateSignal: hasInaccurateSignal(now, pos.hdop),
                                    hasStaleSignal: hasStaleSignal(
                                        now,
                                        decodedGpsSignalStaleAfterMinutes,
                                        pos.updatedAt,
                                    ),
                                    quayCraneName: pos.quayCraneName,
                                    standByDistance: pos.standByDistance,
                                    standByRoute: pos.standByRoute,
                                    underCraneDistance: pos.underCraneDistance,
                                    underCraneRoute: pos.underCraneRoute,
                                };
                            });
                        });
                        return {
                            ...previousState,
                            truckPositions: newTrucks,
                        };
                    });
                },
                setQuayCraneStatusInfoPerQuayCrane: (QuayCraneStatusInfo: QuayCraneStatusInfo) => {
                    set(previousState => {
                        return {
                            ...previousState,
                            quayCraneStatusInfoPerQuayCrane: {
                                ...previousState.quayCraneStatusInfoPerQuayCrane,
                                [QuayCraneStatusInfo.quayCraneName]: QuayCraneStatusInfo,
                            },
                        };
                    });
                },
                truckPositions: {},
                quayCraneStatusInfoPerQuayCrane: {},
            };
        },
        { name: 'truckPositionsStore' },
    ),
);
