/* istanbul ignore file */
import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import 'react';
import { array, Codec, GetType, Maybe, number, string, unknown } from 'purify-ts';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { JSONTree } from 'react-json-tree';
import { useParams } from 'react-router-dom';

const domainEventDecoder = Codec.interface({
    type: string,
    sequenceNumber: number,
    aggregateIdentifier: string,
    payload: unknown,
    timestamp: string,
});
type DomainEvent = GetType<typeof domainEventDecoder>;

export const EventPage = () => {
    const { quayCraneShiftId } = useParams();
    const [aggregateId, setAggregateId] = useState(quayCraneShiftId ?? '');
    const [payloadFilter, setPayloadFilter] = useState('');
    const [amount, setAmount] = useState('50');
    const [offset, setOffset] = useState('0');
    const [shouldExpand, setShouldExpand] = useState(false);
    const [order, setOrder] = useState<'ASC' | 'DESC'>('ASC');
    const [events, setEvents] = useState<DomainEvent[]>([]);
    const auth = useAuth();

    const onChangeAggregate = (input: ChangeEvent<HTMLInputElement>) => {
        setAggregateId(input.target.value);
    };

    const onChangePayloadFilter = (input: ChangeEvent<HTMLInputElement>) => {
        setPayloadFilter(input.target.value);
    };

    const onChangeAmount = (input: ChangeEvent<HTMLInputElement>) => {
        setAmount(input.target.value);
    };
    const onChangeOffset = (input: ChangeEvent<HTMLInputElement>) => {
        setOffset(input.target.value);
    };
    const onChangeOrder = (input: ChangeEvent<HTMLInputElement>) => {
        setOrder(input.target.value as 'ASC' | 'DESC');
    };

    const filterEvents = (filter: string, events: DomainEvent[]) => {
        const match = Maybe.encase(() => {
            const regex = new RegExp(filter);
            return (str: string) => regex.test(str);
        }).orDefault((str: string) => str.includes(filter));

        return events.filter(event => {
            return match(JSON.stringify(event.payload) as string) || match(event.type);
        });
    };

    const fetchEvents = async () => {
        try {
            const params = new URLSearchParams({
                offset,
                amount,
                order,
            });
            const fetchFunction = auth.fetchWithAuth;
            const events = await fetchFunction(
                `/api/v1/domain-events/${encodeURIComponent(aggregateId)}?${params}`,
            )
                .then(r => r.json())
                .then(array(domainEventDecoder).decode)
                .then(e =>
                    e.caseOf({
                        Right: r => {
                            if (order === 'DESC') {
                                r.reverse();
                            }
                            return r;
                        },
                        Left: l => {
                            throw new Error(l);
                        },
                    }),
                );

            setEvents(events);
        } catch (e) {
            console.error('Error fetching events', e);
        }
    };

    useEffect(() => {
        if (aggregateId !== '') {
            void fetchEvents();
        }
    }, []);

    return (
        <div className="flex w-full  flex-row p-5">
            <div className="mr-4">
                <form
                    onSubmit={(e: FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        void fetchEvents();
                    }}
                >
                    <label
                        className="mb-2 block text-sm font-bold text-gray-700"
                        htmlFor="aggregate"
                    >
                        Aggregate
                    </label>
                    <input
                        onChange={onChangeAggregate}
                        value={aggregateId}
                        className="mb-5 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                        id="aggregate"
                        type="text"
                        placeholder="aggregate id"
                    />
                    <div className="flex flex-row">
                        <label
                            className="mb-2 mr-4 block text-sm font-bold text-gray-700"
                            htmlFor="amount"
                        >
                            Amount
                            <input
                                onChange={onChangeAmount}
                                value={amount}
                                className="mb-5 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                                id="amount"
                                type="text"
                                placeholder="amount"
                            />
                        </label>
                        <label
                            className="mb-2 block text-sm font-bold text-gray-700"
                            htmlFor="offset"
                        >
                            Offset
                            <input
                                onChange={onChangeOffset}
                                value={offset}
                                className="mb-5 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                                id="offset"
                                type="text"
                                placeholder="offset"
                            />
                        </label>
                        <div className="flex flex-col justify-center p-2">
                            <div className="flex flex-row">
                                <input
                                    onChange={onChangeOrder}
                                    value="ASC"
                                    checked={order === 'ASC'}
                                    id="order_ASC"
                                    type="radio"
                                />
                                <label
                                    className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                    htmlFor="order_ASC"
                                >
                                    Asc
                                </label>
                            </div>
                            <div className="flex flex-row">
                                <input
                                    onChange={onChangeOrder}
                                    value="DESC"
                                    checked={order === 'DESC'}
                                    id="order_DESC"
                                    type="radio"
                                />
                                <label
                                    className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                                    htmlFor="order_DESC"
                                >
                                    Desc
                                </label>
                            </div>
                        </div>
                    </div>
                    <button
                        className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
                        type="submit"
                    >
                        Get events
                    </button>
                </form>
            </div>
            <div className="grow">
                <div className="mb-2 block text-sm font-bold text-gray-700">Results</div>
                <div className="flex flex-row">
                    <div className="flex grow flex-col">
                        <label
                            className="mb-2 block text-sm font-bold text-gray-700"
                            htmlFor="payloadFilter"
                        >
                            Filter
                        </label>
                        <input
                            onChange={onChangePayloadFilter}
                            value={payloadFilter}
                            className="mb-5 w-full appearance-none rounded border px-3 py-2 leading-tight text-gray-700 shadow focus:outline-none"
                            id="payloadFilter"
                            type="text"
                            placeholder="TG12|TG35"
                        />
                    </div>
                    <div className="flex flex-row items-center pl-2">
                        <input
                            onChange={() => setShouldExpand(!shouldExpand)}
                            checked={shouldExpand}
                            id="shouldExpand"
                            type="checkbox"
                        />
                        <label
                            className="mt-px inline-block pl-[0.15rem] hover:cursor-pointer"
                            htmlFor="shouldExpand"
                        >
                            Expand all
                        </label>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="overflow-hidden">
                                <table className="min-w-full">
                                    <thead className="border-b bg-white">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="w-1 px-6 py-4 text-left text-sm font-medium text-gray-900"
                                            >
                                                #
                                            </th>
                                            <th
                                                scope="col"
                                                className="w-1 px-6 py-4 text-left text-sm font-medium text-gray-900"
                                            >
                                                timestamp
                                            </th>
                                            <th
                                                scope="col"
                                                className="w-16 px-6 py-4 text-left text-sm font-medium text-gray-900"
                                            >
                                                type
                                            </th>
                                            <th
                                                scope="col"
                                                className="w-full px-6 py-4 text-left text-sm font-medium text-gray-900"
                                            >
                                                payload
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filterEvents(payloadFilter, events).map(e => {
                                            return (
                                                <tr
                                                    key={e.sequenceNumber}
                                                    className="border-b bg-gray-100"
                                                >
                                                    <td className="w-1 px-6 py-4 text-sm font-medium text-gray-900">
                                                        {e.sequenceNumber}
                                                    </td>
                                                    <td className="w-2 px-6 py-4 text-sm font-light text-gray-900">
                                                        {e.timestamp}
                                                    </td>
                                                    <td className="w-2 px-6 py-4 text-sm font-light text-gray-900">
                                                        {e.type}
                                                    </td>
                                                    <td className="w-full px-6 py-4 text-sm font-light text-gray-900">
                                                        <JSONTree
                                                            theme={{
                                                                scheme: 'monokai',
                                                                base00: 'var(--mdt-theme-palette-functional-100)',
                                                            }}
                                                            data={e.payload}
                                                            shouldExpandNodeInitially={keyPath =>
                                                                shouldExpand ||
                                                                keyPath.join('').includes('Id')
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
