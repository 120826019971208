import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiPause(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M5 5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V5zm3 0H6v10h2V5zm3 0a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V5zm3 0h-2v10h2V5z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiPause);
export default ForwardRef;
