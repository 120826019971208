import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiCheck(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="m16.418 5.275-6.102 9.275a1 1 0 0 1-.835.45H7.985a1 1 0 0 1-.769-.36l-3.6-4.32.768-.64 3.6 4.32h1.497l6.102-9.275.835.55z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiCheck);
export default ForwardRef;
