import { PropsWithChildren } from 'react';

import { cn } from '@/utils/cn';

type Props = {
    size?: 'small' | 'medium' | 'large';
} & PropsWithChildren;

type Size = NonNullable<Props['size']>;

const classNameForSizes: Record<Size, string> = {
    small: 'text-xs',
    medium: 'text-base',
    large: 'text-xl',
};

export const SecondaryInfo = ({ size = 'small', children }: Props) => {
    return (
        <span className={cn('leading-5 text-gray-600', classNameForSizes[size])}>{children}</span>
    );
};
