import { TruckMapShapes } from '@/constants/enums';

export const getTruckShapeKey = (index: number): TruckMapShapes => {
    switch (index) {
        case 0:
            return TruckMapShapes.CIRCLE;
        case 1:
            return TruckMapShapes.HEXAGON;
        case 2:
            return TruckMapShapes.DIAMOND;
        case 3:
            return TruckMapShapes.SQUARE;
        default:
            return TruckMapShapes.CIRCLE;
    }
};

export const getTruckShapeIcon = (shape: TruckMapShapes) => {
    switch (shape) {
        case TruckMapShapes.CIRCLE:
            return CircleSvg;
        case TruckMapShapes.HEXAGON:
            return HexagonSvg;
        case TruckMapShapes.DIAMOND:
            return DiamondSvg;
        case TruckMapShapes.SQUARE:
            return SquareSvg;
        default:
            return CircleSvg;
    }
};
export const badSignalSvg = (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.08724 3.45874C7.27164 2.47739 8.75975 2 10 2C11.2402 2 12.7284 2.47739 13.9128 3.45874C15.1111 4.45165 16 5.96353 16 8C16 10.1418 15.05 12.3437 14.0426 14.0874C13.0284 15.843 11.9181 17.1979 11.5237 17.6597C11.3306 17.8859 11.0558 18 10.7773 18H9.22268C8.94424 18 8.66936 17.8859 8.47627 17.6597C8.08191 17.1979 6.97159 15.843 5.95735 14.0874C4.94997 12.3437 4 10.1418 4 8C4 5.96353 4.8889 4.45165 6.08724 3.45874ZM10 6C8.89543 6 8 6.89543 8 8C8 9.10457 8.89543 10 10 10C11.1046 10 12 9.10457 12 8C12 6.89543 11.1046 6 10 6Z"
            fill="#4D3E0C"
        />
        <rect
            width="1"
            height="22"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 2.92871 1.51465)"
            fill="#4D3E0C"
        />
        <rect
            width="1"
            height="22"
            transform="matrix(-0.707107 0.707107 0.707107 0.707107 2.22168 2.22168)"
            fill="#FFD029"
        />
    </svg>
);
const CircleSvg = ({ stroke }: { stroke: boolean }) => (
    <svg
        className="size-full"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="16"
            cy="16"
            r="12.5"
            fill="currentColor"
            stroke={stroke ? 'white' : 'currentColor'}
        />
    </svg>
);

const DiamondSvg = ({ stroke }: { stroke: boolean }) => (
    <svg
        className="size-full"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="0.292893"
            y="16"
            width="22"
            height="22"
            rx="2.5"
            transform="rotate(-45 0.292893 16)"
            fill="currentColor"
            stroke={stroke ? 'white' : 'currentColor'}
        />
    </svg>
);

const HexagonSvg = ({ stroke }: { stroke: boolean }) => (
    <svg
        className="size-full"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.75 2.85566C16.2859 2.58771 15.7141 2.58771 15.25 2.85566L4.99167 8.77831C4.52757 9.04626 4.24167 9.54145 4.24167 10.0774V21.9226C4.24167 22.4585 4.52757 22.9537 4.99167 23.2217L15.25 29.1443C15.7141 29.4123 16.2859 29.4123 16.75 29.1443L27.0083 23.2217C27.4724 22.9537 27.7583 22.4585 27.7583 21.9226V10.0774C27.7583 9.54145 27.4724 9.04626 27.0083 8.77831L16.75 2.85566Z"
            fill="currentColor"
            stroke={stroke ? 'white' : 'currentColor'}
        />
    </svg>
);

const SquareSvg = ({ stroke }: { stroke: boolean }) => (
    <svg
        className="size-full"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="4.5"
            y="4.5"
            width="23"
            height="23"
            rx="2.5"
            fill="currentColor"
            stroke={stroke ? 'white' : 'currentColor'}
        />
    </svg>
);
