import {
    Button,
    Accordion,
    AccordionItem,
    Checkbox,
    InputText,
    Tag,
} from '@maersk-global/apmt-react-components';
import { SvgChevronUp, SvgChevronDown } from '@maersk-global/apmt-react-icons';
import { useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';

export type AccordionOption = {
    label: string;
    value: string;
    checked: boolean;
    disabled: boolean;
};

type MultiDropdownWithCheckboxesDefaultProps = {
    label: string;
    disabled?: boolean;
    options: AccordionOption[];
    onOptionChecked: (option: string, checked: boolean) => void;
    onChange?: (checked: string[]) => void;
    testId?: string;
    showFilter?: boolean;
    selectAllLabel?: string;
    clearSelectionLabel?: string;
};

type MultiDropdownWithCheckboxesPropsWithMaximum = MultiDropdownWithCheckboxesDefaultProps & {
    showClearSelectionButton: false;
    selectedMaximum?: number;
    selectedMaximumMessage?: string;
};

type MultiDropdownWithCheckboxesPropsWithClearSelection =
    MultiDropdownWithCheckboxesDefaultProps & {
        showClearSelectionButton: true;
        onClearClicked?: () => void;
        onSelectAllClicked?: () => void;
    };

export type DropdownWithCheckboxesProps =
    | MultiDropdownWithCheckboxesPropsWithMaximum
    | MultiDropdownWithCheckboxesPropsWithClearSelection;

export const DropdownWithCheckboxes = ({
    label,
    options,
    disabled = false,
    showFilter = false,
    selectAllLabel,
    clearSelectionLabel,
    onChange,
    onOptionChecked,
    ...props
}: DropdownWithCheckboxesProps) => {
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const filterRef = useRef<HTMLDivElement>(null);
    const triggerButtonRef = useRef<HTMLButtonElement>(null);
    const hasMaximumSelectionSet = !props.showClearSelectionButton && props.selectedMaximum;
    const selectAllThreshold = options.filter(option => option.checked).length < 2;
    const baseTestId = props.testId || '';
    const [checkedItems, setCheckedItems] = useState<AccordionOption[]>(
        options.filter(o => o.checked),
    );
    const selectAllLabelText = selectAllLabel || 'Select all';
    const clearSelectionLabelText = clearSelectionLabel || 'Clear selection';
    const [searchFilter, setSearchFilter] = useState<string>();

    useEffect(() => {
        setCheckedItems(options.filter(o => o.checked));
    }, [setCheckedItems, options]);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsFilterOpen(current => !current);
    };

    const updateSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchFilter(e.target.value);
    };

    useClickAway(filterRef, (e: Event) => {
        const clickedElement = e.target as HTMLElement;
        if (isFilterOpen && !triggerButtonRef.current?.contains(clickedElement)) {
            setIsFilterOpen(false);
        }
    });

    return (
        <div className="relative w-full" data-testid={`select-accordion${baseTestId}`}>
            <Button
                variant="secondary"
                fit="medium"
                className="w-full min-w-[218px]"
                onClick={handleClick}
                data-testid={`select-accordion-trigger${baseTestId}`}
                ref={triggerButtonRef}
                disabled={disabled}
            >
                <div className="flex w-full items-center justify-between">
                    <div className="text-left text-sm">
                        {label.split(',').map(l => (
                            <Tag key={l} label={l} className="m-[1px]" />
                        ))}
                    </div>
                    <span>
                        {isFilterOpen ? (
                            <SvgChevronUp className="ml-1 text-xl" />
                        ) : (
                            <SvgChevronDown className="ml-1 text-xl" />
                        )}
                    </span>
                </div>
            </Button>
            <div className="absolute top-10 z-40 bg-white" ref={filterRef}>
                {(isFilterOpen && (
                    <Accordion
                        type="single"
                        className="z-40 -mt-1 ml-0  max-h-[500px] w-full min-w-[218px] overflow-x-hidden overflow-y-scroll text-sm"
                        data-testid={`select-accordion-content${baseTestId}`}
                    >
                        {props.showClearSelectionButton && (
                            <>
                                {showFilter && (
                                    <InputText
                                        className="m-2"
                                        name="search"
                                        onChange={updateSearchTerm}
                                    ></InputText>
                                )}
                                <button
                                    className="relative flex w-full flex-row overflow-hidden bg-white px-3 py-1 leading-6 text-blue-700 first:rounded-t last:rounded-b last:border-b"
                                    onClick={e => {
                                        e.preventDefault();
                                        if (selectAllThreshold) {
                                            props.onSelectAllClicked?.();
                                        } else {
                                            props.onClearClicked?.();
                                        }
                                    }}
                                >
                                    <span className="underline">
                                        {selectAllThreshold
                                            ? selectAllLabelText
                                            : clearSelectionLabelText}
                                    </span>
                                </button>
                            </>
                        )}

                        {hasMaximumSelectionSet && props.selectedMaximumMessage && (
                            <span className="relative flex flex-row overflow-hidden bg-white px-3 py-[6px] leading-6 text-gray-700 outline-none first:rounded-t last:rounded-b last:border-b">
                                {props.selectedMaximumMessage}
                            </span>
                        )}

                        {options
                            .filter(
                                o =>
                                    searchFilter === undefined ||
                                    o.label.toLowerCase().includes(searchFilter.toLowerCase()),
                            )
                            .map(option => {
                                const maximumReached =
                                    // hasMaximumSelectionSet cannot be used because then TS complains about props.selectedMaximum being possibly undefined
                                    !props.showClearSelectionButton &&
                                    props.selectedMaximum !== undefined
                                        ? options.filter(option => option.checked).length >=
                                          props.selectedMaximum
                                        : false;

                                return (
                                    <AccordionItem
                                        value={option.value}
                                        key={option.value}
                                        showBorders={false}
                                        className="relative flex flex-row px-3 py-[6px] leading-6 text-gray-800 outline-none hover:bg-gray-200"
                                    >
                                        <Checkbox
                                            id={option.value}
                                            value={option.value}
                                            label={option.label}
                                            disabled={
                                                option.disabled ||
                                                (!option.checked && maximumReached)
                                            }
                                            labelPosition="right"
                                            fit="small"
                                            checked={
                                                !!checkedItems.find(c => c.value === option.value)
                                            }
                                            onCheckedChange={(checked: boolean | string) => {
                                                onOptionChecked(option.value, Boolean(checked));

                                                let updatedItems = checkedItems;
                                                if (checked) {
                                                    // add option to array if missing
                                                    const found = checkedItems.find(
                                                        i => i.value === option.value,
                                                    );
                                                    if (!found) {
                                                        updatedItems = [...checkedItems, option];
                                                    }
                                                } else {
                                                    // remove option if found
                                                    updatedItems = checkedItems.filter(
                                                        i => i.value !== option.value,
                                                    );
                                                }
                                                setCheckedItems(updatedItems);
                                                onChange?.(updatedItems.map(c => c.value));
                                            }}
                                            data-testid={`dropdowncheckbox-${option.value}`}
                                        />
                                    </AccordionItem>
                                );
                            })}
                    </Accordion>
                )) ||
                    null}
            </div>
        </div>
    );
};
