import { Codec } from 'purify-ts';

export const safeDecode = <T extends object>(codec: Codec<T>, data: UndefinedToOptional<T>) => {
    return codec.decode(data);
};

type OptionalProps<T extends object> = Partial<{
    [K in keyof T as T[K] extends NonNullable<T[K]> ? never : K]: T[K] extends object
        ? UndefinedToOptional<T[K]>
        : T[K];
}>;

type RequiredProps<T extends object> = {
    [K in keyof T as T[K] extends NonNullable<T[K]> ? K : never]: T[K] extends object
        ? UndefinedToOptional<T[K]>
        : T[K];
};
type UndefinedToOptional<T extends object> = T extends (infer A)[]
    ? A extends object
        ? OptionalProps<A>[] & RequiredProps<A>[]
        : A
    : OptionalProps<T> & RequiredProps<T>;
