import { SvgExclamationTriangleSolid } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';

import { Title } from '@/components/atoms/Title/Title';
import { Andons } from '@/types/andons';

import { FlowDetailDrawerAndonsOverview } from './FlowDetailDrawerAndonsOverview';

export const FlowDetailDrawerAndons = ({ andons }: { andons: Andons }) => {
    const { t } = useTranslation();
    const hasAndons = andons.length > 0;

    return (
        <div className="flex flex-col gap-4">
            <Title className="mds-font--display-1 flex items-center gap-1">
                {hasAndons ? (
                    <SvgExclamationTriangleSolid className="size-6 text-feedback-danger" />
                ) : null}
                <span className="mds-font--default--bold">{t('labels.andon_other')}</span>
            </Title>
            {hasAndons ? (
                <FlowDetailDrawerAndonsOverview andons={andons} />
            ) : (
                <p className="text-gray-600">{t('pages.positions.detailDrawer.noAndons')}</p>
            )}
        </div>
    );
};
