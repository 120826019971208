import { cn } from '@maersk-global/apmt-react-components/src/common/cn';
import type { PropsWithChildren } from 'react';

type FiltersSectionProps = {
    className?: string;
    label?: string;
};

export const FiltersSection = ({
    label,
    className,
    children,
}: PropsWithChildren<FiltersSectionProps>) => {
    return (
        <div className={cn('flex h-full items-center gap-3', className)}>
            {label && <span className="mr-1">{label}:</span>}
            {children}
        </div>
    );
};
