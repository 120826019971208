import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { TerminalTruckStatus } from '@/types';

type TruckStatusStore = {
    trucksPerQuayCrane: { [quayCraneName: string]: TerminalTruckStatus[] };
    setTrucksPerQuayCrane: (quayCraneName: string, trucks: TerminalTruckStatus[]) => void;
};

export const useTruckStatusStore = create<TruckStatusStore>()(
    devtools(
        set => {
            return {
                setTrucksPerQuayCrane: (quayCraneName, trucks) => {
                    set(state => ({
                        ...state,
                        trucksPerQuayCrane: {
                            ...state.trucksPerQuayCrane,
                            [quayCraneName]: trucks,
                        },
                    }));
                },
                trucksPerQuayCrane: {},
            };
        },
        { name: 'truckStatusStore' },
    ),
);
