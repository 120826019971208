import { Codec, Either, Left, Right, string, optional, array } from "purify-ts";

import { Role } from "./types";

const createGroupMapping: (optionalRolePrefix: string) => Map<string, Role> = (
    optionalRolePrefix
) =>
    new Map([
        ["/admin", Role.Admin],
        [`/${optionalRolePrefix}-admin`, Role.Admin],
        ["/normal", Role.Normal],
        [`/${optionalRolePrefix}-normal`, Role.Normal],
        ["/read-only", Role.ReadOnly],
        [`/${optionalRolePrefix}-read-only`, Role.ReadOnly],
    ]);

export const createGroupsDecoder = (ldapRolePrefix: string) => {
    const groupMapping: Map<string, Role> = createGroupMapping(ldapRolePrefix);
    return Codec.custom<Role[]>({
        decode: (value) => {
            if (!Array.isArray(value)) {
                return Left("Expected array");
            }
            const roles = value.map((entry) => {
                return Either.encase(() => {
                    const role = groupMapping.get(entry);
                    if (role) {
                        return role;
                    } else {
                        throw Error(`Unknown Role ${entry}`);
                    }
                });
            });

            return Right(Either.rights(roles));
        },
        encode: (value) => value,
    });
};

export const accessTokenCodec = Codec.interface({
    email: string,
    given_name: optional(string),
    family_name: optional(string),
    groups: array(string),
});
