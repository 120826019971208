import { SharedCookieNames, SharedSlugs } from '@maersk-global/apmt-dpos-common';
import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { useCookie } from 'react-use';

import MainLayout from '@/components/templates/MainLayout';
import { defaultLocale } from '@/constants/locales';
import { useTerminalSettings } from '@/hooks/terminalInfo/TerminalInfoProvider';
import { getParameterizedRoute } from '@/routes/routes';

import { ControlOverviewPage } from './ControlOverviewPage/ControlOverviewPage';
import { MapEditPage } from './MapEditPage/MapEditPage';
import { FeatureFlagsPage } from './SettingsPage/FeatureFlagsPage/FeatureFlagsPage';
import { YardOverviewPage } from './YardOverviewPage/YardOverviewPage';

import { EventPage, FlowDetailPage, FlowOverviewPage, SettingsPage, TerminalMapPage } from '.';

export const Router = () => {
    return (
        <BrowserRouter>
            <ApplicationRoutes />
        </BrowserRouter>
    );
};

export const ApplicationRoutes = () => {
    const { terminalId } = useTerminalSettings();
    const { user } = useAuth();
    const [yardEnabled] = useCookie(SharedCookieNames.YardPage);
    const flowOverviewPageRoute = getParameterizedRoute(SharedSlugs.flowOverview);
    const controlOverviewPageRoute = getParameterizedRoute(SharedSlugs.controlOverview);
    const yardOverViewPageRoute = getParameterizedRoute(SharedSlugs.yardOverview);
    const mapEditPageRoute = getParameterizedRoute(SharedSlugs.mapEdit);
    const terminalMapPageRoute = getParameterizedRoute(SharedSlugs.terminalMap);
    const settingsPageRoute = getParameterizedRoute(SharedSlugs.settings);
    const yardOverViewDetailPageRoute = `${getParameterizedRoute(
        SharedSlugs.yardOverview,
    )}/:equipmentName`;
    const flowDetailPageRoute = '/:locale/terminal/:terminalId/flow/:quayCraneName/:view/';
    const eventPageRoute = '/:locale/terminal/:terminalId/events/:quayCraneShiftId?';
    const featureFlagPageRoute = '/:locale/terminal/:terminalId/settings/feature-flags';

    return (
        <Routes>
            <Route element={<MainLayout />}>
                <Route index path={flowOverviewPageRoute} element={<FlowOverviewPage />} />
                <Route path={controlOverviewPageRoute} element={<ControlOverviewPage />} />
                {yardEnabled && (
                    <>
                        <Route path={yardOverViewPageRoute} element={<YardOverviewPage />} />
                        <Route path={yardOverViewDetailPageRoute} element={<YardOverviewPage />} />
                    </>
                )}
                <Route path={terminalMapPageRoute} element={<TerminalMapPage />} />
                <Route path={flowDetailPageRoute} element={<FlowDetailPage />} />
                <Route path={eventPageRoute} element={<EventPage />} />
                {(user.isAdmin || user.isNormal) && (
                    <Route path={settingsPageRoute} element={<SettingsPage />} />
                )}
                <Route path={mapEditPageRoute} element={<MapEditPage />} />
                <Route path={featureFlagPageRoute} element={<FeatureFlagsPage />} />
            </Route>
            <Route
                path="*"
                element={
                    <Navigate
                        to={`/${defaultLocale}/terminal/${terminalId}${SharedSlugs.flowOverview}`}
                        replace
                    />
                }
            />
        </Routes>
    );
};
