import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiExclamationTriangle(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M8.426 2.486A1 1 0 0 1 9.283 2h1.434a1 1 0 0 1 .858.486l7.191 11.985a1 1 0 0 1-.025 1.07l-.676 1.014a1 1 0 0 1-.832.445H2.768a1 1 0 0 1-.832-.445L1.26 15.54a1 1 0 0 1-.026-1.07L8.426 2.485zM10.717 3H9.283L2.092 14.986 2.768 16h14.465l.676-1.014L10.717 3zM9.5 11V7h1v4h-1zm-.25 1.5h1.5V14h-1.5v-1.5z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiExclamationTriangle);
export default ForwardRef;
