import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiEyeSlash(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="m4.737 5.444-1.59-1.59.707-.708L5.66 4.954A10.132 10.132 0 0 1 10 4c5.49 0 8.368 4.112 8.85 4.867.104.162.15.343.15.517v1.232a.957.957 0 0 1-.15.517c-.293.46-1.473 2.16-3.587 3.423l1.59 1.59-.707.708-1.807-1.808A10.131 10.131 0 0 1 10 16c-5.49 0-8.368-4.112-8.85-4.867a.957.957 0 0 1-.15-.517V9.384c0-.174.046-.355.15-.517.293-.46 1.473-2.16 3.587-3.423zm.733.733C3.437 7.317 2.293 8.938 2 9.393v1.214C2.466 11.33 5.081 15 10 15a9.166 9.166 0 0 0 3.58-.712l-1.842-1.843a3 3 0 0 1-4.184-4.184L5.47 6.178zm2.807 2.807a2 2 0 0 0 2.74 2.74l-2.74-2.74zm3.446 2.032a2 2 0 0 0-2.74-2.74l2.74 2.74zm.722.722 2.085 2.085c2.033-1.14 3.177-2.761 3.47-3.216V9.393C17.534 8.67 14.919 5 10 5a9.165 9.165 0 0 0-3.58.713l1.842 1.842a3 3 0 0 1 4.184 4.184z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiEyeSlash);
export default ForwardRef;
