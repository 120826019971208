/* istanbul ignore file */
import { Loader, Switch } from '@maersk-global/apmt-react-components';
import {
    FeatureFlagV1,
    YardStackingEquipmentFlagV1,
} from '@maersk-global/digital-pull-operating-system-spec';
import { useTranslation } from 'react-i18next';

import { GridItem } from '@/components/atoms/GridItem/GridItem';
import { GridOfCards } from '@/components/atoms/GridOfCards/GridOfCards';
import { Title } from '@/components/atoms/Title/Title';
import { useAllQuayCranes } from '@/hooks/useAllQuayCranes';
import { useAllYardStackingEquipment } from '@/hooks/useAllYardStackingEquipment';
import { FlagType, useFeatureFlag } from '@/hooks/useFeatureFlag';
import { useTerminalStore } from '@/store/terminalStore';
import { naturalSort } from '@/utils/naturalSort';
import { toggleFeature } from '@/utils/toggleFeatureFlag';

export const FeatureFlagsPage = () => {
    const {
        data: allQuayCranes,
        isLoading: allQuayCranesLoading,
        error: allQuayCranesError,
    } = useAllQuayCranes();
    const { data: allYSEs } = useAllYardStackingEquipment();

    if (allQuayCranesLoading) {
        return <Loader />;
    }

    if (!allQuayCranes) {
        return null;
    }

    if (allQuayCranesError) {
        console.error('Error fetching quayCranes', allQuayCranesError);
        return null;
    }
    const yses = allYSEs?.map(({ name }) => name) ?? [];

    return (
        <div className="p-6">
            <GridOfCards>
                <ConfigPerFlag
                    flagName={{ cheType: 'QUAY_CRANE', flag: FeatureFlagV1.VMT_INSTRUCTIONS }}
                    quayCranes={allQuayCranes.map(a => a.quayCraneId)}
                />
                <ConfigPerFlag
                    flagName={{
                        cheType: 'YSE',
                        flag: YardStackingEquipmentFlagV1.VMT_INSTRUCTIONS,
                    }}
                    quayCranes={yses}
                />
            </GridOfCards>
        </div>
    );
};
const ConfigPerFlag = ({
    flagName: flagType,
    quayCranes,
}: {
    flagName: FlagType;
    quayCranes: string[];
}) => {
    const { t } = useTranslation();
    const terminalId = useTerminalStore(state => state.terminalId);

    const {
        data: featureFlagData,
        isLoading: featureFlagsLoading,
        error: featureFlagsError,
    } = useFeatureFlag(flagType);

    const toggleFeatureFlag = async (checked: boolean, quayCraneName: string) =>
        toggleFeature(terminalId, quayCraneName, flagType, checked);

    if (featureFlagsLoading) {
        return <Loader />;
    }

    if (!featureFlagData) {
        return <>No feature flags found</>;
    }

    if (featureFlagsError) {
        return null;
    }

    return (
        <GridItem className="space-y-4">
            <Title>
                {t(`pages.settings.featureFlags.${flagType.flag}.${flagType.cheType}.title`)}
            </Title>
            <p>
                {t(`pages.settings.featureFlags.${flagType.flag}.${flagType.cheType}.description`)}
            </p>

            <ul>
                {quayCranes
                    .sort((a, b) => naturalSort(a, b))
                    .map(quayCraneId => {
                        return (
                            <li
                                key={quayCraneId}
                                className="flex w-full border border-x-0 border-b-0 border-gray-300 py-3 last:border-b"
                            >
                                <Switch
                                    name={quayCraneId}
                                    label={quayCraneId}
                                    value={quayCraneId}
                                    checked={featureFlagData.includes(quayCraneId) ? true : false}
                                    className="ml-auto"
                                    onCheckedChange={checked =>
                                        toggleFeatureFlag(checked, quayCraneId)
                                    }
                                />
                            </li>
                        );
                    })}
            </ul>
        </GridItem>
    );
};
