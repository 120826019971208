import { SvgChevronDown, SvgChevronUp } from '@maersk-global/apmt-react-icons';
import * as AccordionPrimitive from '@radix-ui/react-accordion';
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, ElementRef, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

export const Accordion = forwardRef<
    ElementRef<typeof AccordionPrimitive.Root>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Root>
>(({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Root
        className={clsx('rounded border border-gray-400 shadow-md', className)}
        {...props}
        ref={forwardedRef}
    >
        {children}
    </AccordionPrimitive.Root>
));

export const AccordionItem = forwardRef<
    ElementRef<typeof AccordionPrimitive.Item>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Item> & { showBorders?: boolean }
>(({ children, className, showBorders = true, ...props }, forwardedRef) => (
    <AccordionPrimitive.Item
        className={clsx(
            'overflow-hidden bg-white first:rounded-t last:rounded-b last:border-b',
            className,
            {
                'border-t': showBorders,
            },
        )}
        {...props}
        ref={forwardedRef}
    >
        {children}
    </AccordionPrimitive.Item>
));

export const AccordionTrigger = forwardRef<
    ElementRef<typeof AccordionPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & { direction?: 'down' | 'up' }
>(({ children, className, direction = 'down', ...props }, forwardedRef) => (
    <AccordionPrimitive.Header
        className={clsx('flex bg-white data-[state=open]:active:bg-gray-200')}
    >
        <AccordionPrimitive.Trigger
            asChild
            className={clsx(
                'group flex h-[36px] flex-1 cursor-default items-center justify-between p-2 text-gray-900 outline-none hover:bg-gray-200',
                className,
            )}
            {...props}
            ref={forwardedRef}
        >
            <div>
                {children}
                {direction === 'up' && (
                    <SvgChevronUp
                        className="shrink-0 text-xl transition-transform duration-100 group-data-[state=open]:rotate-180"
                        aria-hidden
                    />
                )}
                {direction === 'down' && (
                    <SvgChevronDown
                        className="shrink-0 text-xl transition-transform duration-100 group-data-[state=open]:rotate-180"
                        aria-hidden
                    />
                )}
            </div>
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));

export const AccordionContent = forwardRef<
    ElementRef<typeof AccordionPrimitive.Content>,
    ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ children, className, ...props }, forwardedRef) => (
    <AccordionPrimitive.Content
        className={clsx('overflow-hidden', className)}
        {...props}
        ref={forwardedRef}
    >
        {children}
    </AccordionPrimitive.Content>
));

export interface AccordionContentItemProps {
    onSelect?: () => void;
}

export const AccordionContentItem = ({
    children,
    onSelect,
}: PropsWithChildren<AccordionContentItemProps>) => (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div className="p-2 pl-5 hover:bg-gray-200" onClick={onSelect}>
        {children}
    </div>
);
