/* istanbul ignore file */
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
    size?: number;
}
const EmptyHandlerIconIcon = forwardRef<SVGSVGElement, CustomIconProps>(
    ({ size, ...props }, svgRef) => {
        if (size) {
            props.width = size;
            props.height = size;
        }

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 269.5 141.5"
                ref={svgRef}
                {...props}
            >
                <path
                    fill="currentColor"
                    d="M.336 124.994h262v2h-262zm47.551-52.161l11.766-8.977.607.795-11.767 8.977zm3.476-6.128l8.727-6.76.612.79-8.726 6.76z"
                />
                <path
                    fill="currentColor"
                    d="M41.565 127.312a11.232 11.232 0 1111.233-11.232 11.245 11.245 0 01-11.233 11.232zm0-20.464a9.232 9.232 0 109.232 9.232 9.243 9.243 0 00-9.232-9.232zm71.45 20.464a11.232 11.232 0 1111.232-11.232 11.245 11.245 0 01-11.232 11.232zm0-20.464a9.232 9.232 0 109.232 9.232 9.243 9.243 0 00-9.232-9.232z"
                />
                <path
                    fill="currentColor"
                    d="M96.107 122.267H59.553L44.488 95.89H29.244l-1.683 23.996h4.24v2h-6.386L27.38 93.89h18.269l15.065 26.377h34.137l9.594-19.921h15.766v2h-14.51l-9.594 19.921zm-8.441-27.472L123.3 67.648l.606.795-35.635 27.148z"
                />
                <path
                    fill="currentColor"
                    d="M128.407 120.753h-5.156v-2h3.156V3.651h-5.943v103.731h-2V1.651h9.943v119.102z"
                />
                <path
                    fill="currentColor"
                    d="M123.003 64.129h1v10.089h-1zM81.896 82.622l-35.128-.03v-32.64l25.227.09 9.47 23.619zm-33.128-2.028l31.03.026-.315-6.527-8.843-22.057-21.872-.078zm17.975 5.339h9.28v1h-9.28zm5.309 5.176h9.28v1h-9.28zm3.649 5.177h9.28v1h-9.28zm-1.99 14.599h26.313v1H73.711zm-29.665-16.49h-1V45.771l-3.742-3.742.707-.707 4.035 4.034v49.039zm4.004 5.927h58.023v2H48.05z"
                />
                <path
                    fill="currentColor"
                    d="M47.111 55.128h26.724v1H47.111zm41.643 45.744l-6.618-18.255-33.635-.08-3.071 12.59-1.943-.474 3.444-14.119 36.608.086 7.096 19.57-1.881.682zm45.237 19.881h-2V72.301h9.964v2h-7.964v46.452z"
                />
            </svg>
        );
    },
);
export default EmptyHandlerIconIcon;
