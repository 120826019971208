import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { MapComponent } from '@/components/organisms/Map/Map';
import { useTerminalStore } from '@/store/terminalStore';

export const TerminalMapPage = () => {
    const { t } = useTranslation();
    const setPageTitle = useTerminalStore(state => state.setPageTitle);

    useEffect(() => {
        setPageTitle(t('pages.map.pageTitle'));
    }, [setPageTitle]);

    return <MapComponent className="h-screen-min-navigation" />;
};
