import { PropsWithChildren, createContext, useContext } from 'react';

import { TerminalSettings } from '@/api/getTerminalSettings';

const TerminalInfoContext = createContext<TerminalSettings | undefined>(undefined);

export const TerminalSettingsProvider = ({
    children,
    settings,
}: PropsWithChildren<{
    settings: TerminalSettings;
}>) => {
    return <TerminalInfoContext.Provider value={settings}>{children}</TerminalInfoContext.Provider>;
};

export const useTerminalSettings = () => {
    const settings = useContext(TerminalInfoContext);
    if (settings === undefined) {
        throw new Error('useTerminalSettings requires settings being set before being used');
    }

    return settings;
};
