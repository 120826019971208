/* istanbul ignore file */
import { YardStackingEquipmentTypeEnum } from '@maersk-global/digital-pull-operating-system-spec';

import { RtgIcon, EmptyHandlerIcon, ReachStackerIcon } from '@/icons/components';

export const EquipmentIcon = ({
    equipment,
    className,
    iconSize = 70,
}: {
    equipment: { type?: YardStackingEquipmentTypeEnum };
    className?: string;
    iconSize?: number;
}) => {
    const { type } = equipment;

    switch (type) {
        case YardStackingEquipmentTypeEnum.RUBBER_TYRED_GANTRY:
            return <RtgIcon className={className} size={iconSize} />;
        case YardStackingEquipmentTypeEnum.EMPTY_HANDLER:
            return <EmptyHandlerIcon className={className} size={iconSize} />;
        case YardStackingEquipmentTypeEnum.REACH_STACKER:
            return <ReachStackerIcon className={className} size={iconSize} />;
        default:
            return null;
    }
};
