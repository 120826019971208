import { Table, Tag } from '@maersk-global/apmt-react-components';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { routes } from '@/routes/routes';
import { AndonEvent, Andons } from '@/types/andons';

type AndonTableData = AndonEvent;

export const FlowDetailDrawerAndonsOverview = ({ andons }: { andons: Andons }) => {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<AndonTableData>();
    const columns = useMemo(
        () => [
            columnHelper.accessor('cheName', {
                header: () => <span>{t('pages.positions.detailDrawer.assetColumnTitle')}</span>,
                size: 80,
                cell: data => {
                    return (
                        <span className="absolute left-0 top-0 size-full px-3 py-[6px]">
                            <span className="before:absolute before:left-0 before:top-0 before:inline-block before:h-full before:w-1 before:bg-feedback-danger">
                                {data.getValue()}
                            </span>
                        </span>
                    );
                },
            }),
            columnHelper.accessor('andonType', {
                header: () => <span>{t('pages.positions.detailDrawer.typeColumnTitle')}</span>,
                size: 120,
                cell: data => {
                    return (
                        <a
                            href={routes.andon()}
                            className="after:absolute after:left-0 after:top-0 after:z-50 after:size-full"
                        >
                            {data.getValue()}
                        </a>
                    );
                },
            }),
            columnHelper.accessor('cheType', {
                header: () => <span>{t('pages.positions.detailDrawer.statusColumnTitle')}</span>,
                size: 120,
                cell: () => {
                    return (
                        <Tag
                            label={t('labels.andonStatus.open')}
                            appearance={'error'}
                            fit="small"
                        />
                    );
                },
            }),
        ],
        [],
    );

    return <Table cellClassName="relative" data={andons} columns={columns} tableLayout="fixed" />;
};
