import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiChevronUp(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M10.517 7H9.483l-5.1 6.072-.766-.644 5.1-6.071A1 1 0 0 1 9.483 6h1.034a1 1 0 0 1 .766.357l5.1 6.071-.766.644L10.517 7z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiChevronUp);
export default ForwardRef;
