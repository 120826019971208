/* istanbul ignore file */
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
    size?: number;
}
const ReachStackerIconIcon = forwardRef<SVGSVGElement, CustomIconProps>(
    ({ size, ...props }, svgRef) => {
        if (size) {
            props.width = size;
            props.height = size;
        }

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 269.5 141.5"
                ref={svgRef}
                {...props}
            >
                <path
                    fill="currentColor"
                    d="M185.046 67.726l15.216-11.606.606.795-15.216 11.606zm4.569-8.054l11.221-8.692.613.79-11.222 8.692z"
                />
                <path
                    fill="currentColor"
                    d="M214.399 84.036h-29.54V50.515h29.54zm-27.54-2h25.54V52.515h-25.54z"
                />
                <path
                    fill="currentColor"
                    d="M185.046 67.726l15.216-11.606.606.795-15.216 11.606zm4.569-8.054l11.221-8.692.613.79-11.222 8.692z"
                />
                <path
                    fill="currentColor"
                    d="M214.399 84.036h-29.54V50.515h29.54zm-27.54-2h25.54V52.515h-25.54zM20.404 124.954h228.192v2H20.404zM71.223 85.65l11.767-8.976.607.796-11.768 8.975zm3.476-6.127l8.727-6.76.612.79-8.727 6.76z"
                />
                <path
                    fill="currentColor"
                    d="M51.188 127.312A11.232 11.232 0 1162.42 116.08a11.245 11.245 0 01-11.233 11.232zm0-20.464a9.232 9.232 0 109.232 9.232 9.242 9.242 0 00-9.233-9.232zm71.449 20.464a11.232 11.232 0 1111.232-11.232 11.245 11.245 0 01-11.232 11.232zm0-20.464a9.232 9.232 0 109.232 9.232 9.243 9.243 0 00-9.232-9.232z"
                />
                <path
                    fill="currentColor"
                    d="M105.729 122.267H69.175L54.11 95.89H38.866l-1.683 23.996h4.241v2h-6.386l1.964-27.996h18.269l15.065 26.377h34.137l9.594-19.921h11.134v2h-9.878l-9.594 19.921z"
                />
                <path fill="currentColor" d="M88.066 44.96l.849-.528 35.533 57.033-.849.528z" />
                <path
                    fill="currentColor"
                    d="M32.944 71.484l-1.979-8.87 175.382-55.77 1.961 9.764zm.372-7.518l1.107 4.96 171.57-53.689-1.167-5.81zm71.916 31.474l-35.128-.03V62.77l25.227.09 9.47 23.619zm-33.128-2.028l31.03.027-.314-6.528-8.844-22.056-21.872-.079zm8.242 12.428h9.281v1h-9.281zm5.309 5.177h9.28v1h-9.28zm3.65 5.177h9.281v1h-9.281zm-31.633-15.872h58.023v2H57.672z"
                />
                <path
                    fill="currentColor"
                    d="M128.726 96.347l-64.491-.909-10.613-26.976h-5.856l-.443 27.002-2-.032.475-28.97h9.186l10.621 26.996 63.149.889-.028 2zM75.691 57.548l-.636-.772 13.893-11.442 14.821 2.703-.179.983-14.365-2.619-13.534 11.147zM213.9 52.002h-28.524v-6.525h28.523zm-27.558-.966h26.59v-4.592h-26.59z"
                />
                <path
                    fill="currentColor"
                    d="M187.442 46.062l.945-.204 1.202 5.559-.945.204zm5.561 0l.945-.204 1.202 5.559-.945.204zm5.561 0l.945-.204 1.202 5.559-.945.204zm5.561 0l.945-.204 1.202 5.559-.946.204zm5.562 0l.945-.204 1.202 5.559-.945.204zm-10.549-31.353h1v22.455h-1z"
                />
                <path
                    fill="currentColor"
                    d="M209.507 43.188h-3.878v-1h2.878v-4.524h-17.738v4.524h2.794v1h-3.794v-6.524h19.738v6.524z"
                />
                <path
                    fill="currentColor"
                    d="M206.097 46.428h-12.919v-6.74h12.919zm-11.984-.935h11.049v-4.87h-11.049z"
                />
            </svg>
        );
    },
);
export default ReachStackerIconIcon;
