import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiBarsHorizontalFunnelDown(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M17 5H3V4h14v1zm-2 5.5H5v-1h10v1zM12 16H8v-1h4v1z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiBarsHorizontalFunnelDown);
export default ForwardRef;
