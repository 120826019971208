import { queryClient } from '@/components/Application';
import { FlagType } from '@/hooks/useFeatureFlag';

import { disableFeatureFlag } from './disableFeatureFlag';
import { enableFeatureFlag } from './enableFeatureFlag';

export const toggleFeature = async (
    terminalId: string,
    cheId: string,
    flagType: FlagType,
    checked: boolean,
) => {
    if (!checked) {
        await disableFeatureFlag(terminalId, cheId, flagType);
    } else {
        await enableFeatureFlag(terminalId, cheId, flagType);
    }

    await queryClient.invalidateQueries({
        queryKey: [flagType.cheType + flagType.flag],
    });
};
