import { useTranslation } from 'react-i18next';

import { Block } from '@/components/atoms/Block/Block';
import { DelayCodes } from '@/types/delayCodes';

export const FlowDetailDrawerDelayCodes = ({ delayCodes }: { delayCodes: DelayCodes }) => {
    const { t } = useTranslation();

    return (
        <>
            {delayCodes.map((delayCode, index) => {
                return (
                    <Block key={`${index}-${delayCode.code}`} className="text-sm">
                        <span className="text-neutral-500">{t('pages.flows.delayCode')}:</span>
                        <span className="font-bold">
                            {delayCode.code} - {delayCode.description}
                        </span>
                    </Block>
                );
            })}
        </>
    );
};
