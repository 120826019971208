import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowDown(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M9.5 14h-.27l-2.85-3.325-.76.65 2.85 3.326a1 1 0 0 0 .76.349h1.54a1 1 0 0 0 .759-.35l2.85-3.325-.759-.65L10.77 14h-.27V5h-1v9z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowDown);
export default ForwardRef;
