import { SVGProps } from 'react';

export function SvgTriangle(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="14"
            height="16"
            viewBox="0 0 14 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M1 15V1L13 8L1 15Z" fill="#EDEDED" stroke="#141414" />
        </svg>
    );
}
export function SvgSquare(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect x="1" y="1" width="12" height="12" fill="#EDEDED" stroke="#141414" />
        </svg>
    );
}
export function SvgCirlce(props: SVGProps<SVGSVGElement>) {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx="8" cy="8" r="7" fill="#EDEDED" stroke="#141414" />
        </svg>
    );
}
