import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/shallow';

import { FlowPosition, getTruckAppearance } from '@/components/molecules/FlowPosition/FlowPosition';
import { QuayCranePosition } from '@/components/molecules/QuayCranePosition/QuayCranePosition';
import { YardEquipment } from '@/components/molecules/YardEquipment/YardEquipment';
import {
    CheType,
    FlowPositionName,
    TerminalTruckLocation,
    VisualAppearance,
} from '@/constants/enums';
import { useTruckStatusPerQuayCrane } from '@/hooks/useTruckStatusPerQuayCrane';
import { useTerminalStore } from '@/store/terminalStore';
import { PiecesOfEquipment, WorkQueues } from '@/types';
import { Andons } from '@/types/andons';
import { cn } from '@/utils/cn';
import { filterCompletedTrucks } from '@/utils/filterCompletedTrucks';
import { getFlowStatus } from '@/utils/getFlowStatus';

import { EquipmentRow } from './EquipmentRow';
import { FlowDetailBackgroundLine } from './FlowVisualisationBackgroundLine';

export type FlowVisualisationPositions = FlowPositionName | 'IN_YARD';

export const FlowVisualisation = ({
    workQueues,
    andons = [],
    stackingEquipment = [],
    consideredCompletedTruckNames,
}: {
    workQueues: WorkQueues[];
    consideredCompletedTruckNames: string[];
    andons: Andons;
    stackingEquipment?: PiecesOfEquipment | [];
}) => {
    const workQueuesWithoutCompletedTrucks = filterCompletedTrucks(
        workQueues,
        consideredCompletedTruckNames,
    );
    const { t } = useTranslation();
    const quayCraneName = useTerminalStore(useShallow(state => state.quayCraneName));
    const instructionsForThisQuayCrane = workQueuesWithoutCompletedTrucks
        .map(wq => wq.instructions)
        .flat(1);
    const trucks = useTruckStatusPerQuayCrane(quayCraneName);

    const trucksInStandby = instructionsForThisQuayCrane
        .filter(({ instruction }) => instruction === FlowPositionName.STANDBY)
        .map(truck => {
            return {
                truckId: truck.truckId,
                appearance: getTruckAppearance(truck.instruction, truck.hasReachedFlowPosition),
            };
        });

    const trucksUnderCrane = instructionsForThisQuayCrane.filter(
        ({ instruction }) => instruction === FlowPositionName.UNDER_CRANE,
    );

    const trucksInPull = instructionsForThisQuayCrane
        .filter(({ instruction }) => instruction === FlowPositionName.PULL)
        .map(truck => {
            return {
                truckId: truck.truckId,
                appearance: getTruckAppearance(truck.instruction, truck.hasReachedFlowPosition),
            };
        });

    const { flowStatus } = getFlowStatus(workQueues);
    const isStopped = flowStatus === 'STOPPED';

    const trucksAssignedToStackingEquipment = trucks?.filter(
        ({ assignedToYardStackingEquipment }) => assignedToYardStackingEquipment,
    );

    const trucksInYard = trucks
        ?.filter(truck => truck.truckLocation === TerminalTruckLocation.YARD)
        .map(truck => {
            return {
                truckId: truck.terminalTruckId,
                appearance: VisualAppearance.IDLE,
            };
        });

    return (
        <div className="mx-auto mb-6 mt-52 min-w-[704px] max-w-2xl pb-6 pl-12 pr-20">
            <FlowDetailBackgroundLine flowStatus={flowStatus} className="min-h-[236px]">
                <div
                    className={cn(
                        'relative z-20 -mt-32 grid grid-cols-flow_detail_cols_large grid-rows-flow_detail_rows',
                        {
                            'border-gray-400': !isStopped,
                            'border-feedback-danger': isStopped,
                        },
                    )}
                >
                    {/* ROW 1 */}
                    <EquipmentRow
                        fifthCell={
                            <QuayCranePosition
                                name={quayCraneName}
                                trucks={trucksUnderCrane}
                                flowStatus={flowStatus}
                                andon={andons.some(andon => andon.cheType === CheType.QUAY_CRANE)}
                            />
                        }
                    />

                    {/* ROW 2 */}
                    <EquipmentRow
                        thirdCell={
                            <div className="relative">
                                <div className="absolute top-[-66px] w-full">
                                    <FlowPosition
                                        singleTag={trucksInPull.length === 1}
                                        trucks={trucksInPull}
                                        label={t('labels.positions.pull')}
                                        flowStatus={flowStatus}
                                        id={FlowPositionName.PULL}
                                    />
                                </div>
                            </div>
                        }
                        fourthCell={
                            <div className="relative">
                                <div className="absolute top-[-66px] w-full">
                                    <FlowPosition
                                        singleTag={trucksInStandby.length === 1}
                                        trucks={trucksInStandby}
                                        label={t('labels.positions.standby')}
                                        flowStatus={flowStatus}
                                        id={FlowPositionName.STANDBY}
                                    />
                                </div>
                            </div>
                        }
                    />

                    {/* ROW 3 */}
                    <EquipmentRow
                        seventhCell={
                            <div className="relative flex">
                                <FlowPosition
                                    trucks={trucksInYard || []}
                                    label={t('labels.positions.yard')}
                                    row
                                    id={'IN_YARD'}
                                />
                            </div>
                        }
                    />

                    {stackingEquipment.map((equipment, index) => {
                        const andonsForYardEquipment = andons.some(
                            andon => andon.cheName === equipment.name,
                        );

                        const trucksAssignedToYardEquipment = trucksAssignedToStackingEquipment
                            ?.filter(
                                truck =>
                                    truck.assignedToYardStackingEquipment?.name === equipment.name,
                            )
                            .filter(
                                truck =>
                                    truck.truckLocation ===
                                    TerminalTruckLocation.YARD_STACKING_EQUIPMENT,
                            );

                        return (
                            <EquipmentRow
                                key={`${equipment.name}-${index}`}
                                border={index !== stackingEquipment.length - 1}
                                fourthCell={
                                    <YardEquipment
                                        equipment={equipment}
                                        andon={andonsForYardEquipment}
                                        trucks={trucksAssignedToYardEquipment || []}
                                    />
                                }
                            />
                        );
                    })}
                </div>
            </FlowDetailBackgroundLine>
        </div>
    );
};
