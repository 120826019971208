import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import type { ComponentPropsWithoutRef, ElementRef, ReactElement } from 'react';
import { forwardRef } from 'react';

import { cn } from '../common/cn';

const rootFitClass = {
    small: 'min-h-[1rem] min-w-[1rem]',
    medium: 'min-h-[1.25rem] min-w-[1.25rem]',
    large: 'min-h-[1.5rem] min-w-[1.5rem]',
} as const;

const labelFitClass = {
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-base',
} as const;

type CheckboxFit = keyof typeof rootFitClass;

const labelPosition = {
    left: 'left',
    right: 'right',
} as const;

interface CheckboxProps
    extends Omit<ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>, 'asChild'> {
    label: ReactElement | string;
    labelPosition?: keyof typeof labelPosition;
    fit?: CheckboxFit;
}

const IndeterminateIcon = ({ fit, className }: { fit: CheckboxFit; className?: string }) => {
    let d, width, height;
    switch (fit) {
        case 'small':
            d = 'M0 1H8';
            width = 8;
            height = 2;
            break;
        case 'large':
            d = 'M0 1H12';
            width = 12;
            height = 2;
            break;
        default:
            d = 'M0 1H10';
            width = 10;
            height = 2;
    }
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d={d} strokeWidth="2" stroke="white" />
        </svg>
    );
};

const CheckedIcon = ({ fit, className }: { fit: CheckboxFit; className?: string }) => {
    let d, width, height;
    switch (fit) {
        case 'small':
            d = 'M1.5 5.5L4.92615 8.92615C4.96641 8.96641 5.0321 8.96498 5.07057 8.92301L10.5 3';
            width = 12;
            height = 12;
            break;
        case 'large':
            d = 'M3.5 9.5L8.92765 13.9408C8.96837 13.9741 9.02795 13.9702 9.06393 13.9318L16.5 6';
            width = 20;
            height = 20;
            break;
        default:
            d = 'M2.5 7.5L6.92687 11.435C6.96751 11.4711 7.02958 11.4681 7.06658 11.4283L13.5 4.5';
            width = 16;
            height = 16;
    }
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path d={d} strokeWidth="2" stroke="white" />
        </svg>
    );
};

export const Checkbox = forwardRef<ElementRef<typeof CheckboxPrimitive.Root>, CheckboxProps>(
    (
        {
            id,
            label,
            labelPosition = 'left',
            fit = 'medium',
            disabled,
            checked,
            className,
            ...otherProps
        },
        ref,
    ) => {
        const Label = (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            <label
                htmlFor={id}
                className={`w-full ${labelFitClass[fit]}`}
                onClick={e => e.stopPropagation()}
            >
                {label}
            </label>
        );

        return (
            <div
                className={cn('flex w-full items-center gap-2', {
                    'opacity-50': disabled,
                })}
            >
                {(labelPosition === 'left' && Label) || null}
                <CheckboxPrimitive.Root
                    id={id}
                    ref={ref}
                    disabled={disabled}
                    checked={checked}
                    {...otherProps}
                    className={cn(
                        'flex items-center justify-center rounded border',
                        'focus:ring focus:ring-feedback-info-light',
                        'data-[state=checked]:border-blue-300 data-[state=checked]:bg-blue-300',
                        'data-[state=indeterminate]:border-blue-300 data-[state=indeterminate]:bg-blue-300',
                        'data-[state=unchecked]:border-gray-400 data-[state=unchecked]:bg-white hover:data-[state=unchecked]:border-gray-600',
                        rootFitClass[fit],
                        className,
                    )}
                >
                    <CheckboxPrimitive.Indicator className="group">
                        <CheckedIcon
                            fit={fit}
                            className="hidden text-white group-data-[state=checked]:block"
                        />
                        <IndeterminateIcon
                            fit={fit}
                            className="hidden text-white group-data-[state=indeterminate]:block"
                        />
                    </CheckboxPrimitive.Indicator>
                </CheckboxPrimitive.Root>
                {(labelPosition === 'right' && Label) || null}
            </div>
        );
    },
);
