import { useEffect, useRef, useState } from 'react';

type UseIntersectionObserverProps = {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number;
};

export const useIntersectionObserver = ({
    root = null,
    rootMargin,
    threshold = 0,
}: UseIntersectionObserverProps) => {
    const observerRef = useRef<IntersectionObserver | null>(null);
    const [entry, updateEntry] = useState<IntersectionObserverEntry>();
    const [ref, setRef] = useState<Element | null>(null);

    // We have to wrap this in a useEffect because the IntersectionObserver constructor is not available on the server
    useEffect(() => {
        observerRef.current = new IntersectionObserver(([entry]) => updateEntry(entry), {
            root,
            rootMargin,
            threshold,
        });
    }, [root]);

    useEffect(() => {
        const { current: currentObserver } = observerRef;
        // If there is already a ref attached to the observer, disconnect it
        currentObserver?.disconnect();

        if (ref) {
            currentObserver?.observe(ref);
        }

        return () => currentObserver?.disconnect();
    }, [ref]);

    return { setRef, entry };
};
