import clsx from 'clsx';

const menuItemLine =
    'after:absolute after:bottom-0 after:right-0 after:w-[2px] after:h-full after:z-10';

export const getLinkColor = (isActive: boolean) => {
    return clsx('relative text-gray-900', menuItemLine, {
        'hover:bg-gray-200 after:bg-transparent hover:after:bg-blue-300': !isActive,
        'after:bg-blue-300 hover:bg-gray-200': isActive,
    });
};
