import { FlowPositionName, FlowStatusOption } from '@/constants/enums';
import { MoveInstructions } from '@/types';

export const calculateFlowStatus = (instructions: MoveInstructions): FlowStatusOption => {
    if (!instructions || instructions.length === 0) {
        return 'STOPPED';
    }

    const inStandby = instructions.filter(
        ({ instruction }) => instruction === FlowPositionName.STANDBY,
    ).length;

    const inPull = instructions.filter(
        ({ instruction }) => instruction === FlowPositionName.PULL,
    ).length;

    const underCrane = instructions.filter(
        ({ instruction }) => instruction === FlowPositionName.UNDER_CRANE,
    ).length;

    // 1 in pull, 1 in standby, 1 in undercrane
    const flowIsActive = inPull === 1 && inStandby === 1 && underCrane === 1;
    // 0 in pull, 1 in standby, 1 in undercrane
    const flowIsAtRisk =
        (inPull < 1 && inStandby === 1 && underCrane === 1) ||
        (inPull === 1 && inStandby === 0 && underCrane === 1);
    // 0 in pull, 0 in standby, 1 in undercrane
    const flowIsStopped = inPull < 1 && inStandby < 1 && underCrane === 1;
    // more than 1 in pull, 1 in standby, 1 in undercrane
    const flowIsOverDeployed = inPull > 1 && inStandby === 1 && underCrane === 1;

    if (underCrane === 0) {
        return 'STOPPED';
    }

    if (flowIsActive) {
        return 'ACTIVE';
    }

    if (flowIsOverDeployed) {
        return 'OVER_DEPLOYED';
    }

    if (flowIsAtRisk) {
        return 'RISK';
    }

    if (flowIsStopped) {
        return 'STOPPED';
    }

    // Unknown
    return 'UNKNOWN';
};
