import { MoveInstructionPayload } from '@/types';

export const filterCompletedTrucks = (
    workQueues: MoveInstructionPayload['workQueues'],
    consideredCompletedTruckNames: string[],
): MoveInstructionPayload['workQueues'] => {
    return workQueues.map(wq => {
        const instructions = wq.instructions.filter(
            i => !consideredCompletedTruckNames.includes(i.truckId),
        );
        return {
            ...wq,
            instructions,
        } as MoveInstructionPayload['workQueues'][number];
    });
};
