const rootFitClass = {
    small: 'h-4 w-4',
    medium: 'h-5 w-5',
    large: 'h-6 w-6',
} as const;

const labelFitClass = {
    small: 'text-sm',
    medium: 'text-base',
    large: 'text-base',
} as const;

type LoaderFit = keyof typeof rootFitClass;

interface LoaderProps {
    message?: string;
    fit?: LoaderFit;
}

export const Loader = ({ message = 'Loading', fit = 'medium' }: LoaderProps) => {
    return (
        <div className="mt-4 flex flex-col items-center">
            <div className={rootFitClass[fit]}>
                <svg className="container" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <circle
                        className="stroke-gray-200 stroke-[2px]"
                        fill="none"
                        cx="12"
                        cy="12"
                        r="10"
                    ></circle>
                    <circle
                        className="origin-center animate-spin stroke-blue-300 stroke-[2px]"
                        fill="none"
                        cx="12"
                        cy="12"
                        r="10"
                        strokeDasharray="12 100"
                    ></circle>
                </svg>
            </div>
            <span className={labelFitClass[fit]}>{message}</span>
        </div>
    );
};
