import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/shallow';

import { getAllYardStackingEquipment } from '@/api/getAllYardStackingEquipmentNames';
import { reactQueryKeys } from '@/constants/queryKeys';
import { useTerminalStore } from '@/store/terminalStore';
export function useAllYardStackingEquipment() {
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));

    return useQuery({
        queryKey: [reactQueryKeys.YARD_STACKING_EQUIPMENT, terminalId],
        queryFn: () => getAllYardStackingEquipment(terminalId),
        enabled: !!terminalId,
    });
}
