import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiDoorArrowRight(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M2 4a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3h-1V4H3v12h8v-3h1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4zm15 5.24-2.312-1.85.624-.78 2.313 1.85a1 1 0 0 1 .375.78v1.52a1 1 0 0 1-.375.78l-2.313 1.85-.624-.78L17 10.76v-.26H9v-1h8v-.26z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiDoorArrowRight);
export default ForwardRef;
