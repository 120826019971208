import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { defaultLocale } from './constants/locales';
import translations from './locales/translations';

export const languages = {
    en: {
        key: 'en',
        nativeName: 'English',
    },
    nl: {
        key: 'nl',
        nativeName: 'Nederlands',
    },
};

i18n.use(detector)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        // debug: process.env.NODE_ENV === 'development',
        fallbackLng: defaultLocale,
        interpolation: {
            escapeValue: false, // React already escapes values
        },
        resources: translations,
        returnNull: false,
        ns: ['common'],
        defaultNS: 'common',
        detection: {
            order: ['path', 'localStorage', 'cookie', 'htmlTag'],
        },
    })
    .catch(err => console.error(err));

export default i18n;

/* istanbul ignore file */
