import { array, Codec, exactly, GetType, string } from 'purify-ts';

export const delayCodeDecoder = Codec.interface({
    code: string,
    description: string,
});

export const delayCodeEventPayloadDecoder = Codec.interface({
    quayCraneId: string,
    delayCodes: array(delayCodeDecoder),
});

export type DelayCodeEventPayload = GetType<typeof delayCodeEventPayloadDecoder>;

export const delayCodeEventDecoder = Codec.interface({
    event: exactly('QUAY_CRANE_DELAY_CODES'),
    data: delayCodeEventPayloadDecoder,
});
export type DelayCodes = DelayCodeEventPayload['delayCodes'];
