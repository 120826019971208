import { SvgCheckCircle, SvgExclamationTriangle } from '@maersk-global/apmt-react-icons';

import { VisualAppearance } from '@/constants/enums';
import { cn } from '@/utils/cn';

type ComponentProps = {
    label: string;
    appearance?: VisualAppearance;
};

export const Truck = ({ appearance = VisualAppearance.IDLE, label }: ComponentProps) => {
    const hasError = appearance === VisualAppearance.ERROR;
    const isActive = appearance === VisualAppearance.ACTIVE;
    const isDone = appearance === VisualAppearance.DONE;
    const isIdle = appearance === VisualAppearance.IDLE;
    const isInProgress = appearance === VisualAppearance.IN_PROGRESS;
    const showIcon = hasError || isDone || isInProgress;

    return (
        <div className="mb-1 inline-flex justify-center last-of-type:mb-0">
            <span
                className={cn(
                    'mds-font--small inline-flex flex-1 items-stretch text-ellipsis whitespace-nowrap rounded-3xl border-3 px-[6px] py-px text-white',
                    {
                        'border-transparent bg-gray-500': isIdle,
                        'border-transparent bg-blue-500': isDone || isActive || isInProgress,
                        'border-feedback-danger bg-feedback-danger-light text-black': hasError,
                    },
                )}
            >
                {showIcon ? (
                    <span className="mr-1 inline-flex size-5">
                        <TruckIcon appearance={appearance} />
                    </span>
                ) : null}
                <span className={cn('inline-block w-full text-center')}>{label}</span>
            </span>
        </div>
    );
};

export const TruckIcon = ({ appearance }: { appearance: VisualAppearance }) => {
    switch (appearance) {
        case VisualAppearance.DONE:
            return (
                <SvgCheckCircle
                    color="var(--mdt-theme-palette-functional-50)"
                    className="text-xl"
                />
            );
        case VisualAppearance.IN_PROGRESS:
            return <ProgressDots />;
        case VisualAppearance.ERROR:
            return (
                <SvgExclamationTriangle
                    color="var(--mdt-theme-palette-feedback-danger-dark)"
                    className="text-xl"
                />
            );
        default:
            return null;
    }
};

export const ProgressDots = () => {
    const dotClasses: React.ComponentProps<'span'>['className'] =
        'rounded bg-white h-1 w-1 absolute';

    return (
        <span className="relative inline-flex size-5 items-center">
            <span className={`${dotClasses} left-[1px] animate-dot-1`}></span>
            <span className={`${dotClasses} left-[7px] animate-dot-2`}></span>
            <span className={`${dotClasses} left-[13px] animate-dot-3`}></span>
        </span>
    );
};
