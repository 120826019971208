import { PropsWithChildren } from 'react';

export const FloatingBlocksContainer = ({
    children,
    testid,
}: PropsWithChildren<{ testid: string }>) => {
    return (
        <div className="mb-2 flex flex-col items-start gap-2" data-testid={testid}>
            {children}
        </div>
    );
};
