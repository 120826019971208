import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import { Button, Tooltip, TooltipProvider } from '@maersk-global/apmt-react-components';
import { SvgArrowClockwise, SvgBucketMop } from '@maersk-global/apmt-react-icons';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from 'react-use';

import { resendMoveInstructions, triggerReset } from '@/api';
import { Block } from '@/components/atoms/Block/Block';

export type InstructionsButtonsProps = {
    quayCraneName: string;
    terminalId: string;
    vesselVisitId: string;
    showButtonLabels?: boolean;
    className?: string;
};

export const QuayCraneInstructionsButtons = ({
    quayCraneName,
    terminalId,
    vesselVisitId,
    className,
    showButtonLabels = true,
}: InstructionsButtonsProps) => {
    const { t } = useTranslation();
    const [sentInstructionsPending, setSentInstructionsPending] = useState(false);
    const [sentResetCompleted, setSentResetCompleted] = useState(false);

    const { user } = useAuth();

    const handleUpdateClicked = async () => {
        await resendMoveInstructions(terminalId, quayCraneName, vesselVisitId);

        setSentInstructionsPending(value => !value);

        setTimeout(() => {
            setSentInstructionsPending(value => !value);
        }, 1000);
    };

    const handleResetClicked = async () => {
        await triggerReset(terminalId, quayCraneName, vesselVisitId);

        setSentResetCompleted(value => !value);

        setTimeout(() => {
            setSentResetCompleted(value => !value);
        }, 1000);
    };

    if (user.isAdmin || user.isNormal) {
        return (
            <TooltipProvider>
                <Block className={className}>
                    <FlowDetailDrawerButtonTooltip
                        buttonIcon={<SvgBucketMop className="size-5" />}
                        buttonLabel={t('pages.positions.detailDrawer.removeCompletedButton.label')}
                        tooltipContent={t(
                            'pages.positions.detailDrawer.removeCompletedButton.tooltip',
                        )}
                        loading={sentResetCompleted}
                        onClick={handleResetClicked}
                        showLabel={showButtonLabels}
                    />
                    <FlowDetailDrawerButtonTooltip
                        buttonIcon={<SvgArrowClockwise className="size-5" />}
                        buttonLabel={t('pages.positions.detailDrawer.resetButton.label')}
                        tooltipContent={t('pages.positions.detailDrawer.resetButton.tooltip')}
                        loading={sentInstructionsPending}
                        onClick={handleUpdateClicked}
                        showLabel={showButtonLabels}
                    />
                </Block>
            </TooltipProvider>
        );
    } else {
        return <></>;
    }
};

export const FlowDetailDrawerButtonTooltip = ({
    buttonIcon,
    buttonLabel,
    tooltipContent,
    loading,
    onClick,
    showLabel,
    size = 'small',
    testId,
}: {
    buttonIcon: ReactNode;
    buttonLabel: string;
    tooltipContent: string;
    loading: boolean;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    showLabel: boolean;
    testId?: string;
    size?: 'tiny' | 'small';
}) => {
    const { width: windowWidth } = useWindowSize();

    return (
        <Tooltip
            align={windowWidth < 1280 ? 'start' : 'center'}
            className="max-w-[300px] md:w-80"
            content={tooltipContent}
        >
            <Button
                data-testid={testId}
                fit={size}
                icon={buttonIcon}
                variant="secondary"
                loading={loading}
                onClick={onClick}
            >
                {showLabel && buttonLabel && <span className="text-ellipsis">{buttonLabel}</span>}
            </Button>
        </Tooltip>
    );
};
