import { SvgExclamationTriangle } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';

export const AndonsLabel = ({ andons }: { andons: number }) => {
    const { t } = useTranslation();

    return (
        <div className="absolute bottom-1 left-1 z-20 flex items-center gap-2 rounded-[4px] border-2 border-feedback-danger bg-feedback-danger-subtle p-2 py-1">
            <SvgExclamationTriangle className="text-xl" />
            {t('labels.andonWithCount', { count: andons })}
        </div>
    );
};
