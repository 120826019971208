import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowAllDirections(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M8.48 1.331A1 1 0 0 1 9.222 1h1.554a1 1 0 0 1 .744.331l2.1 2.335-.743.668L10.778 2H10.5v6h-1V2h-.277L7.122 4.334l-.744-.668L8.48 1.33zM18 9.5v-.277l-2.335-2.101.67-.744 2.334 2.101a1 1 0 0 1 .331.744v1.554a1 1 0 0 1-.331.744l-2.335 2.1-.668-.743 2.334-2.1V10.5h-6v-1h6zM10.5 12v6h.277l2.101-2.335.744.67-2.101 2.334a1 1 0 0 1-.744.331H9.223a1 1 0 0 1-.744-.331l-2.1-2.335.743-.668L9.222 18H9.5v-6h1zM2 10.5v.277l2.334 2.101-.668.744L1.33 11.52a1 1 0 0 1-.33-.743V9.223a1 1 0 0 1 .331-.744l2.335-2.1.668.743L2 9.222V9.5h6v1H2z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowAllDirections);
export default ForwardRef;
