import { WorkQueueStatus } from '@/constants/enums';
import { MoveInstructionsPerQuayCrane } from '@/types';
import { VesselWithQuayCranes } from '@/types/vessels';

import { naturalSort } from './naturalSort';

export const createVesselsWithQuayCranesObject = (
    quayCranes: MoveInstructionsPerQuayCrane,
): VesselWithQuayCranes[] => {
    const vesselWithQuayCranes: VesselWithQuayCranes[] = [];
    // For each quayCrane
    Object.entries(quayCranes)
        .sort(([id], [id2]) => naturalSort(id, id2))
        .forEach(([quayCraneName, moveInstructions]) => {
            // And each workQueue in the quayCrane
            moveInstructions.workQueues.forEach(workQueue => {
                const { vesselName, vesselVisitId } = workQueue;

                // If the vesselName is empty, show all quayCranes without a vesselName
                if (!vesselName) {
                    if (vesselWithQuayCranes.some(v => v.vesselName === '')) {
                        return;
                    }

                    vesselWithQuayCranes.push({
                        vesselName: '',
                        vesselVisitId: '',
                        quayCranes: [
                            {
                                ...moveInstructions,
                                quayCraneName,
                            },
                        ],
                    });

                    return;
                }

                if (vesselWithQuayCranes.some(v => v.vesselName === vesselName)) {
                    // If the vesselName exists, skip this iteration
                    return;
                }

                // Vesselname should be present and the workqueue should be active
                const quayCranesPerVessel = Object.entries(quayCranes)
                    .filter(([, moveInstructions]) =>
                        moveInstructions.workQueues.find(wq => {
                            return (
                                wq.vesselName === vesselName && wq.status === WorkQueueStatus.ACTIVE
                            );
                        }),
                    )
                    .sort(([id], [id2]) => naturalSort(id, id2));

                // Filter out inactive workqueues
                const quayCranesPerVesselWithActiveWorkQueues = quayCranesPerVessel
                    .map(([quayCraneName, moveInstructions]) => {
                        const activeWorkQueues = moveInstructions.workQueues.filter(
                            workQueue => workQueue.status === WorkQueueStatus.ACTIVE,
                        );
                        const { spreaderAction = undefined } = moveInstructions;

                        return {
                            quayCraneName,
                            workQueues: activeWorkQueues,
                            spreaderAction: spreaderAction,
                            consideredCompletedTruckNames:
                                moveInstructions.consideredCompletedTruckNames,
                        };
                    })
                    .flat(1);

                // QC's without quayCranes should not be added
                if (quayCranesPerVesselWithActiveWorkQueues.length <= 0) {
                    return;
                }

                vesselWithQuayCranes.push({
                    vesselName,
                    vesselVisitId,
                    quayCranes: quayCranesPerVesselWithActiveWorkQueues,
                });
            });
        });

    return vesselWithQuayCranes;
};
