import { Just, Maybe, Nothing } from 'purify-ts';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

export type ConnectionWarningStore = {
    getWarningCode: () => Maybe<number>;
    setWarningMetrics: {
        sseConnectionIsClosed: () => void;
        sseConnectionIsOpen: () => void;
        receivedMessage: () => void;
        sseMessageDecoderFailed: () => void;
    };
    sseConnectionClosedMs: Maybe<number>;
    lastMessageReceived: number;
    sseMessageDecoderError: boolean;
};

export const useConnectionWarningStore = create<ConnectionWarningStore>()(
    devtools(
        (set, get) => {
            return {
                sseConnectionClosedMs: Nothing,
                lastMessageReceived: new Date().getTime(),
                sseMessageDecoderError: false,
                getWarningCode: () => {
                    const now = new Date().getTime();
                    const { lastMessageReceived, sseMessageDecoderError, sseConnectionClosedMs } =
                        get();
                    let errorCode = 0;
                    if (now - lastMessageReceived > 60 * 1000) {
                        errorCode |= 1;
                    }
                    if (sseMessageDecoderError) {
                        errorCode |= 2;
                    }
                    if (
                        sseConnectionClosedMs
                            .map(ms => now - ms)
                            .map(diff => diff > 10 * 1000)
                            .orDefault(false)
                    ) {
                        errorCode |= 4;
                    }

                    return Just(errorCode).filter(e => e !== 0);
                },
                setWarningMetrics: {
                    sseConnectionIsClosed: () => {
                        set(state => {
                            return {
                                ...state,
                                sseConnectionClosedMs: Just(new Date().getTime()),
                            };
                        });
                    },
                    sseConnectionIsOpen: () => {
                        set(state => {
                            return {
                                ...state,
                                sseConnectionClosedMs: Nothing,
                            };
                        });
                    },
                    receivedMessage: () => {
                        set(state => {
                            return {
                                ...state,
                                lastMessageReceived: new Date().getTime(),
                            };
                        });
                    },
                    sseMessageDecoderFailed: () => {
                        set(state => {
                            return {
                                ...state,
                                sseMessageDecoderError: true,
                            };
                        });
                    },
                },
            };
        },
        { name: 'connectionWarningStore' },
    ),
);
