import { Codec, enumeration, exactly, GetType, string } from 'purify-ts';

import { AndonTypeOption, CheType } from '@/constants/enums';

export const andonEventPayloadDecoder = Codec.interface({
    cheName: string,
    cheType: enumeration(CheType),
    andonType: enumeration(AndonTypeOption),
});

export const andonEventDecoder = Codec.interface({
    event: exactly('ANDON_STATE'),
    data: andonEventPayloadDecoder,
});

export type AndonEvent = GetType<typeof andonEventPayloadDecoder>;
export type Andons = AndonEvent[];
