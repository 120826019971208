import { useQuery } from '@tanstack/react-query';
import { Layer, Source } from 'react-map-gl/maplibre';
import { useShallow } from 'zustand/shallow';

import { getFlowPositionsByQuayCrane } from '@/api';
import { reactQueryKeys } from '@/constants/queryKeys';
import { useControlPageStore } from '@/store';
import { useTerminalStore } from '@/store/terminalStore';

const FLOW_POSITIONS_POLL_INTERVAL = 60000; // 1 minute

type FlowPositionsLayerProps = {
    quayCraneName: string;
    editFlowPosition: boolean;
};

export const FlowPositionsLayer = ({
    quayCraneName,
    editFlowPosition,
}: FlowPositionsLayerProps) => {
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));
    const userFilteredEquipmentOnMapPerQuayCrane = useControlPageStore(
        useShallow(state => state.userFilteredEquipmentOnMapPerQuayCrane),
    );

    const {
        data: flowPositions,
        isError,
        error,
    } = useQuery({
        queryKey: [
            `${reactQueryKeys.FLOW_POSITIONS}-${quayCraneName.toLowerCase()}`,
            terminalId,
            quayCraneName,
        ],
        queryFn: () => getFlowPositionsByQuayCrane(terminalId, quayCraneName),
        enabled: !!terminalId && !!quayCraneName,
        refetchInterval: FLOW_POSITIONS_POLL_INTERVAL,
    });

    if (isError) {
        console.error('Error while fetching flow positions', error);
        return null;
    }

    if (!flowPositions) {
        return null;
    }

    // If the user has filtered the trucks on the map, we don't want to show the trucks for the quay crane
    if (userFilteredEquipmentOnMapPerQuayCrane.find(qc => qc.quayCraneId === quayCraneName)) {
        return null;
    }

    const flowPositionsForTrucks = {
        standby: flowPositions.standby,
        underQuayCrane: flowPositions.underQuayCrane,
    };
    const features: GeoJSON.Feature[] = Object.entries(flowPositionsForTrucks)
        .map(flowPosition => {
            const [flowPositionName, flowPositonData] = flowPosition;
            if (editFlowPosition && flowPositionName === 'standby') {
                return undefined;
            }

            const flowPositionCoordinates = flowPositonData?.map(({ longitude, latitude }) => {
                return [longitude, latitude];
            });

            return {
                type: 'Feature',
                properties: {
                    flowPositionName,
                    quayCraneName,
                },
                geometry: {
                    type: 'MultiPolygon',
                    coordinates: [[flowPositionCoordinates]],
                },
            } as GeoJSON.Feature;
        })
        .filter((g: GeoJSON.Feature | undefined): g is GeoJSON.Feature => g !== undefined);

    const geoJsonFlowPositions: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features,
    };

    const geoJsonPointOfNoReturn: GeoJSON.FeatureCollection = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    name: 'Point of no return',
                },
                geometry: {
                    type: 'MultiPolygon',
                    coordinates: [
                        [
                            flowPositions?.pointOfNoReturn?.map(({ longitude, latitude }) => [
                                longitude,
                                latitude,
                            ]) ?? [],
                        ],
                    ],
                },
            },
        ],
    };

    return (
        <>
            <Source type="geojson" data={geoJsonFlowPositions}>
                <Layer
                    id={`flow-positions-layer-${quayCraneName.toLowerCase()}`}
                    type="fill"
                    paint={{
                        'fill-color': '#0073AB',
                        'fill-opacity': 0.48,
                        'fill-outline-color': '#000',
                    }}
                />
            </Source>
            <Source type="geojson" data={geoJsonPointOfNoReturn}>
                <Layer
                    id={`point-of-no-return-layer-${quayCraneName.toLowerCase()}`}
                    type="line"
                    paint={{
                        'line-color': '#141414',
                        'line-opacity': 0.8,
                        'line-width': 0.5,
                        'line-dasharray': [2, 2],
                    }}
                />
            </Source>
        </>
    );
};
