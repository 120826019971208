import { PropsWithChildren } from 'react';

import { FlowStatusOption, FlowVisualisationVariant } from '@/constants/enums';
import { cn } from '@/utils/cn';

type ComponentProps = {
    flowStatus: FlowStatusOption;
    className?: string;
    variant?: FlowVisualisationVariant;
};

/**
 * This component should NOT be reused unless together with the FlowDetail component
 */
export const FlowDetailBackgroundLine = ({
    flowStatus,
    children,
    className,
    variant = FlowVisualisationVariant.LARGE,
}: PropsWithChildren<ComponentProps>) => {
    const isStopped = flowStatus === 'STOPPED';

    const flowDirectionArrow =
        'after:absolute after:right-12 after:top-0 after:-mt-[7px] after:h-[12px] after:w-[12px] after:rotate-45 after:border-t-[3px] after:border-r-[3px]';

    return (
        <div
            className={cn('relative h-auto w-full border-3', className, flowDirectionArrow, {
                'rounded-4xl': variant === FlowVisualisationVariant.LARGE,
                'rounded-xl after:hidden': variant === FlowVisualisationVariant.SMALL,
                'border-gray-400 after:border-gray-400': !isStopped,
                'border-feedback-danger after:border-feedback-danger': isStopped,
            })}
        >
            {children}
        </div>
    );
};
