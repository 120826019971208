import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiStopCircle(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M10 3a7 7 0 1 0 0 14 7 7 0 0 0 0-14zm-8 7a8 8 0 1 1 16 0 8 8 0 0 1-16 0zm4-3a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v6a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm7 0H7v6h6V7z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiStopCircle);
export default ForwardRef;
