import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowClockwiseClock(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M16 10a6 6 0 0 0-6-6V3a7 7 0 1 1-4.899 2H2V4h3.293A1 1 0 0 1 6 4.293L6.707 5A1 1 0 0 1 7 5.707V9H6V5.707l-.094-.094A6 6 0 1 0 16 10zm-6.5-.181V7h1v2.819l2.384 2.86-.768.641-2.384-2.86a1 1 0 0 1-.232-.641z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowClockwiseClock);
export default ForwardRef;
