import {
    FeatureFlagService,
    FeatureFlagV1,
    YardStackingEquipmentFlagV1,
} from '@maersk-global/digital-pull-operating-system-spec';
import { useQuery } from '@tanstack/react-query';
import { useShallow } from 'zustand/shallow';

import { useTerminalStore } from '@/store/terminalStore';

export type FlagType =
    | { cheType: 'QUAY_CRANE'; flag: FeatureFlagV1 }
    | { cheType: 'YSE'; flag: YardStackingEquipmentFlagV1 };

export const useFeatureFlag = (flagType: FlagType) => {
    const { terminalId } = useTerminalStore(
        useShallow(state => ({
            terminalId: state.terminalId,
        })),
    );
    return useQuery({
        queryKey: [flagType.cheType + flagType.flag],
        queryFn: () => {
            switch (flagType.cheType) {
                case 'QUAY_CRANE':
                    return FeatureFlagService.getScopesOfFeatureFlag({
                        terminalId,
                        flagName: FeatureFlagV1[flagType.flag],
                    });
                case 'YSE':
                    return FeatureFlagService.getYardStackingEquipmentFlags({
                        terminalId,
                        flagName: YardStackingEquipmentFlagV1[flagType.flag],
                    });
            }
        },
        enabled: !!terminalId,
    });
};
