import {
    Button,
    Popover,
    PopoverContent,
    PopoverTrigger,
} from '@maersk-global/apmt-react-components';
import { SvgDoorArrowRight, SvgUserCircle } from '@maersk-global/apmt-react-icons';

export interface ProfileButtonProps {
    username: string | undefined;
    userEmail: string | undefined;
    onLogout: () => void;
    className: string | undefined;
}

export const ProfileButton = ({ username, userEmail, onLogout, className }: ProfileButtonProps) => {
    return (
        <Popover>
            <PopoverTrigger className={`p-5 hover:bg-neutral-400 ${className ?? ''}`}>
                <SvgUserCircle width={24} height={24} data-testid="profile" />
            </PopoverTrigger>
            <PopoverContent className="relative z-50 px-0 py-2">
                {username !== undefined || userEmail !== undefined ? (
                    <div className="border-b ">
                        {username !== undefined ? <p className="px-4 ">{username}</p> : null}
                        {userEmail !== undefined ? (
                            <p className="mb-2 mt-0 px-4 text-xs ">{userEmail}</p>
                        ) : null}
                    </div>
                ) : null}
                <Button
                    icon={<SvgDoorArrowRight />}
                    data-testid="logout"
                    variant="plain"
                    className="mt-2 w-full rounded-none"
                    fit="medium"
                    onClick={onLogout}
                >
                    {'Logout'}
                </Button>
            </PopoverContent>
        </Popover>
    );
};
