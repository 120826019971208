import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Anchor } from '@/components/atoms/Anchor/Anchor';
import { Title } from '@/components/atoms/Title/Title';
import { NoQuayCraneWorkIcon } from '@/icons/components/NoQuayCraneWorkIcon';
import { routes } from '@/routes/routes';

export const NoWorkQueue = ({
    terminalId,
    quayCraneName,
}: {
    terminalId: string;
    quayCraneName: string;
}) => {
    const { t } = useTranslation();
    const { locale } = useParams();

    return (
        <div className="mt-40 flex flex-col items-center">
            <Title className="mds-font--display-3 mb-12">
                {t('pages.flows.noWorkQueueForQuayCrane', { name: quayCraneName })}
            </Title>
            <NoQuayCraneWorkIcon />
            <Anchor href={routes.flowOverview(terminalId, locale)} iconPosition="after">
                {t('pages.flows.seeAllFlows')}
            </Anchor>
        </div>
    );
};
