import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowAntiClockwise(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M4 5.293V2h1v3.101A7 7 0 1 1 3 10h1a6 6 0 1 0 1.613-4.094L5.707 6H9v1H5.707A1 1 0 0 1 5 6.707L4.293 6A1 1 0 0 1 4 5.293z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowAntiClockwise);
export default ForwardRef;
