import * as SwitchPrimitive from '@radix-ui/react-switch';
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

const rootFitClass = {
    small: 'h-4 w-7',
    medium: 'h-5 w-9',
} as const;

const thumbFitClass = {
    small: 'h-3 w-3 data-[state=checked]:translate-x-[13px]',
    medium: 'h-4 w-4 data-[state=checked]:translate-x-[17px]',
} as const;

const labelFitClass = {
    small: 'text-sm',
    medium: 'text-base',
} as const;

type SwitchFit = keyof typeof rootFitClass;

interface SwitchProps
    extends Omit<ComponentPropsWithoutRef<typeof SwitchPrimitive.Root>, 'asChild'> {
    label: string;
    fit?: SwitchFit;
}

export const Switch = forwardRef<ElementRef<typeof SwitchPrimitive.Root>, SwitchProps>(
    ({ label, fit = 'medium', ...otherProps }, ref) => {
        return (
            <div className="flex items-center gap-2">
                <label
                    htmlFor={label.toLowerCase().split(' ').join('-')}
                    className={labelFitClass[fit]}
                >
                    {label}
                </label>
                <SwitchPrimitive.Root
                    id={label.toLowerCase().split(' ').join('-')}
                    ref={ref}
                    {...otherProps}
                    className={clsx(
                        'relative rounded-full border',
                        'focus:border-blue-400 focus:shadow-input focus:outline-none',
                        rootFitClass[fit],
                        otherProps.disabled
                            ? 'border-gray-300 bg-gray-100'
                            : 'border-gray-400 bg-gray-400 data-[state=checked]:border-blue-400 data-[state=checked]:bg-blue-400',
                    )}
                >
                    <SwitchPrimitive.Thumb
                        className={clsx(
                            'top-4 block translate-x-[1px] rounded-full transition-transform duration-100 will-change-transform',
                            thumbFitClass[fit],
                            otherProps.disabled ? 'bg-gray-300' : 'bg-white',
                        )}
                    />
                </SwitchPrimitive.Root>
            </div>
        );
    },
);
