import { Notification } from '@maersk-global/apmt-react-components';
import { ImageSourceSpecification } from 'maplibre-gl';
import { Maybe } from 'purify-ts';
import { Layer, Source } from 'react-map-gl/maplibre';

import { RectangleCoordinates } from '@/api/getTerminalSettings';
import { CACHE_BUSTER_VERSION, MAP_OVERLAY_PATH } from '@/constants/settings';
import { useTerminalSettings } from '@/hooks/terminalInfo/TerminalInfoProvider';

export const TerminalMapLayer = ({
    terminalImageCoordinates,
}: {
    terminalImageCoordinates: RectangleCoordinates;
}) => {
    const { terminalId } = useTerminalSettings();
    const imageOverlayUrl = getImageOverlay(terminalId);
    // Using as because the Maplibre type requires it to be a tuple
    const coordinates: ImageSourceSpecification['coordinates'] = [
        [terminalImageCoordinates.topLeft.longitude, terminalImageCoordinates.topLeft.latitude],
        [terminalImageCoordinates.topRight.longitude, terminalImageCoordinates.topRight.latitude],
        [
            terminalImageCoordinates.bottomRight.longitude,
            terminalImageCoordinates.bottomRight.latitude,
        ],
        [
            terminalImageCoordinates.bottomLeft.longitude,
            terminalImageCoordinates.bottomLeft.latitude,
        ],
    ];
    if (imageOverlayUrl === 'unknown') {
        return (
            <Notification
                appearance="warning"
                body={`Image overlay could not be found for terminalId: ${terminalId}`}
            />
        );
    }

    return (
        <Source
            id="terminal-background-image"
            type="image"
            url={`${MAP_OVERLAY_PATH}/${imageOverlayUrl}`}
            coordinates={coordinates}
        >
            <Layer
                id="terminal-background-layer"
                type="raster"
                paint={{
                    'raster-opacity': 1,
                }}
            />
        </Source>
    );
};

const isHighDensity = () => window.devicePixelRatio && window.devicePixelRatio > 1.3;
const isFirefox = () => navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

type ImageSources = { normal: string; hdpi: string };

const imageOverlaysPerTerminal: { [key: string]: ImageSources } = {
    UNKNOWN: {
        normal: 'unknown',
        hdpi: 'unknown',
    },
    BHKBS: {
        normal: `overlay-bhkbs@1x.webp?${CACHE_BUSTER_VERSION}`,
        hdpi: `overlay-bhkbs@2x.webp?${CACHE_BUSTER_VERSION}`,
    },
    JOAQB: {
        normal: `overlay-joaqb@1x.webp?${CACHE_BUSTER_VERSION}`,
        hdpi: `overlay-joaqb@2x.webp?${CACHE_BUSTER_VERSION}`,
    },
    MAPTM: {
        normal: `overlay-maptm@1x.webp?${CACHE_BUSTER_VERSION}`,
        hdpi: `overlay-maptm@2x.webp?${CACHE_BUSTER_VERSION}`,
    },
    OMSLL: {
        normal: `overlay-omsll@1x.webp?${CACHE_BUSTER_VERSION}`,
        hdpi: `overlay-omsll@2x.webp?${CACHE_BUSTER_VERSION}`,
    },
    EGPSD: {
        normal: `overlay-egpsd@1x.webp?${CACHE_BUSTER_VERSION}`,
        hdpi: `overlay-egpsd@2x.webp?${CACHE_BUSTER_VERSION}`,
    },
    INBOM: {
        normal: `overlay-inbom@1x.webp?${CACHE_BUSTER_VERSION}`,
        hdpi: `overlay-inbom@2x.webp?${CACHE_BUSTER_VERSION}`,
    },
    INPPV: {
        normal: `overlay-inppv@1x.webp?${CACHE_BUSTER_VERSION}`,
        hdpi: `overlay-inppv@2x.webp?${CACHE_BUSTER_VERSION}`,
    },
};

// We check the density of the screen so that we can use a hdpi image
// but for Firefox these images do not seem to render correctly so we load the 1x image for Firefox
const getImageOverlay = (terminalId: string): string => {
    const isHighDensityScreenAndNotFireFox = isHighDensity() && !isFirefox();

    return Maybe.fromNullable(
        isHighDensityScreenAndNotFireFox
            ? imageOverlaysPerTerminal[terminalId]?.hdpi
            : imageOverlaysPerTerminal[terminalId]?.normal,
    ).orDefault(imageOverlaysPerTerminal.UNKNOWN?.normal as string);
};
