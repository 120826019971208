import { ContainerHandlingEquipmentPosition } from '@maersk-global/digital-pull-operating-system-spec';
import { array, Codec, enumeration, GetType, intersect, string } from 'purify-ts';

import { coordinateDecoder } from '@/types/coordinates';
import { positionUpdateMetaDecoder } from '@/types/position';

export const CheIdDecoder = Codec.interface({
    containerHandlingEquipmentId: string,
    containerHandlingEquipmentType: enumeration(
        ContainerHandlingEquipmentPosition.containerHandlingEquipmentType,
    ),
});
export type CheId = GetType<typeof CheIdDecoder>;

export const chePositionDecoder = intersect(
    CheIdDecoder,
    intersect(coordinateDecoder, positionUpdateMetaDecoder),
);
export type ChePosition = GetType<typeof chePositionDecoder>;

export const chePositionsDecoder = array(chePositionDecoder);
