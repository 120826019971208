import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
    size?: number;
}
const QuayCraneIconIcon = forwardRef<SVGSVGElement, CustomIconProps>(
    ({ size, ...props }, svgRef) => {
        if (size) {
            props.width = size;
            props.height = size;
        }

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 269.5 141.5"
                ref={svgRef}
                {...props}
            >
                <path fill="currentColor" d="M53.778 103.627L70.1 94.531l.487.874-16.322 9.096z" />
                <path fill="currentColor" fillRule="evenodd" d="M54.021 107.968l13.6-7.526" />
                <path
                    fill="currentColor"
                    d="M53.779 107.53l13.6-7.525.485.875-13.601 7.525zm33.348-12.124l.487-.874 16.322 9.095-.486.873z"
                />
                <path
                    fill="currentColor"
                    fillRule="evenodd"
                    d="M103.694 107.968l-13.601-7.526"
                    data-name="Rectangle-11-Copy"
                />
                <path fill="currentColor" d="M89.851 100.88l.484-.876 13.6 7.527-.484.875z" />
                <path
                    fill="currentColor"
                    d="M59.083 129.328l-9.131-37.639L79.171 73.98l28.815 16.846-9.135 37.545-1.944-.473 8.784-36.097-26.502-15.493L52.24 92.64l8.787 36.217-1.944.471z"
                />
                <path fill="currentColor" d="M77.969 86.91h2v42.424h-2z" />
                <path
                    fill="currentColor"
                    d="M100.872 87.739h-2V69.761H59.065v17.872h-2V67.761h43.807v19.978z"
                />
                <path
                    fill="currentColor"
                    d="M65.145 72.091h1v10.893h-1zm6.701 0h1v6.745h-1zm18.936 0h1v10.893h-1zm-6.702 0h1v6.745h-1zm6.086-2.719l-4.863-15.887H72.634l-4.863 15.887-1.912-.585 5.296-17.302h15.628l5.296 17.302-1.913.585z"
                />
                <path
                    fill="currentColor"
                    d="M61.852 57.868h34.233v2H61.852zM78.469 46.56h1v5.787h-1zm43.409 80.233h109.491v2H121.878zm-85.738 4.5h19v1h-19zm62.378 0h19v1h-19zM61.91 138.62h31v1h-31zM80.921 36.403l-.815-1.826 73.589-32.858 40.263 32.997-1.267 1.547-39.308-32.214-72.462 32.354z"
                />
                <path
                    fill="currentColor"
                    d="M124.349 36.196l-.744-.668 29.803-33.247 62.1 27.337-.403.915-61.436-27.045-29.32 32.708z"
                />
                <path fill="currentColor" d="M230.539 40.884h-22v-12h22zm-20-2h18v-8h-18z" />
                <path fill="currentColor" d="M69.781 34.884h140.47v2H69.781z" />
                <path
                    fill="currentColor"
                    d="M178.62 128.508h-26.553V34.932h26.553zm-24.553-2h22.553V36.932h-22.553z"
                />
                <path
                    fill="currentColor"
                    d="M177.03 127.783l-24.33-19.701 23.881-18.259-23.683-18.518 23.657-17.055-24.188-18.158.6-.8 25.275 18.974-23.679 17.072 23.653 18.494-23.899 18.272 23.342 18.902-.629.777z"
                />
                <path
                    fill="currentColor"
                    d="M152.788 54.137h24.668v1h-24.668zm0 17.032h24.668v1h-24.668zm0 18.249h24.668v1h-24.668zm0 18.47h24.668v1h-24.668z"
                />
            </svg>
        );
    },
);
export default QuayCraneIconIcon;
