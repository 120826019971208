import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiLock(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M9 2h2a1 1 0 0 1 1 1v2H8V3a1 1 0 0 1 1-1zm4 3V3a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v2H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V6a1 1 0 0 0-1-1h-3zM4 6h12v10H4V6zm6 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 2.5 1.937V14h-1v-2.063A2 2 0 0 1 8 10z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiLock);
export default ForwardRef;
