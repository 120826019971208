import * as turf from '@turf/turf';
import { LngLatBounds } from 'maplibre-gl';

import { Coordinate } from '@/types';

export const createLngLatBoundsFrom = (coordinates: Coordinate[]): LngLatBounds => {
    const mapLngLatBounds = new LngLatBounds();
    coordinates.forEach(coord =>
        mapLngLatBounds.extend({ lat: coord.latitude, lng: coord.longitude }),
    );
    return mapLngLatBounds;
};

export const rotatePolygon = (coordinates: Coordinate[], bearing: number): Coordinate[] => {
    const closedPolygon = [...coordinates, coordinates[0]!];

    const turfPolygon = turf.polygon([closedPolygon.map(c => [c.longitude, c.latitude])]);
    const rotatedPolygon = turf.transformRotate(turfPolygon, bearing);
    if (rotatedPolygon.geometry.coordinates.length != 1) {
        throw new Error(`Rotated polygon does not have expected one coordinate array`);
    }
    return rotatedPolygon.geometry.coordinates[0]!.map(([lng, lat]) => ({
        longitude: lng!,
        latitude: lat!,
    }));
};
