/* istanbul ignore file */
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
    size?: number;
}
const DposLogoIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
    if (size) {
        props.width = size;
        props.height = size;
    }

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={75}
            height={32}
            fill="none"
            viewBox="0 0 75 32"
            ref={svgRef}
            {...props}
        >
            <path
                d="M63.4931 25.3332C67.9997 25.3332 69.6264 23.5702 69.6264 20.0184C69.6264 16.5443 68.3731 15.5591 65.5731 14.885L63.2531 14.3147C61.3864 13.848 60.6397 13.3295 60.6397 11.3332C60.6397 9.28502 61.5997 8.74058 64.1064 8.74058C65.7864 8.74058 67.5731 9.02576 68.5864 9.23317V7.1591C67.6531 6.95169 65.8131 6.6665 64.0797 6.6665C59.7064 6.6665 58.2397 8.22206 58.2397 11.3591C58.2397 14.6517 59.3597 15.6628 62.2664 16.3628L64.8797 17.0109C66.5864 17.3998 67.1997 18.0221 67.1997 20.0961C67.1997 22.4813 66.1864 23.2591 63.4131 23.2591C61.4131 23.2591 59.4931 22.8702 58.3731 22.585V24.711C59.3597 24.9702 61.1997 25.3332 63.4931 25.3332Z"
                fill="#353535"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M50.4746 7.5498L50.8147 7.72708C53.7986 9.28227 55.8397 12.4061 55.8397 16.0085C55.8397 20.9076 52.065 24.9251 47.2654 25.3114L49.4434 27.7617L47.9145 29.1208L45.2641 26.139C44.9936 25.8348 44.8442 25.4419 44.8442 25.0348V23.5536C44.8442 23.2255 44.9414 22.9047 45.1234 22.6316L47.1466 19.5968L48.8487 20.7316L47.1581 23.2674C50.8776 22.9377 53.794 19.8136 53.794 16.0085C53.794 13.1982 52.2037 10.7578 49.8692 9.54114L49.5291 9.36386L50.4746 7.5498Z"
                fill="#353535"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M45.098 2.896L47.7485 5.87773C48.0189 6.18198 48.1683 6.5749 48.1683 6.98197V8.46317C48.1683 8.79131 48.0712 9.11211 47.8891 9.38514L45.8659 12.4199L44.1638 11.2852L45.8544 8.74941C42.1349 9.07909 39.2185 12.2031 39.2185 16.0083C39.2185 18.8186 40.8089 21.2589 43.1433 22.4756L43.4834 22.6529L42.538 24.467L42.1978 24.2897C39.2139 22.7345 37.1729 19.6107 37.1729 16.0083C37.1729 11.1092 40.9475 7.09169 45.7472 6.70541L43.5691 4.25506L45.098 2.896Z"
                fill="#FA6A55"
            />
            <path
                d="M28.5063 6.67529H22.6396V16.0086V25.342H25.0396V18.6486H28.5063C32.2396 18.6486 34.773 18.0086 34.773 12.6753C34.773 7.28863 32.2396 6.67529 28.5063 6.67529ZM25.0396 16.542V8.78196H28.1863C30.8796 8.78196 32.3196 8.88863 32.3196 12.6753C32.3196 16.382 30.9063 16.542 28.1596 16.542H25.0396Z"
                fill="#FA6A55"
            />
            <path
                d="M11.2532 6.67529H5.70654V25.342H11.1732C15.8932 25.342 18.9065 24.5153 18.9065 16.0086C18.9065 7.50196 16.0532 6.67529 11.2532 6.67529ZM8.10654 23.2353V8.78196H11.2532C14.2665 8.78196 16.4532 8.91529 16.4532 16.0086C16.4532 23.0753 14.3199 23.2353 11.2532 23.2353H8.10654Z"
                fill="#FA6A55"
            />
        </svg>
    );
});
export default DposLogoIcon;
