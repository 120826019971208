export const NoQuayCraneWorkIcon = () => {
    return (
        <svg
            width="640"
            height="332"
            viewBox="0 0 640 332"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M304.593 284.444H297.482V289.185L257.185 289.185V293.926H574.815V289.185L439.704 289.185V284.444H432.592V118.518L437.334 118.518H489.482L515.556 118.518V113.778H503.716L489.482 107.46V90.074H450.152L311.861 29.2796L307.2 25.8369L83.8023 113.778H65.1855V118.518H304.593V284.444ZM444.239 90.074L320.665 35.7824L415.03 105.481H427.852V94.8147H432.592V113.778H437.334V90.074H444.239ZM442.074 113.778H484.741V94.8147H442.074V113.778ZM489.482 113.778H497.874L489.482 110.052V113.778ZM427.852 107.852H418.239L426.262 113.778H427.852V107.852ZM407.115 105.481L309.334 32.9849V105.481H407.115ZM309.334 107.852H410.312L418.305 113.778H309.334V107.852ZM420.741 289.185V284.444H427.852V225.185H309.334V284.444H316.445V289.185H420.741ZM309.334 219.218L425.666 118.518H309.334V219.218ZM311.538 220.444H427.852V119.761L311.538 220.444ZM304.593 113.778V32.6224L227.812 113.778H304.593ZM300.644 33.4454L224.585 113.778H96.5393L300.644 33.4454ZM125.63 299.852H80.5928V302.222H125.63V299.852ZM210.963 314.074H137.482V316.444H210.963V314.074Z"
                fill="#FA6A55"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M119.886 127.926H146V167.926H85V165.674L119.628 139.262L118.189 137.377L85 162.694V127.926H116.017L93.1388 145.659L94.5918 147.534L119.886 127.926ZM81 123.926H85H146H150V127.926V167.926V171.926H146H85H81V167.926V127.926V123.926Z"
                fill="#B2B2B2"
            >
                <animate
                    attributeType="XML"
                    attributeName="opacity"
                    values="0;0;1;1;0;"
                    dur="1.5s"
                    repeatCount="indefinite"
                />
            </path>
        </svg>
    );
};
