import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiTimes(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M4.646 5.354 9.293 10l-4.647 4.646.708.708L10 10.707l4.646 4.647.708-.707L10.707 10l4.647-4.646-.707-.708L10 9.293 5.354 4.646l-.708.708z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiTimes);
export default ForwardRef;
