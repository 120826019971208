import { Codec, oneOf } from 'purify-ts';

import { nullToUndefined } from './nullToUndefined';

export const optionalNullToUndefined = <T>(codec: Codec<T>): Codec<T | undefined> => {
    return {
        ...oneOf([codec, nullToUndefined]),
        schema: codec.schema,
        _isOptional: true,
    } as never;
};
