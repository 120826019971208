import { Button } from '@maersk-global/apmt-react-components';

import { FloatingBlocksContainer } from '@/components/atoms/FloatingBlocksContainer/FloatingBlocksContainer';
import { Notification } from '@/components/atoms/Notification/Notification';
import { cn } from '@/utils/cn';

export const FloatingCraneWarnings = ({
    warnings: warnings,
    cranesWithTosOnlyTriggers,
    toggleTosOnlyTriggers,
}: {
    warnings: string[];
    cranesWithTosOnlyTriggers: string[];
    toggleTosOnlyTriggers: (checked: boolean, quayCraneName: string) => void;
}) => {
    return (
        <>
            {warnings.length > 0 && (
                <FloatingBlocksContainer testid="crane-warnings">
                    {warnings
                        .filter(quayCraneName => !cranesWithTosOnlyTriggers.includes(quayCraneName))
                        .map(quayCraneName => {
                            return (
                                <span key={quayCraneName} className="flex flex-col gap-2">
                                    <Notification
                                        fit="small"
                                        className="h-10 bg-feedback-warning-light"
                                        body={
                                            <div className="flex flex-row items-center">
                                                It looks like {quayCraneName} is not sending
                                                triggers
                                                <Button
                                                    className={cn(' underline', {
                                                        'text-feedback-warning-text': true,
                                                    })}
                                                    variant="link"
                                                    onClick={async () => {
                                                        void toggleTosOnlyTriggers(
                                                            true,
                                                            quayCraneName,
                                                        );
                                                    }}
                                                >
                                                    Switch to TOS-Triggers
                                                </Button>
                                            </div>
                                        }
                                    />
                                </span>
                            );
                        })}
                </FloatingBlocksContainer>
            )}
        </>
    );
};
