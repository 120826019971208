import { UniqueIdentifier } from '@dnd-kit/core';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { QuayCrane, QuayCraneWithShape } from '@/types';
import { MapViewState } from '@/types/map';

export type ColumnKeys = 'root' | 'container1' | 'container2' | 'container3';
export type SortState = { [key in ColumnKeys]: { kind: 'qc' | 'rtg'; id: UniqueIdentifier }[] };
type ControlPageStore = {
    userFilteredEquipmentOnMapPerQuayCrane: QuayCrane[];
    setUserFilteredEquipmentOnMapPerQuayCrane: (quayCranes: QuayCrane[]) => void;
    userSelectedQuayCranes: QuayCraneWithShape[];
    setUserSelectedQuayCranes: (quayCranes: QuayCraneWithShape[]) => void;
    visibleQuayCranes: SortState;
    setVisibleQuayCranes: (quayCranes: SortState) => void;
    flyToTruck: boolean;
    setFlyToTruck: (flyToTruck: boolean) => void;
    mapViewState: MapViewState | undefined;
    setMapViewState: (mapViewState: MapViewState) => void;
};

export const useControlPageStore = create<ControlPageStore>()(
    devtools(
        set => ({
            userFilteredEquipmentOnMapPerQuayCrane: [],
            setUserFilteredEquipmentOnMapPerQuayCrane: quayCranes => {
                set(previousState => {
                    return {
                        ...previousState,
                        userFilteredEquipmentOnMapPerQuayCrane: quayCranes,
                    };
                });
            },
            userSelectedQuayCranes: [],
            setUserSelectedQuayCranes: quayCranes => {
                set(previousState => {
                    return {
                        ...previousState,
                        userSelectedQuayCranes: quayCranes,
                    };
                });
            },
            visibleQuayCranes: {
                root: [],
                container1: [],
                container2: [],
                container3: [],
            },
            setVisibleQuayCranes: quayCranes => {
                set(previousState => {
                    return {
                        ...previousState,
                        visibleQuayCranes: quayCranes,
                    };
                });
            },
            flyToTruck: false,
            setFlyToTruck: flyToTruck => {
                set(previousState => {
                    return {
                        ...previousState,
                        flyToTruck: flyToTruck,
                    };
                });
            },
            mapViewState: undefined,
            setMapViewState: mapViewState => {
                set(previousState => {
                    return {
                        ...previousState,
                        mapViewState: mapViewState,
                    };
                });
            },
        }),
        { name: 'controlPageStore' },
    ),
);
