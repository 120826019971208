import { Codec, exactly } from 'purify-ts';

import { terminalTruckPositionsDecoder } from '@/types';
import { chePositionsDecoder } from '@/types/che';

export const terminalTruckPositionsChangedDecoder = Codec.interface({
    event: exactly('TERMINAL_TRUCK_POSITIONS_CHANGED'),
    data: Codec.interface({
        positions: terminalTruckPositionsDecoder,
    }),
});

export const chePositionsChangedDecoder = Codec.interface({
    event: exactly('CONTAINER_HANDLING_EQUIPMENT_POSITIONS_CHANGED'),
    data: Codec.interface({
        positions: chePositionsDecoder,
    }),
});
