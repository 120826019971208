import { useState, useEffect } from 'react';

function Clock() {
    const [date, setDate] = useState(new Date());

    function updateClock() {
        setDate(new Date());
    }

    useEffect(() => {
        const timerId = setInterval(updateClock, 1000);

        return function cleanup() {
            clearInterval(timerId);
        };
    }, []);

    return (
        <span className="inline-flex min-w-[100px] gap-2 text-left font-sansNumeric text-sm">
            <span>
                {date.toLocaleDateString([], {
                    year: 'numeric',
                    month: '2-digit',
                    day: 'numeric',
                })}
            </span>
            <span>
                {date.toLocaleTimeString([], {
                    timeZone: 'UTC',
                    hour12: false,
                    timeZoneName: 'short',
                })}
            </span>
        </span>
    );
}

export default Clock;
