import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { QuayCranes } from '@/types';

type TerminalStore = {
    terminalId: string;
    setTerminalId: (terminalId: string) => void;
    quayCraneName: string;
    setquayCraneName: (quayCraneName: string) => void;
    pageTitle: string;
    setPageTitle: (pageTitle: string) => void;
    allQuayCranes: QuayCranes | undefined;
    setAllQuayCranes: (allQuayCranes: QuayCranes) => void;
};

export const useTerminalStore = create<TerminalStore>()(
    devtools(
        set => ({
            terminalId: '',
            setTerminalId: (terminalId: string) => set(state => ({ ...state, terminalId })),
            quayCraneName: '',
            setquayCraneName: (quayCraneName: string) =>
                set(state => ({ ...state, quayCraneName })),
            pageTitle: '',
            setPageTitle: (pageTitle: string) => set(state => ({ ...state, pageTitle })),
            allQuayCranes: undefined,
            setAllQuayCranes: (allQuayCranes: QuayCranes) =>
                set(state => ({ ...state, allQuayCranes })),
        }),
        { name: 'terminalStore' },
    ),
);
