import { Table, Tooltip, TooltipProvider } from '@maersk-global/apmt-react-components';
import { FlowPositionEnum } from '@maersk-global/digital-pull-operating-system-spec';
import { createColumnHelper } from '@tanstack/react-table';
import { PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TableHeader } from '@/components/organisms/TruckInstructionsTable/TableHeader';
import { YardWorkInstructions, WorkType, AssignedPosition } from '@/types';
import { cn } from '@/utils/cn';

export type JobListTableCellData = {
    jobNumber: string;
    title: string;
    from: string;
    to: string;
    flowPosition: string;
    container: string;
};

const TableCell = ({ children, className }: PropsWithChildren & { className?: string }) => {
    return <span className={cn('font-sansNumeric whitespace-nowrap', className)}>{children}</span>;
};
export const JobListTable = ({ jobList }: { jobList: YardWorkInstructions['jobs'] }) => {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<JobListTableCellData>();
    const defaultColumns = useMemo(
        () => [
            columnHelper.accessor('jobNumber', {
                header: () => <TableHeader title={t('pages.yard.jobTitleDrawer.numberHeader')} />,
                cell: data => <TableCell>{data.getValue()}</TableCell>,
                size: 42,
            }),
            columnHelper.accessor('title', {
                header: () => <TableHeader title={t('pages.yard.jobTitleDrawer.jobTitleHeader')} />,
                cell: data => <TableCell>{data.getValue()}</TableCell>,
                size: 100,
            }),
            columnHelper.accessor('from', {
                header: () => <TableHeader title={t('pages.yard.jobTitleDrawer.fromHeader')} />,
                cell: data => {
                    const val = data.getValue();
                    return (
                        <TableCell className="relative truncate">
                            <Tooltip
                                align="center"
                                className="z-50 max-w-[300px]"
                                content={val}
                                side="bottom"
                            >
                                <span>{val}</span>
                            </Tooltip>
                        </TableCell>
                    );
                },
                size: 82,
            }),
            columnHelper.accessor('to', {
                header: () => <TableHeader title={t('pages.yard.jobTitleDrawer.toHeader')} />,
                cell: data => {
                    const val = data.getValue();
                    return (
                        <TableCell className="relative truncate">
                            <Tooltip
                                align="center"
                                className="z-50 max-w-[300px]"
                                content={val}
                                side="bottom"
                            >
                                <span>{val}</span>
                            </Tooltip>
                        </TableCell>
                    );
                },
                size: 82,
            }),
            columnHelper.accessor('flowPosition', {
                header: () => (
                    <TableHeader title={t('pages.yard.jobTitleDrawer.flowPositionHeader')} />
                ),
                cell: data => <TableCell>{data.getValue()}</TableCell>,
                size: 100,
            }),
            columnHelper.accessor('container', {
                header: () => (
                    <TableHeader title={t('pages.yard.jobTitleDrawer.containerHeader')} />
                ),
                cell: data => {
                    const val = data.getValue();
                    return (
                        <TableCell className="relative truncate">
                            <Tooltip
                                align="center"
                                className="z-50 max-w-[300px]"
                                content={val}
                                side="bottom"
                            >
                                <span>{val}</span>
                            </Tooltip>
                        </TableCell>
                    );
                },
                size: 165,
            }),
        ],
        [],
    );

    const getJobTitle = (job: YardWorkInstructions['jobs'][number]) => {
        switch (job.type) {
            case 'RTGHouseKeepingJob':
                return t('pages.yard.jobTitleDrawer.jobTitle.rtgHouseKeeping');
            case 'ExternalJob':
                if (job.work === WorkType.FETCH) {
                    return t('pages.yard.jobTitleDrawer.jobTitle.external.fetch');
                } else {
                    return t('pages.yard.jobTitleDrawer.jobTitle.external.put');
                }
            case 'InternalJob':
                if (job.work === WorkType.FETCH) {
                    return t('pages.yard.jobTitleDrawer.jobTitle.internal.fetch', {
                        quayCraneName: job.serving,
                    });
                } else {
                    return t('pages.yard.jobTitleDrawer.jobTitle.internal.put', {
                        quayCraneName: job.serving,
                    });
                }
            case 'TruckHouseKeepingJob':
                return t('pages.yard.jobTitleDrawer.jobTitle.truckHouseKeeping');
        }
    };

    const getFlowPositionLabel = (job: YardWorkInstructions['jobs'][number]): string => {
        if (job.type === 'ExternalJob') {
            return job.assignedPosition === AssignedPosition.YARD_BLOCK
                ? t('pages.yard.jobTitleDrawer.flowPosition.yardBlock')
                : t('pages.yard.jobTitleDrawer.flowPosition.staging');
        }

        switch (job.flowPosition) {
            case FlowPositionEnum.UNDER_CRANE:
                return t('pages.yard.jobTitleDrawer.flowPosition.underCrane');
            case FlowPositionEnum.STANDBY:
                return t('pages.yard.jobTitleDrawer.flowPosition.standby');
            case FlowPositionEnum.PULL:
                return t('pages.yard.jobTitleDrawer.flowPosition.pull');
        }
    };
    const sortedJobList = [...jobList].sort((a, b) => {
        return (a.index ?? Number.MAX_SAFE_INTEGER) - (b.index ?? Number.MAX_SAFE_INTEGER);
    });
    const jobListData = sortedJobList.map(job => {
        return {
            jobNumber: job.index?.toString() ?? '-',
            title: getJobTitle(job),
            from: getFromTitle(job),
            to: getToTitle(job),
            flowPosition: getFlowPositionLabel(job),
            container: job.container,
        };
    });
    return (
        <TooltipProvider>
            <Table<JobListTableCellData>
                tableLayout="fixed"
                data={jobListData}
                cellClassName="truncate"
                columns={defaultColumns}
            />
        </TooltipProvider>
    );
};

export const getFromTitle = (job: YardWorkInstructions['jobs'][number]): string => {
    const yardPositionStrippedName = job.fromPosition.replace(/.*-.*-/, '');
    switch (job.type) {
        case 'ExternalJob':
            switch (job.work) {
                case WorkType.FETCH:
                    return yardPositionStrippedName;
                case WorkType.PUT:
                    return stripTruckName(job.fromPosition);
            }
        // eslint-disable-next-line no-fallthrough
        case 'RTGHouseKeepingJob':
        case 'InternalJob':
        case 'TruckHouseKeepingJob':
            return yardPositionStrippedName;
    }
};
const stripTruckName = (truckName: string): string => {
    return truckName.replace(/\(.*\)/g, '').replace(/-TIP-Y$/, '');
};

export const getToTitle = (job: YardWorkInstructions['jobs'][number]) => {
    const yardPositionStrippedName = job.toPosition.replace(/.*-.*-/, '');
    switch (job.type) {
        case 'ExternalJob':
            switch (job.work) {
                case WorkType.FETCH:
                    return stripTruckName(job.toPosition);
                case WorkType.PUT:
                    return yardPositionStrippedName;
            }
        // eslint-disable-next-line no-fallthrough
        case 'RTGHouseKeepingJob':
        case 'InternalJob':
        case 'TruckHouseKeepingJob':
            return yardPositionStrippedName;
    }
};
