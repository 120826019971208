import { Maybe } from 'purify-ts';

import { defaultLocale } from '@/constants/locales';

export const humanReadableTime = (timestamp: string, locale = defaultLocale) => {
    return Intl.DateTimeFormat(locale, {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    }).format(new Date(timestamp));
};

const dateFormatter = Intl.DateTimeFormat('en-GB', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour12: false,
    fractionalSecondDigits: 3,
});

export const readableDate = (date: Date | null) => {
    return Maybe.fromNullable(date)
        .map(d => new Date(d))
        .map(dateFormatter.format)
        .orDefault('-');
};
