import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiVesselFront(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M8 3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h2a1 1 0 0 1 1 1v3.909l1.58.45a1 1 0 0 1 .635 1.376l-1.86 4.093A2 2 0 0 1 13.535 18h-7.07a2 2 0 0 1-1.82-1.172l-1.86-4.093a1 1 0 0 1 .636-1.375L5 10.909V7a1 1 0 0 1 1-1h2V3zm1 3h2V3H9v3zm-3 4.623 3.725-1.065a1 1 0 0 1 .55 0L14 10.623V7H6v3.623zm4-.103-6.305 1.801 1.86 4.093a1 1 0 0 0 .91.586h7.07a1 1 0 0 0 .91-.586l1.86-4.093L10 10.52z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiVesselFront);
export default ForwardRef;
