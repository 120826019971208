import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownButton,
    DropdownMenuContent,
    DropdownMenuItem,
} from '@maersk-global/apmt-react-components';
import { SvgCheck } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { FlowDetailMapOptions } from '@/constants/enums';
import { useAllQuayCranes } from '@/hooks/useAllQuayCranes';
import { routes } from '@/routes/routes';
import { useTerminalStore, useQuayCraneStore } from '@/store';
import { cn } from '@/utils/cn';
import { naturalSort } from '@/utils/naturalSort';

export const QuayCraneDropdown = ({ terminalId }: { terminalId: string }) => {
    const quayCraneName = useTerminalStore(useShallow(state => state.quayCraneName));
    const workQueuesPerQuayCrane = useQuayCraneStore(
        useShallow(state => state.workQueuesPerQuayCrane),
    );
    const quayCranesWithActiveWorkQueue = Object.entries(workQueuesPerQuayCrane)
        .filter(([, quayCraneInfo]) => {
            return quayCraneInfo.workQueues.length > 0;
        })
        .map(([quayCraneName]) => quayCraneName);

    const navigate = useNavigate();

    const { error: errorAllQuayCranes, data: allQuayCranes = [] } = useAllQuayCranes();

    const { t } = useTranslation();
    const { locale, view } = useParams();

    if (!quayCraneName || errorAllQuayCranes) {
        return null;
    }

    const listOfQuayCranes = allQuayCranes
        .map(option => {
            const { quayCraneId } = option;
            const hasActiveWorkQueue = quayCranesWithActiveWorkQueue.includes(quayCraneId);
            return {
                label: quayCraneId,
                value: quayCraneId,
                active: quayCraneId.toLowerCase() === quayCraneName?.toLowerCase(),
                disabled: !hasActiveWorkQueue,
            };
        })
        .sort(({ label: a }, { label: b }) => naturalSort(a, b));

    const label =
        listOfQuayCranes.find(option => option.active)?.label ||
        (t('labels.unknown.quayCrane') as string);

    const viewType =
        view === FlowDetailMapOptions.MAP
            ? FlowDetailMapOptions.MAP
            : FlowDetailMapOptions.VISUALISATION;

    if (listOfQuayCranes.length === 0) {
        return null;
    }

    return (
        <DropdownMenu data-testid="dropdown-quaycranes">
            <DropdownMenuTrigger asChild data-testid="dropdown-trigger">
                <DropdownButton>{label}</DropdownButton>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
                {listOfQuayCranes.map(option => {
                    if (option.disabled) {
                        return (
                            <DropdownMenuItem
                                key={option.value}
                                className="flex flex-row px-3 py-2 leading-6 text-gray-600 outline-none"
                            >
                                <span className="pl-7">{option.label}</span>
                            </DropdownMenuItem>
                        );
                    }

                    return (
                        <DropdownMenuItem
                            key={option.value}
                            className={cn(
                                'relative flex cursor-pointer flex-row px-3 py-2 leading-6 text-gray-800 outline-none',
                                {
                                    'cursor-pointer hover:bg-gray-200': !option.active,
                                },
                            )}
                            onClick={() => {
                                navigate(
                                    routes.flowDetail(terminalId, option.value, viewType, locale),
                                );
                            }}
                        >
                            {option.active && (
                                <span className="absolute left-2 top-3">
                                    <SvgCheck width={32} />
                                </span>
                            )}
                            <span className="pl-7">{option.label}</span>
                        </DropdownMenuItem>
                    );
                })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
};
