import { SvgChevronUp, SvgChevronDown } from '@maersk-global/apmt-react-icons';
import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';

import { Button } from '../Button';
import { cn } from '../common/cn';

const DropdownMenu = DropdownMenuPrimitive.Root;
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;
const DropdownPortal = DropdownMenuPrimitive.Portal;

const DropdownMenuItem = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.Item>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item>
>((props, ref) => (
    <DropdownMenuPrimitive.Item
        ref={ref}
        className="flex cursor-pointer flex-row px-3 py-2 leading-6 text-gray-800 outline-none hover:bg-gray-200 data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
        {...props}
    />
));

const DropdownMenuItemAnchor = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.Item>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & { href: string }
>(({ href, ...props }, ref) => (
    <DropdownMenuPrimitive.Item ref={ref} className={'outline-none'} {...props}>
        <a
            href={href}
            className={
                'flex cursor-pointer flex-row px-3 py-2 leading-6 text-gray-800 hover:bg-gray-200'
            }
        >
            {props.children}
        </a>
    </DropdownMenuPrimitive.Item>
));

interface DropdownMenuContentCustomProps {
    className?: string;
    container?: ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Portal>['container'];
}

const DropdownMenuContent = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.Content>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content> & DropdownMenuContentCustomProps
>(({ sideOffset = 4, className, container, ...props }, ref) => (
    <DropdownMenuPrimitive.Portal container={container}>
        <DropdownMenuPrimitive.Content
            ref={ref}
            sideOffset={sideOffset}
            className={cn(
                'z-40 min-w-[220px] rounded border border-gray-400 bg-white shadow-list data-[side=bottom]:animate-slideUpAndFade data-[side=top]:animate-slideDownAndFade',
                className,
            )}
            {...props}
        />
    </DropdownMenuPrimitive.Portal>
));

const DropdownButton = forwardRef<
    ElementRef<typeof DropdownMenuPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Trigger>
>(({ children, className, ...props }, forwardedRef) => {
    const icon = props['aria-expanded'] ? (
        <SvgChevronUp className="ml-1 text-xl" />
    ) : (
        <SvgChevronDown className="ml-1 text-xl" />
    );

    return (
        <Button
            variant="plain"
            fit="medium"
            {...props}
            ref={forwardedRef}
            className={cn(
                'mds-font--default--bold flex items-center focus:bg-transparent focus:outline focus:outline-2 focus:outline-blue-200 focus:hover:bg-gray-200 active:bg-gray-200',
                className,
            )}
        >
            {children}
            {icon}
        </Button>
    );
});

export {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuItemAnchor,
    DropdownButton,
    DropdownPortal,
};
