import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { QuayCrane } from '@/types';

type FilterStore = {
    filterOnLongCranes: boolean;
    setFilterLongCranes: (filterLongCranes: boolean) => void;
    filteredQuayCranes: QuayCrane[];
    setFilteredQuayCranes: (quayCranes: QuayCrane[]) => void;
};

export const useFilterStore = create<FilterStore>()(
    devtools(
        set => ({
            filterOnLongCranes: false,
            setFilterLongCranes: (value: boolean) => {
                set({ filterOnLongCranes: value });
            },
            filteredQuayCranes: [],
            setFilteredQuayCranes: (quayCranes: QuayCrane[]) => {
                set({ filteredQuayCranes: quayCranes });
            },
        }),
        { name: 'filterStore' },
    ),
);
