import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiPin(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M6.087 3.459C7.272 2.477 8.76 2 10 2c1.24 0 2.728.477 3.913 1.459C15.11 4.452 16 5.964 16 8c0 2.142-.95 4.344-1.957 6.087a25.124 25.124 0 0 1-2.52 3.573.975.975 0 0 1-.746.34H9.223a.975.975 0 0 1-.747-.34 25.13 25.13 0 0 1-2.519-3.573C4.95 12.344 4 10.142 4 8c0-2.036.889-3.548 2.087-4.541zm.638.77C5.736 5.049 5 6.286 5 8c0 1.877.844 3.893 1.823 5.587A24.154 24.154 0 0 0 9.228 17h1.544a24.151 24.151 0 0 0 2.405-3.413C14.156 11.893 15 9.877 15 8c0-1.714-.736-2.952-1.725-3.771C12.272 3.398 11.01 3 10 3c-1.01 0-2.272.398-3.275 1.229zM10 6a2 2 0 1 0 0 4 2 2 0 0 0 0-4zM7 8a3 3 0 1 1 6 0 3 3 0 0 1-6 0z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiPin);
export default ForwardRef;
