import { ContainerHandlingEquipmentPosition } from '@maersk-global/digital-pull-operating-system-spec';
import { produce } from 'immer';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { CheType } from '@/constants/enums';
import { ChePosition, hasInaccurateSignal, hasStaleSignal } from '@/types';

export type CheMapData = Omit<
    ChePosition,
    'updatedAt' | 'hdop' | 'containerHandlingEquipmentType'
> & {
    containerHandlingEquipmentType: CheType;
    hasBadSignal: boolean;
};

type ChePositionsForType = {
    [cheIdentifier: string]: CheMapData;
};

type ChePositionsStore = {
    positions: Record<CheType, ChePositionsForType>;
    updateChePositions: (
        decodedGpsSignalStaleAfterMinutes: number,
        positions: ChePosition[],
    ) => void;
};

const mapToDomain = (
    containerHandlingEquipmentType: ContainerHandlingEquipmentPosition.containerHandlingEquipmentType,
): CheType => {
    switch (containerHandlingEquipmentType) {
        case ContainerHandlingEquipmentPosition.containerHandlingEquipmentType
            .RUBBER_TYRED_GANTRY_CRANE:
            return CheType.RUBBER_TYRED_GANTRY;
    }
};

export const useChePositionsStore = create<ChePositionsStore>()(
    devtools(
        set => {
            return {
                updateChePositions: (
                    decodedGpsSignalStaleAfterMinutes: number,
                    positions: ChePosition[],
                ) => {
                    set(previousState => {
                        const now = Date.now();
                        const newPositions = produce(previousState.positions, draft => {
                            positions.forEach(pos => {
                                const cheType = mapToDomain(pos.containerHandlingEquipmentType);
                                if (draft[cheType]) {
                                    draft[cheType][pos.containerHandlingEquipmentId] = {
                                        containerHandlingEquipmentType: mapToDomain(
                                            pos.containerHandlingEquipmentType,
                                        ),
                                        containerHandlingEquipmentId:
                                            pos.containerHandlingEquipmentId,
                                        latitude: pos.latitude,
                                        longitude: pos.longitude,
                                        hasBadSignal:
                                            hasInaccurateSignal(now, pos.hdop) ||
                                            hasStaleSignal(
                                                now,
                                                decodedGpsSignalStaleAfterMinutes,
                                                pos.updatedAt,
                                            ),
                                    };
                                } else {
                                    console.error(
                                        'cheType does not exist in draft',
                                        cheType,
                                        draft,
                                    );
                                }
                            });
                        });
                        return {
                            ...previousState,
                            positions: newPositions,
                        };
                    });
                },
                positions: {
                    [CheType.EMPTY_HANDLER]: {},
                    [CheType.OTHER]: {},
                    [CheType.REACH_STACKER]: {},
                    [CheType.RUBBER_TYRED_GANTRY]: {},
                    [CheType.QUAY_CRANE]: {},
                },
            };
        },
        { name: 'ChePositionsStore' },
    ),
);
