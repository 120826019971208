import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiContainerStackedAlt(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M3 3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v6h6a1 1 0 0 1 1 1v7H0v-7a1 1 0 0 1 1-1h2V3zm1 6h8V3H4v6zm5.5 1H1v6h8.5v-6zm1 6H19v-6h-8.5v6zm-5-8.5v-3h1v3h-1zm2 0v-3h1v3h-1zm2 0v-3h1v3h-1zm-6.5 7v-3h1v3H3zm2 0v-3h1v3H5zm2 0v-3h1v3H7zm5 0v-3h1v3h-1zm2 0v-3h1v3h-1zm2 0v-3h1v3h-1z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiContainerStackedAlt);
export default ForwardRef;
