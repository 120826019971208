import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowsLeftRight(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="m2.35 4.97 3.325-2.85.65.76L3 5.73V6h9v1H3v.27l3.325 2.85-.65.76-3.326-2.85A1 1 0 0 1 2 7.27V5.73a1 1 0 0 1 .35-.76zM17 12.73l-3.325-2.85.65-.76 3.326 2.85a1 1 0 0 1 .349.76v1.54a1 1 0 0 1-.35.76l-3.325 2.85-.65-.76L17 14.27V14H8v-1h9v-.27z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowsLeftRight);
export default ForwardRef;
