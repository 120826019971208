import { SharedCookieNames } from '@maersk-global/apmt-dpos-common';
import { DropdownWithCheckboxes } from '@maersk-global/apmt-react-components';
import { useTranslation } from 'react-i18next';
import { useCookie } from 'react-use';

import { AccordionOption } from '@/components/molecules/SelectAccordion/SelectAccordion';
import { naturalSort } from '@/utils/naturalSort';

type QuayCraneFilterDefaultProps = {
    label: string;
    disabled?: boolean;
    options?: AccordionOption[];
    onOptionChecked: (option: string, checked: boolean) => void;
    rtgOptions?: AccordionOption[];
    onRtgOptionChecked: (option: string, checked: boolean) => void;
};

type QuayCraneFilterWithMaximum = QuayCraneFilterDefaultProps & {
    showClearSelectionButton: false;
    selectedMaximum?: number;
    selectedMaximumMessage?: string;
};

type QuayCraneFilterWithClearSelection = QuayCraneFilterDefaultProps & {
    showClearSelectionButton: true;
    onClearClicked: () => void;
    onSelectAllClicked: () => void;
};

type QuayCraneFilterProps = QuayCraneFilterWithMaximum | QuayCraneFilterWithClearSelection;

export const QuayCraneFilter = ({
    label,
    disabled,
    options = [],
    onOptionChecked,
    ...props
}: QuayCraneFilterProps) => {
    const { t } = useTranslation();

    const [yardEnabled] = useCookie(SharedCookieNames.YardPage);
    if (!options) return null;

    return (
        <div
            className="relative flex items-center justify-center gap-3 space-x-1 pl-2"
            data-testid="dropdown-filter-quaycranes"
        >
            <span>{label}:</span>
            <DropdownWithCheckboxes
                label={t('pages.control.filter.selection', {
                    selected: options.filter(qc => qc.checked).length,
                    total: options.length,
                })}
                options={options.sort(({ label: a }, { label: b }) => naturalSort(a, b))}
                disabled={disabled}
                onOptionChecked={(quayCraneName, checked) =>
                    onOptionChecked(quayCraneName, checked)
                }
                showClearSelectionButton={!!props.showClearSelectionButton}
                {...(props.showClearSelectionButton && {
                    onClearClicked: props.onClearClicked,
                    onSelectAllClicked: props.onSelectAllClicked,
                })}
                {...(!props.showClearSelectionButton &&
                    props.selectedMaximum && {
                        selectedMaximum: props.selectedMaximum,
                        selectedMaximumMessage: props.selectedMaximumMessage,
                    })}
            />
            {yardEnabled && props.rtgOptions !== undefined && (
                <>
                    <span className="whitespace-nowrap">{t('pages.control.filter.titleRtg')}</span>
                    <DropdownWithCheckboxes
                        testId="-rtg"
                        label={t('pages.control.filter.rtg.selection', {
                            selected: props.rtgOptions.filter(qc => qc.checked).length,
                            total: props.rtgOptions.length,
                        })}
                        options={props.rtgOptions.sort(({ label: a }, { label: b }) =>
                            naturalSort(a, b),
                        )}
                        disabled={disabled}
                        onOptionChecked={(cheId, checked) =>
                            props.onRtgOptionChecked(cheId, checked)
                        }
                        showClearSelectionButton={!!props.showClearSelectionButton}
                        {...(props.showClearSelectionButton && {
                            onClearClicked: props.onClearClicked,
                            onSelectAllClicked: props.onSelectAllClicked,
                        })}
                    />
                </>
            )}
        </div>
    );
};
