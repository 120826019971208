import Keycloak, { KeycloakConfig } from "keycloak-js";

export const keycloakInit = async (
    keycloakConfig: KeycloakConfig
): Promise<Keycloak> => {
    const keycloak = new Keycloak(keycloakConfig);

    try {
        await keycloak.init({
            onLoad: "login-required",
            enableLogging: false,
            checkLoginIframe: false,
        });
    } catch (error) {
        console.error("Failed to initialize keycloak adapter", error);
    }

    return keycloak;
};
