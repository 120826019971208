import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { ControlOverview } from '@/components/organisms/ControlOverview/ControlOverview';
import { QueryStringKeys, TruckMapShapes } from '@/constants/enums';
import { DEFAULT_TRUCK_SHAPE } from '@/constants/settings';
import { ColumnKeys, useControlPageStore } from '@/store';
import { useTerminalStore } from '@/store/terminalStore';
import { QuayCraneWithShape } from '@/types';
import {
    convertSortStateToSearchParams,
    convertSearchParamsToSortState,
} from '@/utils/searchParams';

export const ControlOverviewPage = () => {
    const { t } = useTranslation();
    const { setPageTitle, allQuayCranes } = useTerminalStore(
        useShallow(state => ({
            setPageTitle: state.setPageTitle,
            allQuayCranes: state.allQuayCranes,
        })),
    );

    const [searchParams, setSearchParams] = useSearchParams();
    const { setUserSelectedQuayCranes, visibleQuayCranes } = useControlPageStore(
        useShallow(state => ({
            setUserSelectedQuayCranes: state.setUserSelectedQuayCranes,
            visibleQuayCranes: state.visibleQuayCranes,
        })),
    );

    // On first load of the page we need to check if there are any quay cranes
    // in the URL and if so, we need to set them as selected
    useEffect(() => {
        let quayCranesFromUrl = searchParams.get(QueryStringKeys.QUAYCRANES);
        // Fall back to localStorage if no url is set
        if (quayCranesFromUrl === null) {
            const quayCranesFromLocalStorage = localStorage.getItem(QueryStringKeys.QUAYCRANES);
            if (quayCranesFromLocalStorage === null) {
                return;
            }
            searchParams.set(QueryStringKeys.QUAYCRANES, quayCranesFromLocalStorage);
            quayCranesFromUrl = quayCranesFromLocalStorage;
        }

        const quayCranesFromUrlParams = convertSearchParamsToSortState(quayCranesFromUrl);
        const shapes = Object.keys(TruckMapShapes);
        const selectedQuayCranesWithShapeDefined: QuayCraneWithShape[] = Object.entries(
            quayCranesFromUrlParams,
        )
            .map(([columnKey, ids]) => {
                return ids.map(quayCraneId => {
                    return {
                        kind: quayCraneId.kind,
                        //TODO: fix types
                        quayCraneId: quayCraneId.id as string,
                        cheId: quayCraneId.id as string,
                        shape: (shapes.shift() ?? DEFAULT_TRUCK_SHAPE) as TruckMapShapes,
                        columnKey: columnKey as ColumnKeys,
                    };
                });
            })
            .flat(1);

        setUserSelectedQuayCranes(selectedQuayCranesWithShapeDefined);
    }, []);

    // When user selects a quay crane, we need to update the URL
    useEffect(() => {
        const searchParams = convertSortStateToSearchParams(visibleQuayCranes);
        const searchParamsQuayCranesString = createSearchParams({
            [QueryStringKeys.QUAYCRANES]: searchParams,
        });

        setSearchParams(searchParamsQuayCranesString, { replace: true });
        localStorage.setItem(QueryStringKeys.QUAYCRANES, searchParams);
    }, [visibleQuayCranes]);

    useEffect(() => {
        setPageTitle(t('pages.flows.pageTitle'));
    }, [setPageTitle]);

    if (!allQuayCranes || allQuayCranes.length === 0) {
        return (
            <div className="mx-auto -mt-14 size-full">
                <div className="flex size-full items-center justify-center">
                    <p className="text-center text-gray-600">
                        <span className="text-xl">{t('pages.control.noQuayCranes')}</span>
                    </p>
                </div>
            </div>
        );
    }

    return <ControlOverview />;
};
