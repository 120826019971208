import { Loader } from '@maersk-global/apmt-react-components';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/shallow';

import { FlowOverview } from '@/components/organisms/FlowOverview/FlowOverview';
import { useQuayCraneStore } from '@/store/quayCranesStore';
import { useTerminalStore } from '@/store/terminalStore';
import { useYardStore } from '@/store/yardStore';

export const FlowOverviewPage = () => {
    const { t } = useTranslation();
    const { setPageTitle, allQuayCranes } = useTerminalStore(
        useShallow(state => ({
            setPageTitle: state.setPageTitle,
            allQuayCranes: state.allQuayCranes,
        })),
    );
    const { workQueuesPerQuayCrane, longCranePerVesselVisit } = useQuayCraneStore(
        useShallow(store => ({
            workQueuesPerQuayCrane: store.workQueuesPerQuayCrane,
            longCranePerVesselVisit: store.longCranePerVesselVisit,
        })),
    );
    const yardWorkInstructions = useYardStore(useShallow(store => store.yardWorkInstructions));

    useEffect(() => {
        setPageTitle(t('pages.flows.pageTitle'));
    }, [setPageTitle]);

    if (allQuayCranes === undefined) {
        return <Loader />;
    }

    return (
        <FlowOverview
            allQuayCranes={allQuayCranes}
            workQueuesPerQuayCrane={workQueuesPerQuayCrane}
            longCranePerVesselVisit={longCranePerVesselVisit}
            yardWorkInstructions={yardWorkInstructions}
        />
    );
};
