import { CellContext } from '@tanstack/react-table';

import { TruckStatus } from '@/components/atoms/TruckInstructionsCells/TruckStatus';

import { InstructionsTableCellData } from './TruckInstructionsTable';

export const FormattedTruckId = ({
    data,
    isPaused = false,
}: {
    data: CellContext<InstructionsTableCellData, string>;
    isPaused?: boolean;
}) => {
    const { hasReachedFlowPosition, instruction } = data.cell.row.original;

    return (
        <TruckStatus
            isCompleted={data.row.original.isCompleted}
            hasReachedFlowPosition={
                data.row.original.isCompleted ? undefined : hasReachedFlowPosition
            }
            instruction={instruction}
            value={data.getValue()}
            isPaused={!!isPaused}
        />
    );
};
