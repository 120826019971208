import {
    FeatureFlagV1,
    FeatureFlagService,
    YardStackingEquipmentFlagV1,
} from '@maersk-global/digital-pull-operating-system-spec';

import { FlagType } from '@/hooks/useFeatureFlag';

export const disableFeatureFlag = async (terminalId: string, cheId: string, flagType: FlagType) => {
    try {
        switch (flagType.cheType) {
            case 'QUAY_CRANE':
                return await FeatureFlagService.disableFeatureFlagOnQuayCrane({
                    terminalId,
                    quayCraneId: cheId,
                    flagName: FeatureFlagV1[flagType.flag],
                });
            case 'YSE':
                return await FeatureFlagService.disableYardStackingEquipmentFlag({
                    terminalId,
                    yardStackingEquipmentId: cheId,
                    flagName: YardStackingEquipmentFlagV1[flagType.flag],
                });
        }
    } catch (error) {
        console.error(error);
    }
};
