import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiExclamationOctagon(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M6.5 2.293A1 1 0 0 1 7.207 2h5.586a1 1 0 0 1 .707.293L17.707 6.5a1 1 0 0 1 .293.707v5.586a1 1 0 0 1-.293.707L13.5 17.707a1 1 0 0 1-.707.293H7.207a1 1 0 0 1-.707-.293L2.293 13.5A1 1 0 0 1 2 12.793V7.207a1 1 0 0 1 .293-.707L6.5 2.293zM12.793 3H7.207L3 7.207v5.586L7.207 17h5.586L17 12.793V7.207L12.793 3zM9.5 11V6h1v5h-1zm-.25 1.5h1.5V14h-1.5v-1.5z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiExclamationOctagon);
export default ForwardRef;
