import { YardStackingEquipmentTypeEnum } from '@maersk-global/digital-pull-operating-system-spec';
import { array, Codec, enumeration, GetType, string } from 'purify-ts';

import { optionalNullToUndefined } from './util/optionalNullToUndefined';

export const singleTypeOfEquipmentDecoder = Codec.interface({
    name: string,
    type: optionalNullToUndefined(enumeration(YardStackingEquipmentTypeEnum)),
});
export type PieceOfEquipment = GetType<typeof singleTypeOfEquipmentDecoder>;

export const piecesOfEquipmentDecoder = array(singleTypeOfEquipmentDecoder);
export type PiecesOfEquipment = GetType<typeof piecesOfEquipmentDecoder>;

export type EquipmentPerQuayCrane = { [quayCraneName: string]: PiecesOfEquipment };
