import { useTranslation } from 'react-i18next';

import { FeatureLayerData } from '@/types/map';

export const FlowPositionsTooltip = ({ flowPosition }: { flowPosition: FeatureLayerData }) => {
    const { t } = useTranslation();
    const { flowPositionName, quayCraneName, x, y } = flowPosition;

    return (
        <div className="relative" style={{ left: x, top: y - 50 }}>
            <span className="rounded bg-white p-2 text-gray-700">
                {t(`pages.map.flowPositions.${flowPositionName}`, {
                    quayCraneName: quayCraneName,
                })}
            </span>
        </div>
    );
};
