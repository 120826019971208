import { OpenAPI } from '@maersk-global/digital-pull-operating-system-spec';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { MapProvider } from 'react-map-gl/maplibre';

import { TerminalSettings } from '@/api';
import { Router } from '@/components/router/Router';
import config from '@/config';
import { TerminalSettingsProvider } from '@/hooks/terminalInfo/TerminalInfoProvider';

import '../i18n';

// Set the base url for the OpenAPI client
OpenAPI.BASE = config.backendApiUrl();

export const queryClient = new QueryClient();

export type ApplicationProps = {
    terminalSettings: TerminalSettings;
};

export const Application = ({ terminalSettings }: ApplicationProps) => {
    return (
        <ErrorBoundary
            fallback={<div>ERROR!</div>}
            onError={error => {
                console.error('ERROR FROM BOUNDARY', error);
            }}
        >
            <QueryClientProvider client={queryClient}>
                <TerminalSettingsProvider settings={terminalSettings}>
                    <MapProvider>
                        <Router />
                    </MapProvider>
                </TerminalSettingsProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </ErrorBoundary>
    );
};
