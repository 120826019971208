import { Switch } from '@maersk-global/apmt-react-components';
import { useCookie } from 'react-use';

import { Title } from '@/components/atoms/Title/Title';
import { Card } from '@/components/organisms/Cards/Card';

export const SettingsCard = ({
    title,
    description,
    cookieName,
}: {
    title: string;
    description: string;
    cookieName: string;
}) => {
    const [cookieValue, updateCookie, deleteCookie] = useCookie(cookieName);

    const toggleCookie = () => {
        if (!cookieValue) {
            updateCookie('true', {
                sameSite: 'strict',
                expires: 365,
            });

            return;
        }

        deleteCookie();
    };

    return (
        <Card className="gap-2">
            <div className="mb-6">
                <header>
                    <Title className="mds-font--display-1">{title}</Title>
                </header>
                <p className="mds-font--small">{description}</p>
            </div>

            <footer className="mt-auto">
                <Switch
                    label={''}
                    checked={cookieValue === 'true' ? true : false}
                    onCheckedChange={toggleCookie}
                />
            </footer>
        </Card>
    );
};
