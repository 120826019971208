import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import { getApplicationVersion } from '@/api/getApplicationVersion';
import { reactQueryKeys } from '@/constants/queryKeys';

// Deployed version checker
export function useVersionCheckHook() {
    const [versionHasBeenUpdated, setVersionHasBeenUpdated] = useState(false);
    const [currentVersion, setCurrentVersion] = useState<string | undefined>();

    const { data: applicationVersion } = useQuery({
        queryKey: [reactQueryKeys.APPLICATION_VERSION],
        queryFn: () => {
            return getApplicationVersion();
        },
        refetchInterval: 15 * 60 * 1000, // Every 15 minutes
        refetchIntervalInBackground: true, // Also fetch when tab is not active
    });

    useEffect(() => {
        if (!applicationVersion || !applicationVersion.version) {
            return;
        }

        // On first load, set the current version, but don't do anything else
        if (currentVersion !== applicationVersion.version) {
            setCurrentVersion(applicationVersion.version);
        }

        if (
            currentVersion !== undefined &&
            !versionHasBeenUpdated &&
            currentVersion !== applicationVersion.version
        ) {
            setVersionHasBeenUpdated(true);
        }
    }, [applicationVersion?.version]);

    return {
        version: currentVersion,
        versionHasBeenUpdated,
    };
}
