import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '../common/cn';

type FitClasses = {
    small: string;
    medium: string;
};

export type Fit = keyof FitClasses;

const inputFitClasses: FitClasses = {
    small: 'h-8',
    medium: 'h-10',
};

const labelFitClasses: FitClasses = {
    small: 'leading-5',
    medium: 'leading-6',
};

type LabelPosition = 'top' | 'left';

export interface InputTextProps extends InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    labelPosition?: LabelPosition;
    fit?: Fit;
    type?: string;
    placeholder?: string;
    invalid?: boolean;
    warning?: boolean;
    icon?: React.ReactNode;
    affix?: React.ReactNode;
}

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
    (
        {
            name,
            label,
            labelPosition = 'top',
            fit = 'medium',
            invalid = false,
            warning = false,
            icon,
            className,
            affix,
            ...otherProps
        },
        ref,
    ) => {
        return (
            <label
                className={cn(
                    'flex',
                    { 'flex-col': labelPosition === 'top' },
                    { 'items-center': labelPosition === 'left' },
                )}
            >
                {label && (
                    <span
                        className={cn('text-sm text-gray-900', labelFitClasses[fit], {
                            'mb-1': labelPosition === 'top',
                            'mr-1': labelPosition === 'left',
                        })}
                    >
                        {label}
                    </span>
                )}
                <div
                    className={cn('relative flex items-center', {
                        grow: labelPosition === 'left',
                    })}
                >
                    <input
                        {...otherProps}
                        name={name}
                        ref={ref}
                        className={cn(
                            inputFitClasses[fit],
                            className,
                            'w-full rounded border bg-white px-3 transition duration-200 ease-in-out placeholder:text-gray-600 focus:border-blue-400 focus:shadow-input focus:outline-none',
                            {
                                'border-feedback-danger focus:border-red-900 focus:shadow-feedback-invalidShadow':
                                    invalid,
                                'border-gray-300 focus:border-blue-400': !invalid,
                                'pr-8': !!icon,
                                'bg-feedback-warning-light': warning,
                            },
                        )}
                    />
                    {icon && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            {icon}
                        </div>
                    )}
                    {affix && <span className="ml-1">{affix}</span>}
                </div>
            </label>
        );
    },
);
