import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowLeft(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="m5.35 8.47 3.325-2.85.65.76L6 9.23v.27h9v1H6v.27l3.325 2.85-.65.76-3.326-2.85A1 1 0 0 1 5 10.77V9.23a1 1 0 0 1 .35-.76z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowLeft);
export default ForwardRef;
