import { Switch } from '@maersk-global/apmt-react-components';
import { FiltersBar, UtilityBar } from '@maersk-global/apmt-react-layout';
import { FiltersSection } from '@maersk-global/apmt-react-layout/src/components/FiltersSection/FiltersSection';
import { useTranslation } from 'react-i18next';

import { AccordionOption } from '@/components/molecules/SelectAccordion/SelectAccordion';

import { QuayCraneFilter } from '../QuayCraneFilter/QuayCraneFilter';

type QuayCranesFiltersBar = {
    quayCraneFilterOptions: AccordionOption[];
    onLongCranesSwitch: (checked: boolean) => void;
    onQuayCranesFilterSelection: (quayCraneId: string, checked: boolean) => void;
    onClearSelection: () => void;
    onHandleSelectAll: () => void;
    onResetFilters: () => void;
    hasActiveFilters: boolean;
    hasActiveLongCraneFilter: boolean;
};

export const QuayCranesFiltersBar = ({
    quayCraneFilterOptions,
    onLongCranesSwitch,
    onQuayCranesFilterSelection,
    onClearSelection,
    onHandleSelectAll,
    onResetFilters,
    hasActiveFilters,
    hasActiveLongCraneFilter,
}: QuayCranesFiltersBar) => {
    const { t } = useTranslation();

    const handleLongCraneChange = (checked: boolean) => onLongCranesSwitch(checked);
    const handleOptionChecked = (quayCraneId: string, checked: boolean) =>
        onQuayCranesFilterSelection(quayCraneId, checked);
    const handleClearSelection = () => onClearSelection();
    const handleSelectAll = () => onHandleSelectAll();
    const handleResetFilters = () => onResetFilters();

    return (
        <UtilityBar className="relative z-40 -mx-6 -mt-6 mb-6">
            <FiltersBar
                showResetButton={hasActiveFilters}
                handleReset={handleResetFilters}
                resetButtonLabel={t('labels.filters.reset')}
            >
                <FiltersSection>
                    <Switch
                        fit="small"
                        label={t('labels.filters.only_long_cranes')}
                        checked={hasActiveLongCraneFilter}
                        onCheckedChange={handleLongCraneChange}
                    />
                </FiltersSection>
                <FiltersSection className="justify-center pl-3">
                    <QuayCraneFilter
                        onRtgOptionChecked={() => {}}
                        label={t('labels.filters.qcs')}
                        disabled={quayCraneFilterOptions.length === 0}
                        options={quayCraneFilterOptions}
                        onOptionChecked={handleOptionChecked}
                        showClearSelectionButton={true}
                        onClearClicked={handleClearSelection}
                        onSelectAllClicked={handleSelectAll}
                    />
                </FiltersSection>
            </FiltersBar>
        </UtilityBar>
    );
};
