import { SvgExclamationTriangle } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';

import { FloatingBlock } from '@/components/atoms/FloatingBlock/FloatingBlock';
import { FloatingBlocksContainer } from '@/components/atoms/FloatingBlocksContainer/FloatingBlocksContainer';
import { AndonTypeOption } from '@/constants/enums';
import { useAndonsPerQuayCrane } from '@/hooks/useAndonsPerQuayCrane';
import { QuayCranes } from '@/types';

export const FloatingAndons = ({ quayCranes }: { quayCranes: QuayCranes }) => {
    const { t } = useTranslation();

    const andonsForSelectedQuayCranes = useAndonsPerQuayCrane(quayCranes);

    return (
        <>
            {andonsForSelectedQuayCranes.length > 0 && (
                <FloatingBlocksContainer testid="andons">
                    {andonsForSelectedQuayCranes.map((andon, index) => {
                        const { cheName, andonType } = andon;

                        return (
                            <FloatingBlock
                                key={`${cheName}-${index}`}
                                className="bg-feedback-danger-light"
                                icon={
                                    <SvgExclamationTriangle className="size-5 stroke-feedback-danger" />
                                }
                                testid="andon"
                            >
                                <strong>
                                    {cheName} -{' '}
                                    <abbr title={andonType} className="!no-underline">
                                        {andonType === AndonTypeOption.OPERATIONAL
                                            ? t('labels.andonTypes.operational').toUpperCase()
                                            : t('labels.andonTypes.technical').toUpperCase()}
                                    </abbr>
                                </strong>
                                <span className="-ml-2 inline-block">
                                    {t('labels.andon_one').toLowerCase()}
                                </span>
                            </FloatingBlock>
                        );
                    })}
                </FloatingBlocksContainer>
            )}
        </>
    );
};
