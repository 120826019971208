import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowUp(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M8.47 5.35A1 1 0 0 1 9.23 5h1.54a1 1 0 0 1 .759.35l2.85 3.325-.759.65L10.77 6h-.27v9h-1V6h-.27L6.38 9.325l-.76-.65 2.85-3.326z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowUp);
export default ForwardRef;
