import { SvgTimes } from '@maersk-global/apmt-react-icons';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import type { ComponentPropsWithoutRef, ElementRef, HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '../common/cn';

const Popover = PopoverPrimitive.Root;
const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = forwardRef<
    ElementRef<typeof PopoverPrimitive.Content>,
    ComponentPropsWithoutRef<typeof PopoverPrimitive.Content> & {
        containerRef?: ComponentPropsWithoutRef<typeof PopoverPrimitive.Portal>['container'];
    }
>(({ children, className, side = 'top', containerRef, ...props }, forwardedRef) => {
    return (
        <PopoverPrimitive.Portal container={containerRef}>
            <PopoverPrimitive.Content
                side={side}
                sideOffset={5}
                onClick={e => e.stopPropagation()}
                {...props}
                ref={forwardedRef}
                className={cn(
                    'min-w-[140px] rounded border border-gray-300 bg-white px-4 py-3 shadow will-change-[transform,opacity] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade',
                    className,
                )}
            >
                {children}
            </PopoverPrimitive.Content>
        </PopoverPrimitive.Portal>
    );
});

const PopoverClose = forwardRef<
    ElementRef<typeof PopoverPrimitive.Close>,
    ComponentPropsWithoutRef<typeof PopoverPrimitive.Close>
>((props, forwardRef) => {
    return (
        <PopoverPrimitive.Close
            className="absolute right-2 top-2 inline-flex cursor-default items-center justify-center outline-none hover:cursor-pointer"
            aria-label="Close"
            ref={forwardRef}
            {...props}
        >
            <SvgTimes className="text-2xl" />
        </PopoverPrimitive.Close>
    );
});

const PopoverArrow = forwardRef<
    ElementRef<typeof PopoverPrimitive.Arrow>,
    ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow>
>((props, forwardRef) => {
    return (
        <PopoverPrimitive.Arrow
            asChild
            className="text-gray-300"
            width="15px"
            height="7px"
            ref={forwardRef}
            {...props}
        >
            <Arrow />
        </PopoverPrimitive.Arrow>
    );
});

const Arrow = forwardRef<
    ElementRef<typeof PopoverPrimitive.Arrow>,
    ComponentPropsWithoutRef<typeof PopoverPrimitive.Arrow>
>((props, ref) => (
    <svg width="20" height="10" viewBox="0 0 24 12" fill="white" {...props} ref={ref}>
        <polygon points="0,0 30,0 15,10" stroke="currentColor" strokeWidth="2px" />
        <rect width="26" height="1" fill="white" x="2px" />
    </svg>
));

const PopoverTitle = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
    <div className={cn('font-bold text-gray-800', className)} {...props} />
);

export { Popover, PopoverTrigger, PopoverClose, PopoverTitle, PopoverArrow, PopoverContent };
