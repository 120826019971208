import { TerminalTruckLocationEnum } from '@maersk-global/digital-pull-operating-system-spec/lib/models/TerminalTruckLocationEnum';
import { array, boolean, Codec, enumeration, exactly, GetType, intersect, string } from 'purify-ts';

import {
    FlowPositionName,
    MoveKind,
    MoveStatus,
    TerminalTruckLocation,
    YardStackingEquipmentType,
} from '@/constants/enums';
import { positionUpdateMetaDecoder } from '@/types/position';
import { routesUpdateMetaDecoder } from '@/types/routes';

import { coordinateDecoder } from './coordinates';
import { singleTypeOfEquipmentDecoder } from './equipment';
import { optionalNullToUndefined } from './util/optionalNullToUndefined';

export const terminalTruckIdDecoder = Codec.interface({
    terminalTruckId: string,
});
export type TerminalTruckId = GetType<typeof terminalTruckIdDecoder>;

export const flowPositionDecoder = Codec.interface({
    flowPositionName: enumeration(FlowPositionName),
});
export const terminalTruckPositionDecoder = intersect(
    intersect(coordinateDecoder, terminalTruckIdDecoder),
    intersect(positionUpdateMetaDecoder, routesUpdateMetaDecoder),
);
export type TerminalTruckPosition = GetType<typeof terminalTruckPositionDecoder>;

export const terminalTruckPositionsDecoder = array(terminalTruckPositionDecoder);

export const terminalTruckStatusDecoder = Codec.interface({
    terminalTruckId: string,
    assignedToYardStackingEquipment: optionalNullToUndefined(singleTypeOfEquipmentDecoder),
    equipmentStatusIsWorking: boolean,
    workInstructionConsideredCompleted: boolean,
    moveStatus: enumeration(MoveStatus),
    moveKind: enumeration(MoveKind),
    truckLocation: enumeration(TerminalTruckLocation),
    workInstructionIsMoveInProgress: boolean,
});

export type TerminalTruckStatus = GetType<typeof terminalTruckStatusDecoder>;

export const terminalTruckStatusPayloadDecoder = Codec.interface({
    quayCraneName: string,
    trucks: array(
        Codec.interface({
            terminalTruckId: string,
            equipmentStatusIsWorking: boolean,
            workInstructionIsMoveInProgress: boolean,
            workInstructionConsideredCompleted: boolean,
            moveStatus: enumeration(MoveStatus),
            moveKind: enumeration(MoveKind),
            truckLocation: enumeration(TerminalTruckLocationEnum),
            assignedToYardStackingEquipment: optionalNullToUndefined(
                Codec.interface({
                    name: string,
                    type: enumeration(YardStackingEquipmentType),
                }),
            ),
        }),
    ),
});
export const terminalTruckStatusEventDecoder = Codec.interface({
    event: exactly('TRUCK_STATUS_UPDATED'),
    data: terminalTruckStatusPayloadDecoder,
});
