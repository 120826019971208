import { Marker } from 'react-map-gl/maplibre';

import { YardStackingEquipmentMarker } from '@/components/atoms/YardStackingEquipmentMarker/YardStackingEquipmentMarker';
import { CheType } from '@/constants/enums';
import { CheMapData } from '@/store/chePositions';

export const YardStackingEquipmentMapMarker = ({
    yardStackingEquipment,
    width = 24,
    onYardStackingEquipmentClicked,
}: {
    yardStackingEquipment: CheMapData;
    width?: number;
    onYardStackingEquipmentClicked?: (type: CheType, id: string) => void;
}) => {
    return (
        <Marker
            key={`${yardStackingEquipment.containerHandlingEquipmentType}${yardStackingEquipment.containerHandlingEquipmentId}`}
            longitude={yardStackingEquipment.longitude}
            latitude={yardStackingEquipment.latitude}
            color="transparent"
            anchor="center"
            style={{
                width,
            }}
            onClick={
                onYardStackingEquipmentClicked &&
                (() =>
                    onYardStackingEquipmentClicked(
                        yardStackingEquipment.containerHandlingEquipmentType,
                        yardStackingEquipment.containerHandlingEquipmentId,
                    ))
            }
        >
            <div
                key={`${yardStackingEquipment.containerHandlingEquipmentType}${yardStackingEquipment.containerHandlingEquipmentId}`}
                className="relative"
            >
                <YardStackingEquipmentMarker
                    yardStackingEquipmentType={yardStackingEquipment.containerHandlingEquipmentType}
                    yardStackingEquipmentId={yardStackingEquipment.containerHandlingEquipmentId}
                    hasBadSignal={yardStackingEquipment.hasBadSignal}
                />
            </div>
        </Marker>
    );
};
