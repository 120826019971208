import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowClockwise(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M16 2v3.293a1 1 0 0 1-.293.707L15 6.707a1 1 0 0 1-.707.293H11V6h3.293l.094-.094A6 6 0 1 0 16 10h1a7 7 0 1 1-2-4.899V2h1z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowClockwise);
export default ForwardRef;
