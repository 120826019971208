import clsx from 'clsx';
import type { PropsWithChildren, ReactNode } from 'react';

export interface HeaderProps {
    rightSide?: ReactNode;
    rightSideClassName?: string;
}

export const Header = ({
    children,
    rightSide,
    rightSideClassName,
}: PropsWithChildren<HeaderProps>) => (
    <header className="flex max-h-[63px] w-full items-center justify-between bg-white pl-6">
        <div className="m-auto grow">
            <div className="mds-font--default--bold flex w-auto flex-row flex-nowrap items-center gap-2 whitespace-nowrap">
                {children}
            </div>
        </div>

        {rightSide && (
            <div className={clsx('flex items-center', rightSideClassName)}>{rightSide}</div>
        )}
    </header>
);
