import { Codec, Left, Right } from 'purify-ts';

export const stringToNumberDecoder = Codec.custom<number>({
    encode: value => value,
    decode: value => {
        const num = Number(value);
        if (!Number.isFinite(num)) {
            return Left(`Expected valid number got [${value}]`);
        }

        return Right(num);
    },
});
