import { FlowPositionEnum } from '@maersk-global/digital-pull-operating-system-spec';

import { TagContainer } from '@/components/atoms/TagContainer/TagContainer';
import { TagContainerSmall } from '@/components/atoms/TagContainerSmall/TagContainerSmall';
import { Truck } from '@/components/atoms/Truck/Truck';
import { TruckSmall } from '@/components/atoms/TruckSmall/TruckSmall';
import { FlowVisualisationPositions } from '@/components/organisms/FlowVisualisation/FlowVisualisation';
import {
    FlowPositionName,
    FlowStatusOption,
    FlowVisualisationVariant,
    VisualAppearance,
} from '@/constants/enums';
import { MoveInstruction } from '@/types';
import { cn } from '@/utils/cn';

type TruckWithAppeareance = Pick<MoveInstruction, 'truckId'> & {
    appearance: VisualAppearance;
};

interface FlowPositionProps {
    id: FlowVisualisationPositions;
    trucks: TruckWithAppeareance[];
    label: string;
    row?: boolean; // if true, the label is next to the position
    error?: boolean;
    singleTag?: boolean;
    variant?: FlowVisualisationVariant;
    flowStatus?: FlowStatusOption;
}

export const FlowPosition = ({
    id,
    trucks,
    label,
    flowStatus = 'UNKNOWN',
    row = false,
    error = false,
    variant = FlowVisualisationVariant.LARGE,
}: FlowPositionProps) => {
    // Large variant (flowDetail visualisation)
    const isLargeVariant = variant === FlowVisualisationVariant.LARGE;
    // Small variant (quayCraneCard visualisation)
    const isSmallVariant = variant === FlowVisualisationVariant.SMALL;
    // Row = LABEL (yard) | POSITION (trucks in yard position)
    const isLargeRow = row && isLargeVariant;
    const isSmallRow = row && isSmallVariant;

    return (
        <div
            className={cn('flex h-full', {
                'flex-col items-center': !row,
                'justify-center': isSmallRow,
            })}
        >
            <span
                className={cn('flex text-gray-600', {
                    'mds-font--small--bold': isLargeVariant,
                    'mds-font--x-small mb-1': isSmallVariant,
                    'absolute -left-full h-full items-center': isLargeRow,
                    hidden: isSmallRow,
                })}
            >
                <span
                    className={cn('block w-full', {
                        'text-center': !isLargeRow || isSmallVariant,
                        'pr-2 text-right': row,
                    })}
                >
                    {label}
                </span>
            </span>

            {isSmallVariant && (
                <TagContainerSmall flowStatus={flowStatus}>
                    {trucks.map(item => (
                        <TruckSmall key={item.truckId} label={item.truckId} />
                    ))}
                </TagContainerSmall>
            )}
            {isLargeVariant && (
                <TagContainer
                    error={error}
                    flowStatus={flowStatus}
                    className={cn({
                        'min-h-singleTag':
                            id === FlowPositionName.STANDBY ||
                            id === FlowPositionName.PULL ||
                            id === 'IN_YARD',
                    })}
                >
                    {trucks.map(truck => (
                        <Truck
                            key={truck.truckId}
                            label={truck.truckId}
                            appearance={truck.appearance}
                        />
                    ))}
                </TagContainer>
            )}
        </div>
    );
};

export const getTruckAppearance = (
    instruction: FlowPositionName,
    hasReachedFlowPosition: boolean,
) => {
    if (instruction === FlowPositionEnum.PULL) {
        return VisualAppearance.ACTIVE;
    }

    if (hasReachedFlowPosition) {
        return VisualAppearance.DONE;
    } else {
        return VisualAppearance.IN_PROGRESS;
    }
};
