import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiBucketMop(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M6 11V0h1v11h3a1 1 0 0 1 1 1v1.419l.755 2.265A1 1 0 0 1 10.807 17H2.194a1 1 0 0 1-.949-1.316L2 13.419V12a1 1 0 0 1 1-1h3zm4.036-5H9.5a.5.5 0 0 0-.497.55l.197 2.5.995-.1L10.001 7h7l-.715 9H14v1h2.287c.523 0 .958-.478.997-1l.715-9.463A.5.5 0 0 0 17.5 6h-.535a3.5 3.5 0 0 0-6.93 0zm1.014 0a2.5 2.5 0 0 1 4.9 0h-4.9zM3 12v1.419a1 1 0 0 1-.05.316L2.193 16H4.5v-2h1v2h2v-2h1v2h2.307l-.755-2.265A1 1 0 0 1 10 13.42V12H3z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiBucketMop);
export default ForwardRef;
