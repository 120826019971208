import clsx from 'clsx';
import type { PropsWithChildren } from 'react';

export type TitleProps = {
    as?: keyof Pick<HTMLElementTagNameMap, 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'>;
    screenReaderText?: string;
    className?: string;
};

export const Title = ({
    as = 'h2',
    screenReaderText,
    children,
    className,
}: PropsWithChildren<TitleProps>) => {
    const TitleTag = as;
    return (
        <TitleTag
            className={clsx(className, {
                'mds-font--display-1 mds-font--default--bold': !className,
            })}
        >
            {screenReaderText && <span className="sr-only">{screenReaderText}</span>}
            {children}
        </TitleTag>
    );
};
