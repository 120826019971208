export interface Specification<T> {
    (input: T): boolean;
}

export function all<T>(...specifications: Specification<T>[]): Specification<T> {
    return (input: T): boolean => specifications.every(spec => spec(input));
}

export function some<T>(...specifications: Specification<T>[]): Specification<T> {
    return (input: T): boolean => specifications.some(spec => spec(input));
}

export function inverse<T>(specification: Specification<T>): Specification<T> {
    return input => !specification(input);
}
