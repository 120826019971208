import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import { ProfileButton } from '@maersk-global/apmt-react-components';
import { SvgExclamationTriangle } from '@maersk-global/apmt-react-icons';
import { Header as LayoutHeader } from '@maersk-global/apmt-react-layout';
import { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import Clock from '@/components/atoms/Clock/Clock';
import { DebugDownloadButton } from '@/components/atoms/DebugDownloadButton/DebugDownloadButton';
import { Title } from '@/components/atoms/Title/Title';
import { BackButton } from '@/components/molecules/BackButton/BackButton';
import { QuayCraneDropdown } from '@/components/organisms/QuayCraneDropdown/QuayCraneDropdown';
import config from '@/config';
import { FlowDetailMapOptions } from '@/constants/enums';
import DposLogoIcon from '@/icons/DposLogo';
import { routes } from '@/routes/routes';
import { useConnectionWarningStore } from '@/store/connectionWarningStore';
import { useTerminalStore } from '@/store/terminalStore';
export const Header = () => {
    const { getWarningCode } = useConnectionWarningStore();
    const [warningCode, setWarningCode] = useState(getWarningCode());
    const { quayCraneName, terminalId, pageTitle } = useTerminalStore(
        useShallow(state => ({
            quayCraneName: state.quayCraneName,
            terminalId: state.terminalId,
            pageTitle: state.pageTitle,
        })),
    );
    const { locale } = useParams();
    const { pathname } = useLocation();
    const { user, logout } = useAuth();

    const showBackButton =
        pathname === routes.flowDetail(terminalId, quayCraneName, FlowDetailMapOptions.MAP) ||
        pathname ===
            routes.flowDetail(terminalId, quayCraneName, FlowDetailMapOptions.VISUALISATION);

    useEffect(() => {
        const interval = setInterval(() => {
            setWarningCode(getWarningCode());
        }, 1000);

        return () => clearInterval(interval);
    });

    const rightSide = (
        <>
            {warningCode
                .map(code => {
                    return (
                        <div className="flex flex-nowrap items-center text-sm text-gray-400">
                            <SvgExclamationTriangle className="w-5 text-xl" />E{code}
                        </div>
                    );
                })
                .extractNullable()}
            <Clock />
            <DebugDownloadButton quayCraneName={quayCraneName} />
            <div className="flex items-center">
                <Link
                    to={routes.flowOverview(terminalId, locale)}
                    className="text-center text-xs text-gray-400"
                    title={`v${config.appVersion}`}
                >
                    <DposLogoIcon data-testid="dpos-logo" className="pr-6" />
                </Link>
                <ProfileButton
                    className="border-l border-gray-300"
                    userEmail={user.email}
                    onLogout={() => {
                        logout();
                    }}
                    username={user.name}
                />
            </div>
        </>
    );

    return (
        <LayoutHeader rightSide={rightSide} rightSideClassName="gap-2">
            {pageTitle ? <Title>{pageTitle}</Title> : null}
            {showBackButton ? (
                <div className="min-w-[52px]">
                    <BackButton href={routes.flowOverview(terminalId)} />
                </div>
            ) : null}
            <div>{terminalId && <QuayCraneDropdown terminalId={terminalId} />}</div>
        </LayoutHeader>
    );
};
