import { Children, PropsWithChildren } from 'react';

import { FlowStatusOption } from '@/constants/enums';
import { cn } from '@/utils/cn';

type ComponentProps = {
    error?: boolean;
    flowStatus?: FlowStatusOption;
    className?: string;
};

export const TagContainer = ({
    children,
    flowStatus,
    className,
    error = false,
}: PropsWithChildren<ComponentProps>) => {
    const isOverDeployed = flowStatus === 'OVER_DEPLOYED';
    const isAtRisk = flowStatus === 'RISK';
    const isStopped = flowStatus === 'STOPPED';
    const numberOfChildren = Children.count(children);
    const empty = numberOfChildren < 1;

    return (
        <div
            className={cn(
                'flex min-w-tag-container max-w-tag-container flex-1 flex-col items-stretch rounded-3xl border-3 bg-gray-200 px-4 py-[6px]',
                className,
                {
                    'border-gray-400': !error,
                    'border-feedback-danger': error,
                    'border-feedback-danger bg-feedback-danger-light': isStopped && empty,
                    'border-feedback-warning bg-feedback-warning-light': isAtRisk && empty,
                    'border-feedback-info bg-feedback-info-light':
                        isOverDeployed && numberOfChildren > 2,
                },
            )}
        >
            {children}
        </div>
    );
};
