import { Codec, Right, Left } from 'purify-ts';

export const nullToUndefined = Codec.custom<undefined>({
    decode: input => {
        if (input === null || input === undefined) {
            return Right(undefined);
        }

        return Left('Not null or undefined');
    },
    encode: input => input, // strings have no serialization logic
});
