import { array, Codec, enumeration, exactly, GetType, number, oneOf, string } from 'purify-ts';

import { TruckMapShapes } from '@/constants/enums';

import { MergeTypes } from './util/mergeTypes';

export const singleQuayCraneDecoder = Codec.interface({
    quayCraneId: string,
});
export type QuayCrane = GetType<typeof singleQuayCraneDecoder>;

export const quayCranesDecoder = array(singleQuayCraneDecoder);
export type QuayCranes = GetType<typeof quayCranesDecoder>;

const quayCraneStatusInfo = Codec.interface({
    quayCraneName: string,
    hoistPosition: number,
    spreaderMode: string,
    trolleyPosition: string,
});

export type QuayCraneStatusInfo = GetType<typeof quayCraneStatusInfo>;
export type SpreaderInfoPerQuayCrane = { [quayCraneName: string]: QuayCraneStatusInfo };

export const quayCraneSpreaderEventDecoder = Codec.interface({
    event: exactly('QUAY_CRANE_SPREADER_UPDATED'),
    data: quayCraneStatusInfo,
});

export const truckShapeDecoder = Codec.interface({
    shape: enumeration(TruckMapShapes),
    columnKey: oneOf([
        exactly('root'),
        exactly('container1'),
        exactly('container2'),
        exactly('container3'),
    ]),
});

export type TruckShape = GetType<typeof truckShapeDecoder>;
export type QuayCraneWithShape = MergeTypes<
    ((QuayCrane & { kind: 'qc' }) | { cheId: string; kind: 'rtg' }) & TruckShape
>;
