import { ReactElement } from 'react';

import { FlowVisualisationVariant } from '@/constants/enums';
import { cn } from '@/utils/cn';

type EquipmentRowProps = {
    border?: boolean;
    firstCell?: ReactElement;
    secondCell?: ReactElement;
    thirdCell?: ReactElement;
    fourthCell?: ReactElement;
    fifthCell?: ReactElement;
    sixthCell?: ReactElement;
    seventhCell?: ReactElement;
    variant?: FlowVisualisationVariant;
};

/**
 * Positions match the positions in the FlowVisualisation component per ROW
 * FIRSTCELL | SECONDCELL | THIRDCELL | FOURTHCELL | FIFTHCELL | SIXTHCELL | SEVENTHCELL
 */
export const EquipmentRow = ({
    border = false,
    firstCell = undefined,
    secondCell = undefined,
    thirdCell = undefined,
    fourthCell = undefined,
    fifthCell = undefined,
    sixthCell = undefined,
    seventhCell = undefined,
    variant = FlowVisualisationVariant.LARGE,
}: EquipmentRowProps) => {
    const isLargeVariant = variant === FlowVisualisationVariant.LARGE;

    return (
        <>
            <div
                className={cn({
                    '-ml-[0.17rem] border-b-3 border-l-3 border-inherit': border,
                    'rounded-bl-4xl': isLargeVariant && border,
                    'rounded-bl-xl': !isLargeVariant && border,
                })}
            >
                {firstCell}
            </div>
            <div
                className={cn({
                    'border-b-3 border-inherit': border,
                })}
            >
                {secondCell}
            </div>
            <div
                className={cn({
                    'border-b-3 border-inherit': border,
                })}
            >
                {thirdCell}
            </div>
            <div
                className={cn({
                    'border-b-3 border-inherit': border,
                })}
            >
                {fourthCell}
            </div>
            <div
                className={cn({
                    'border-b-3 border-inherit': border,
                })}
            >
                {fifthCell}
            </div>
            <div
                className={cn({
                    'border-b-3 border-inherit': border,
                })}
            >
                {sixthCell}
            </div>
            <div
                className={cn({
                    'border-b-3 border-r-3 border-inherit': border,
                    'rounded-br-4xl': isLargeVariant && border,
                    'rounded-br-xl': !isLargeVariant && border,
                    '-mr-[0.17rem]': isLargeVariant,
                    'mr-[1.33rem]': !isLargeVariant,
                })}
            >
                {seventhCell}
            </div>
        </>
    );
};
