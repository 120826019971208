import { QuayCraneService } from '@maersk-global/digital-pull-operating-system-spec';

export const resendMoveInstructions = async (
    terminalId: string,
    quayCraneName: string,
    vesselVisitId: string,
): Promise<void> => {
    return QuayCraneService.createMoveInstructions({
        terminalId,
        quayCraneId: quayCraneName,
        vesselVisitId,
    });
};

export const triggerReset = async (
    terminalId: string,
    quayCraneName: string,
    vesselVisitId: string,
    truckNames?: string[],
): Promise<void> => {
    return QuayCraneService.resetCompletedState({
        terminalId,
        quayCraneId: quayCraneName,
        vesselVisitId,
        truckNames,
    });
};
