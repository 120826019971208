import * as ToggleGroup from '@radix-ui/react-toggle-group';

import { cn } from '../common/cn';
import { isDefined } from '../common/guards';

export const itemSegmentControlVariantsClassNames = {
    primary:
        'enabled:hover:bg-blue-900 enabled:hover:text-white data-[state=on]:bg-blue-900 data-[state=on]:text-white',
    secondary: 'enabled:data-[state=off]:hover:bg-gray-200 data-[state=on]:bg-gray-400',
};
export const rootSegmentControlVariantsClassNames = {
    primary: 'border-gray-300 divide-gray-300',
    secondary: 'border-gray-400 divide-gray-400',
};
export const rootSegmentControlFitClassNames = {
    small: 'mds-font--small',
    medium: 'mds-font--medium',
};
export const itemSegmentControlFitClassNames = {
    small: 'px-4 py-2',
    medium: 'px-6 py-3',
};

export type SegmentControlVariants = keyof typeof itemSegmentControlVariantsClassNames;
export type SegmentControlFits = keyof typeof rootSegmentControlFitClassNames;

export interface SegmentedControlProps {
    options: {
        value: string;
        label: string;
        icon?: React.ReactNode;
    }[];
    variant?: SegmentControlVariants;
    fit?: SegmentControlFits;
    value?: ToggleGroup.ToggleGroupSingleProps['defaultValue'];
    onValueChange: ToggleGroup.ToggleGroupSingleProps['onValueChange'];
    ariaLabel?: ToggleGroup.ToggleGroupSingleProps['aria-label'];
    disabled?: ToggleGroup.ToggleGroupSingleProps['disabled'];
}

export const SegmentedControl = ({
    options,
    variant = 'primary',
    fit = 'small',
    value,
    onValueChange,
    ariaLabel,
    disabled,
}: SegmentedControlProps) => (
    <ToggleGroup.Root
        className={cn(
            'inline-flex divide-x rounded border bg-white',
            rootSegmentControlVariantsClassNames[variant],
            rootSegmentControlFitClassNames[fit],
        )}
        type="single"
        value={value}
        aria-label={ariaLabel}
        disabled={disabled}
        onValueChange={onValueChange}
        data-testid="toggle-group"
    >
        {options.map(({ value, label, icon }) => {
            return (
                <ToggleGroup.Item
                    key={value}
                    value={value}
                    aria-label={label}
                    className={cn(
                        'flex items-center justify-center bg-white leading-4 text-black first:rounded-l last:rounded-r enabled:data-[state=on]:cursor-default',
                        'disabled:cursor-not-allowed disabled:opacity-50',
                        itemSegmentControlVariantsClassNames[variant],
                        itemSegmentControlFitClassNames[fit],
                    )}
                    data-testid={`toggle-item-${value}`}
                >
                    {isDefined(icon) && icon}
                    {label}
                </ToggleGroup.Item>
            );
        })}
    </ToggleGroup.Root>
);
