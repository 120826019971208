import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from '@maersk-global/apmt-react-components';
import { useTranslation } from 'react-i18next';

import { TruckBubbleColors } from '@/components/atoms/TruckBubbles/TruckBubble';
import { cn } from '@/utils/cn';

export const YardEquipmentLegend = () => {
    const { t } = useTranslation();
    return (
        <Accordion type="single" collapsible={true} className="mt-2 inline-block bg-white">
            <AccordionItem value="map-legend" className="mds-font--default text-base">
                <AccordionTrigger direction="up">
                    <span className="pl-1">{t('pages.map.legend.title')}</span>
                </AccordionTrigger>
                <AccordionContent className="px-5 py-2">
                    <ul>
                        <YardTableLegendItem
                            indicatorColorBorder={TruckBubbleColors.InternalFull}
                            indicatorColor={TruckBubbleColors.InternalFull}
                            label={t('pages.yard.legend.internalFull')}
                        />
                        <YardTableLegendItem
                            indicatorColorBorder={TruckBubbleColors.InternalFull}
                            indicatorColor={TruckBubbleColors.InternalEmpty}
                            label={t('pages.yard.legend.internalEmpty')}
                        />
                        <YardTableLegendItem
                            indicatorColorBorder={TruckBubbleColors.ExternalFull}
                            indicatorColor={TruckBubbleColors.ExternalFull}
                            label={t('pages.yard.legend.externalFull')}
                        />
                        <YardTableLegendItem
                            indicatorColorBorder={TruckBubbleColors.ExternalFull}
                            indicatorColor={TruckBubbleColors.ExternalEmpty}
                            label={t('pages.yard.legend.externalEmpty')}
                        />
                        <YardTableLegendItem
                            indicatorColorBorder={TruckBubbleColors.HousekeepingFull}
                            indicatorColor={TruckBubbleColors.HousekeepingFull}
                            label={t('pages.yard.legend.housekeepingFull')}
                        />
                        <YardTableLegendItem
                            indicatorColorBorder={TruckBubbleColors.HousekeepingFull}
                            indicatorColor={TruckBubbleColors.HousekeepingEmpty}
                            label={t('pages.yard.legend.housekeepingEmpty')}
                        />
                    </ul>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
};

export const YardTableLegendItem = ({
    label,
    indicatorColor,
    indicatorColorBorder,
}: {
    label: string;
    indicatorColor: string;
    indicatorColorBorder: string;
}) => {
    return (
        <li className="mds-font--x-small relative mb-2 flex items-center">
            <svg height="12" width="12" className={cn('mr-2')} xmlns="http://www.w3.org/2000/svg">
                <circle
                    cx="6"
                    cy="6"
                    r="5"
                    fill={indicatorColor}
                    stroke={indicatorColorBorder}
                    strokeWidth="2"
                />
            </svg>
            {label}
        </li>
    );
};
