import { Codec, enumeration, GetType, number, string } from 'purify-ts';

import { WorkQueueMode, WorkQueueStatus, WorkQueueType } from '@/constants/enums';

import { optionalNullToUndefined } from './util/optionalNullToUndefined';

export const workQueueDecoder = Codec.interface({
    id: string,
    name: string,
    type: enumeration(WorkQueueType),
    loadMode: enumeration(WorkQueueMode),
    status: enumeration(WorkQueueStatus),
    sequence: number,
    activatedOn: optionalNullToUndefined(string),
    vesselName: optionalNullToUndefined(string),
    vesselVisitId: optionalNullToUndefined(string),
});

export type WorkQueue = GetType<typeof workQueueDecoder>;
