import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiUserCircle(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M10 3a7 7 0 0 0-3.852 12.846l.583-2.331A2 2 0 0 1 8.67 12h2.658a2 2 0 0 1 1.94 1.515l.583 2.33A7 7 0 0 0 10 3zm2.947 13.351-.648-2.593a1 1 0 0 0-.97-.758H8.67a1 1 0 0 0-.97.758l-.648 2.593A6.971 6.971 0 0 0 10 17a6.97 6.97 0 0 0 2.947-.649zM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0zm6-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v2.5a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1V7zm1 0v2.5h2V7H9z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiUserCircle);
export default ForwardRef;
