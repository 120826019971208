import { YardStackingEquipmentSvg } from '@/components/atoms/YardStackingEquipmentMarker/YardStackingEquipmentSvg';
import { CheType } from '@/constants/enums';
import { cleanupContainerHandlingEquipmentId } from '@/utils/cleanupContainerHandlingEquipmentId';

const getYardStackingEquipmentIcon = (type: CheType, id: string, hasBadSignal: boolean) => {
    switch (type) {
        case CheType.RUBBER_TYRED_GANTRY:
            return <YardStackingEquipmentSvg identifier={id} hasBadSignal={hasBadSignal} />;
    }
    return <></>;
};

export const YardStackingEquipmentMarker = ({
    yardStackingEquipmentType,
    yardStackingEquipmentId,
    hasBadSignal,
}: {
    yardStackingEquipmentType: CheType;
    yardStackingEquipmentId: string;
    hasBadSignal?: boolean;
}) => {
    const cleanedYardStackingEquipmentId =
        cleanupContainerHandlingEquipmentId(yardStackingEquipmentId);

    const yseIcon = getYardStackingEquipmentIcon(
        yardStackingEquipmentType,
        cleanedYardStackingEquipmentId,
        hasBadSignal ?? false,
    );
    return <div className="opacity-65">{yseIcon}</div>;
};
