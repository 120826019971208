import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiChevronDown(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="m8.717 13.643-5.1-6.071.766-.644L9.483 13h1.034l5.1-6.072.766.644-5.1 6.071a1 1 0 0 1-.766.357H9.483a1 1 0 0 1-.766-.357z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiChevronDown);
export default ForwardRef;
