import { QuayCraneService } from '@maersk-global/digital-pull-operating-system-spec';

import { flowPositionsDecoder } from '@/types';
import { safeDecode } from '@/utils/safeCodec';

export const getFlowPositionsByQuayCrane = async (terminalId: string, quayCraneName: string) => {
    const response = await QuayCraneService.getAllQuayCraneFlowPositions({
        terminalId,
        quayCraneId: quayCraneName,
    });

    return safeDecode(flowPositionsDecoder, response).caseOf({
        Left: e => {
            throw new Error(
                `Error fetching ${terminalId} flow positions for quay crane [${quayCraneName}]: ${e}`,
            );
        },
        Right: r => r,
    });
};
