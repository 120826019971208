import { SvgCheck, SvgLockSolid, SvgLockUnlocked } from '@maersk-global/apmt-react-icons';
import { Maybe, NonEmptyList } from 'purify-ts';

import { SquareTag } from '@/components/atoms/SquareTag/SquareTag';
import { FlowStatusOption } from '@/constants/enums';

type TruckAssignedToFlowPosition = {
    name: string;
    hasReachedFlowPosition: Maybe<boolean>;
};

export type FlowStateRowProps = {
    pull: Maybe<NonEmptyList<string>>;
    standby: Maybe<TruckAssignedToFlowPosition>;
    underCrane: Maybe<TruckAssignedToFlowPosition>;
    status: FlowStatusOption;
    relevantTrucks: string[];
    className?: string;
    kind?: 'yard' | 'qc';
    standbyLocked?: boolean;
    underCraneLocked?: boolean;
};

export const FlowStateRow = ({
    status,
    pull,
    standby,
    underCrane,
    relevantTrucks: relevantTrucks,
    className,
    standbyLocked,
    underCraneLocked,
    kind = 'qc',
}: FlowStateRowProps) => {
    const standbyLock = Maybe.fromNullable(standbyLocked)
        .map(locked =>
            locked ? (
                <SvgLockSolid className="size-4 text-gray-600" />
            ) : (
                <SvgLockUnlocked className="size-4" />
            ),
        )
        .extractNullable();
    const underCraneLock = Maybe.fromNullable(underCraneLocked)
        .map(locked =>
            locked ? (
                <SvgLockSolid className="size-4 text-gray-600" />
            ) : (
                <SvgLockUnlocked className="size-4" />
            ),
        )
        .extractNullable();
    return (
        <div className={`${className ?? ''} relative flex flex-row`}>
            <div className="absolute left-[14%] top-2 h-px w-[72%] bg-black"></div>
            <div className="mr-8 flex flex-col items-center">
                <div className="z-0 mb-1 bg-white px-1 text-xs text-gray-600">Pull</div>
                <SquareTag
                    flowStatus={status}
                    className="min-w-[84px]"
                    label={pull
                        .map(p => {
                            return p.map((truck, index, list) => {
                                const last = index === list.length - 1;
                                const truckIsIrrelevant = !relevantTrucks.includes(truck);
                                return (
                                    <div
                                        key={truck}
                                        className={`text-gray-800${
                                            truckIsIrrelevant ? ' opacity-30' : ''
                                        }`}
                                    >
                                        {truck}
                                        {last ? '' : ','}
                                    </div>
                                );
                            });
                        })
                        .caseOf({
                            Just: trucks => {
                                return <div className="flex flex-wrap">{trucks}</div>;
                            },
                            Nothing: () => {
                                return <>-</>;
                            },
                        })}
                />
            </div>
            <div className="mr-8 flex flex-col items-center">
                <div className="z-0 mb-1 flex flex-row items-center bg-white px-1 text-xs text-gray-600">
                    Standby{standbyLock}
                </div>
                <SquareTag
                    className="min-w-10"
                    flowStatus={standby.map(() => 'ACTIVE' as FlowStatusOption).orDefault(status)}
                    label={standby
                        .map(t => {
                            const truckIsIrrelevant = !relevantTrucks.includes(t.name);
                            return (
                                <div className="flex flex-row items-center justify-center">
                                    {t.hasReachedFlowPosition
                                        .map(check =>
                                            check ? (
                                                <SvgCheck
                                                    color="var(--mdt-theme-palette-functional-50)"
                                                    className="size-[18px] text-green-500"
                                                />
                                            ) : (
                                                <ProgressDots />
                                            ),
                                        )
                                        .extractNullable()}
                                    <span
                                        className={`text-gray-800${
                                            truckIsIrrelevant ? ' opacity-30' : ''
                                        }`}
                                    >
                                        {t.name}
                                    </span>
                                </div>
                            );
                        })
                        .orDefault(<>-</>)}
                />
            </div>
            <div className="mr-8 flex flex-col items-center">
                <div className="z-0 mb-1 flex flex-row items-center whitespace-nowrap bg-white px-1 text-xs text-gray-600">
                    Under {kind === 'qc' ? 'QC' : 'CHE'}
                    {underCraneLock}
                </div>
                <SquareTag
                    className="min-w-10"
                    flowStatus={underCrane
                        .map(() => 'ACTIVE' as FlowStatusOption)
                        .orDefault(status)}
                    label={underCrane
                        .map(t => {
                            const truckIsIrrelevant = !relevantTrucks.includes(t.name);
                            return (
                                <div className="flex flex-row items-center justify-center">
                                    {t.hasReachedFlowPosition
                                        .map(check =>
                                            check ? (
                                                <SvgCheck
                                                    color="var(--mdt-theme-palette-functional-50)"
                                                    className="size-[18px] text-green-500"
                                                />
                                            ) : (
                                                <ProgressDots />
                                            ),
                                        )
                                        .extractNullable()}
                                    <span
                                        className={`text-gray-800${
                                            truckIsIrrelevant ? ' opacity-30' : ''
                                        }`}
                                    >
                                        {t.name}
                                    </span>
                                </div>
                            );
                        })
                        .orDefault(<>-</>)}
                />
            </div>
        </div>
    );
};

export const ProgressDots = () => {
    const dotClasses: React.ComponentProps<'span'>['className'] =
        'rounded bg-blue-400 h-1 w-1 absolute';

    return (
        <span className="relative inline-flex size-5 items-center">
            <span className={`${dotClasses} left-[1px] animate-dot-1`}></span>
            <span className={`${dotClasses} left-[7px] animate-dot-2`}></span>
            <span className={`${dotClasses} left-[13px] animate-dot-3`}></span>
        </span>
    );
};
