export const YardStackingEquipmentSvg = ({
    identifier,
    hasBadSignal,
}: {
    identifier: string;
    hasBadSignal?: boolean;
}) => (
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 32 42"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="3.5"
            y="0.5"
            width="25"
            height="41"
            rx="2.5"
            fill="#004D40"
            fillOpacity="0.16"
            stroke="#00897A"
        />
        <rect x="0.5" y="12.5" width="31" height="17" rx="2.5" fill="#00897A" stroke="#00897A" />
        <text
            x="50%"
            y="50%"
            dy="1"
            dominantBaseline="middle"
            textAnchor="middle"
            fill="white"
            className="mds-font--x-small"
        >
            {identifier}
        </text>
        {hasBadSignal && (
            <circle cx="50%" cy="6" r="4" fill="red">
                <animate attributeName="r" values="4;1;4" dur="3s" repeatCount="indefinite" />
            </circle>
        )}
    </svg>
);
