import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiArrowsDownUp(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M11.97 2.35a1 1 0 0 1 .76-.35h1.54a1 1 0 0 1 .759.35l2.85 3.325-.759.65L14.27 3H14v9h-1V3h-.27L9.88 6.325l-.76-.65 2.85-3.326zM6 17h-.27l-2.85-3.325-.76.65 2.85 3.326a1 1 0 0 0 .76.349h1.54a1 1 0 0 0 .759-.35l2.85-3.325-.759-.65L7.27 17H7V8H6v9z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiArrowsDownUp);
export default ForwardRef;
