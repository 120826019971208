import type { ClassValue } from 'clsx';

import { cn } from '../common/cn';
import { Tooltip } from '../Tooltip';

export interface TagProps {
    label: string;
    tooltip?: string;
    appearance?: 'default' | 'info' | 'success' | 'warning' | 'error' | 'warning-weak';
    fit?: 'small' | 'medium' | 'large';
    width?: 'auto' | 'full-width';
    className?: ClassValue;
}

export const Tag = ({
    label,
    tooltip,
    appearance = 'default',
    fit = 'medium',
    width = 'auto',
    className,
}: TagProps) => {
    return (
        <div
            className={cn(
                'inline-flex max-w-full overflow-hidden rounded-full font-sans text-gray-900',
                {
                    'bg-[var(--mdt-theme-tag--default)]': appearance === 'default',
                    'bg-[var(--mdt-theme--info)]': appearance === 'info',
                    'bg-[var(--mdt-theme--success)]': appearance === 'success',
                    'bg-[var(--mdt-theme--warning)]': appearance === 'warning',
                    'bg-[var(--mdt-theme--error)]': appearance === 'error',
                    'bg-warning-weak': appearance === 'warning-weak',
                    'px-2 py-0.5 text-xs leading-4': fit === 'small',
                    'px-2.5 py-0.5 text-sm leading-5': fit === 'medium',
                    'px-3 py-1 text-sm leading-5': fit === 'large',
                    'w-full': width === 'full-width',
                },
                className,
            )}
        >
            <span
                title={tooltip ? undefined : label}
                className={cn('block w-full truncate', {
                    'text-center': width === 'full-width',
                })}
            >
                {tooltip ? (
                    <Tooltip content={tooltip} fit={fit}>
                        <button>{label}</button>
                    </Tooltip>
                ) : (
                    label
                )}
            </span>
        </div>
    );
};
