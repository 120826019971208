import { CellContext } from '@tanstack/react-table';

import { InstructionStatus } from '@/components/atoms/TruckInstructionsCells/InstructionStatus';

import { InstructionsTableCellData } from './TruckInstructionsTable';

export const FormattedInstruction = ({
    data,
}: {
    data: CellContext<InstructionsTableCellData, [boolean | undefined, string]>;
}) => {
    const [isLocked, instructionTranslation] = data.getValue();
    const instruction = data.cell.row.original;
    const cycleType = instruction.cycleType;
    const handledCraneTriggers = instruction.handledCraneTriggers;
    const expectedCraneTriggers = instruction.expectedCraneTriggers;

    return (
        <InstructionStatus
            isCompleted={data.row.original.isCompleted}
            cycleType={cycleType}
            isLocked={!!isLocked}
            instruction={instructionTranslation}
            handledCraneTriggers={handledCraneTriggers}
            expectedCraneTriggers={expectedCraneTriggers}
        />
    );
};
