import { Switch, Tooltip, TooltipProvider } from '@maersk-global/apmt-react-components';
import Cookies from 'js-cookie';
import { Maybe } from 'purify-ts';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { Block } from '@/components/atoms/Block/Block';
import { CookieNames } from '@/constants/cookies';
import { SpreaderAction } from '@/constants/enums';
import { useGetWorkQueuesPerQuayCrane } from '@/hooks/useGetWorkQueuesPerQuayCrane';
import { QuayCraneStatusInfo } from '@/types';
import { cn } from '@/utils/cn';
import { humanReadableTime } from '@/utils/dates';

export interface FlowDetailDrawerQuayCraneStatusInfoProps {
    quayCraneName: string;
    quayCraneStatusInfo: QuayCraneStatusInfo | undefined;
    tosOnlyCraneTriggersEnabled: boolean;
    toggleTosOnlyTriggers: (checked: boolean) => void;
}
export const FlowDetailDrawerQuayCraneStatusInfo = ({
    quayCraneName,
    quayCraneStatusInfo,
    tosOnlyCraneTriggersEnabled,
    toggleTosOnlyTriggers,
}: FlowDetailDrawerQuayCraneStatusInfoProps) => {
    const { t } = useTranslation();
    const unknownValue = 'Unknown';
    const showTosOnlyToggle = Cookies.get(CookieNames.TosOnlyCraneTriggers) === 'true';

    const workQueuesPerQuayCrane = useGetWorkQueuesPerQuayCrane(quayCraneName);
    const spreaderActionTime =
        workQueuesPerQuayCrane &&
        workQueuesPerQuayCrane?.spreaderAction &&
        workQueuesPerQuayCrane?.spreaderAction?.handledOn
            ? humanReadableTime(workQueuesPerQuayCrane?.spreaderAction?.handledOn || '')
            : unknownValue;
    const spreaderActionLabel = Maybe.fromNullable(workQueuesPerQuayCrane?.spreaderAction)
        .map(action => {
            const spreaderActionLabel =
                action.lockOrUnlock === SpreaderAction.LOCK ? t('labels.lock') : t('labels.unlock');

            return (
                <>
                    <span>{spreaderActionLabel}</span>
                    {' @ '}
                    <span>{spreaderActionTime}</span>
                </>
            );
        })
        .orDefault(<span>{unknownValue}</span>);

    const labelSpreaderMode = Maybe.fromNullable(quayCraneStatusInfo)
        .map(({ spreaderMode }) => humanReadableSpreaderMode(spreaderMode))
        .orDefault(unknownValue);

    const labelTrolleyPosition = Maybe.fromNullable(quayCraneStatusInfo)
        .map(({ trolleyPosition }) => trolleyPosition)
        .orDefault(unknownValue);

    const labelHoistPosition = Maybe.fromNullable(quayCraneStatusInfo)
        .map(({ hoistPosition }) => Math.round(hoistPosition * 10) / 10)
        .map(hoistPosition => (
            // eslint-disable-next-line react/jsx-key
            <span className="font-mono">
                <span className="mr-1">{hoistPosition}</span>
                <abbr title="meters" className="!no-underline">
                    m
                </abbr>
            </span>
        ))
        .orDefault(<span>{unknownValue}</span>);

    return (
        <>
            <TooltipProvider>
                <div className={cn('mb-4 flex flex-row items-center justify-end gap-1', {})}>
                    {showTosOnlyToggle && (
                        <Tooltip
                            align="center"
                            className="z-50 max-w-[300px]"
                            content={
                                'Back up for the crane trigger when the crane loses connectivity. Will send the next truck when the work instruction is completed'
                            }
                            side="left"
                        >
                            <Switch
                                name={quayCraneName}
                                label={'TOS-only crane triggers'}
                                value={quayCraneName}
                                checked={tosOnlyCraneTriggersEnabled}
                                data-state={tosOnlyCraneTriggersEnabled ? 'checked' : 'unchecked'}
                                className="ml-auto whitespace-nowrap "
                                onCheckedChange={checked => toggleTosOnlyTriggers(checked)}
                            />
                        </Tooltip>
                    )}
                </div>
            </TooltipProvider>
            <div className="flex grow flex-row justify-between gap-2 pr-4">
                <StatusInfoBlock
                    title={t('pages.positions.detailDrawer.quayCraneStatusInfo.last_trigger')}
                >
                    {spreaderActionLabel}
                </StatusInfoBlock>
                <StatusInfoBlock
                    title={t('pages.positions.detailDrawer.quayCraneStatusInfo.hoist_height')}
                >
                    {labelHoistPosition}
                </StatusInfoBlock>
                <StatusInfoBlock
                    title={t('pages.positions.detailDrawer.quayCraneStatusInfo.above')}
                >
                    {labelTrolleyPosition}
                </StatusInfoBlock>
                <StatusInfoBlock
                    title={t('pages.positions.detailDrawer.quayCraneStatusInfo.spreader_mode')}
                >
                    {labelSpreaderMode}
                </StatusInfoBlock>
            </div>
        </>
    );
};

export const StatusInfoBlock = ({ title, children }: PropsWithChildren<{ title: string }>) => {
    return (
        <Block className="flex-col items-start">
            <div className="text-xs text-gray-600">{title}</div>
            <div className="text-sm">{children}</div>
        </Block>
    );
};

const humanReadableSpreaderMode = (mode = '') => {
    let formattedMode = 'Unknown';

    switch (mode?.toLowerCase()) {
        case '45':
            formattedMode = '45’';
            break;
        case '40':
            formattedMode = '40’';
            break;
        case '20':
            formattedMode = '20’';
            break;
        case 'twin':
            formattedMode = 'Twin';
            break;
        default:
            formattedMode = 'Unknown';
            break;
    }

    return formattedMode;
};
