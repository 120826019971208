import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiEye(props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M2 9.393v1.214C2.466 11.33 5.081 15 10 15c4.919 0 7.534-3.67 8-4.393V9.393C17.534 8.67 14.919 5 10 5 5.081 5 2.466 8.67 2 9.393zm-.85-.526C1.631 8.112 4.51 4 10 4s8.368 4.112 8.85 4.867c.104.162.15.343.15.517v1.232a.958.958 0 0 1-.15.517C18.369 11.888 15.49 16 10 16s-8.368-4.112-8.85-4.867a.957.957 0 0 1-.15-.517V9.384c0-.174.046-.355.15-.517zM10 8a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-3 2a3 3 0 1 1 6 0 3 3 0 0 1-6 0z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiEye);
export default ForwardRef;
