import { SvgPause } from '@maersk-global/apmt-react-icons';
import { FlowPositionEnum } from '@maersk-global/digital-pull-operating-system-spec';

import { FlowPositionName } from '@/constants/enums';

import { CheckMark } from './CheckMark';
import { ProgressDots } from './ProgressDots';
import { TruckId } from './TruckId';

export const TruckStatus = ({
    hasReachedFlowPosition,
    instruction,
    value,
    isPaused = false,
    isCompleted,
}: {
    hasReachedFlowPosition?: boolean;
    isCompleted: boolean;
    instruction: FlowPositionEnum;
    value: string;
    isPaused: boolean;
}) => {
    const isPull = instruction === FlowPositionName.PULL;
    const truckArrived = hasReachedFlowPosition;

    return (
        <span
            className={`flex max-w-fit items-center justify-start gap-1 ${
                isCompleted ? 'opacity-30' : ''
            }`}
        >
            {isPaused ? (
                <>
                    <SvgPause width={24} height={24} />
                    {value}
                </>
            ) : isPull ? (
                TruckId(value)
            ) : (
                <>
                    {truckArrived !== undefined
                        ? truckArrived
                            ? CheckMark(value)
                            : ProgressDots(value)
                        : TruckId(value)}
                </>
            )}
        </span>
    );
};
