import { Maybe } from 'purify-ts';

import { FlowStatusOption } from '@/constants/enums';
import { calculatePositionBackgroundColorYard } from '@/utils/calculateFlowPositionBackground';
import { cn } from '@/utils/cn';
export type PullAmount = 'Fits' | 'Stretch' | 'TooMany';
export const FlowPositionTruckBubbleContainer = ({
    hasAndon,
    flowStatus,
    equipment,
    under,
    pull,
    pullAmount,
    standby,
    className,
}: {
    hasAndon: boolean;
    flowStatus: FlowStatusOption;
    equipment: Maybe<string>;
    pull: Maybe<React.ReactNode>;
    pullAmount: PullAmount;
    standby: Maybe<React.ReactNode>;
    under: Maybe<React.ReactNode>;
    className?: string;
}) => {
    const pullContent = pull.map(all => <>{all}</>).orDefault(<>-</>);
    const bg = calculatePositionBackgroundColorYard(flowStatus);
    return (
        <div className={cn(className, 'mds-font--x-small flex h-5 items-center justify-center')}>
            <span
                className={cn('pr-1 w-12 text-right font-mono', {
                    'mds-font--x-small--bold text-feedback-danger': hasAndon,
                })}
            >
                {equipment.orDefault('')}
            </span>

            <div
                className={cn(
                    'bg-gray-200',
                    'w-12 leading-5 flex h-full mr-1 justify-center items-center rounded ',
                )}
            >
                {under.orDefault('-')}
            </div>
            <div
                className={cn(
                    standby.map(() => 'bg-gray-200').orDefault(bg),
                    'w-16 leading-5 flex h-full mr-1 justify-center items-center rounded ',
                )}
            >
                {standby.orDefault('-')}
            </div>
            <div className={cn('w-12 leading-5 flex h-full ')}>
                {pullAmount === 'Fits' ? (
                    <FitsBlock content={pullContent} flowStatus={flowStatus} />
                ) : (
                    <StretchBlock content={pullContent} flowStatus={flowStatus} />
                )}
            </div>
        </div>
    );
};

const FitsBlock = ({
    flowStatus,
    content,
}: {
    content: React.ReactNode;
    flowStatus: FlowStatusOption;
}) => {
    const bg = calculatePositionBackgroundColorYard(flowStatus);
    return (
        <div
            className={cn(
                bg,
                'px-2 w-16 leading-5 flex h-full mr-1 justify-center items-center rounded ',
            )}
        >
            {content}
        </div>
    );
};
const StretchBlock = ({
    content,
    flowStatus,
}: {
    content: React.ReactNode;
    flowStatus: FlowStatusOption;
}) => {
    const bg = calculatePositionBackgroundColorYard(flowStatus);
    return (
        <div
            className={cn(
                bg,
                'px-2 min-w-12 overflow-visible flex flex-nowrap rounded items-center ',
            )}
        >
            {content}
        </div>
    );
};
