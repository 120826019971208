import { SvgExclamationTriangleSolid } from '@maersk-global/apmt-react-icons';
import { TerminalTruckWithStatusV1 } from '@maersk-global/digital-pull-operating-system-spec';

import { TagContainer } from '@/components/atoms/TagContainer/TagContainer';
import { TagContainerSmall } from '@/components/atoms/TagContainerSmall/TagContainerSmall';
import { Truck } from '@/components/atoms/Truck/Truck';
import { TruckSmall } from '@/components/atoms/TruckSmall/TruckSmall';
import { EquipmentIcon } from '@/components/molecules/EquipmentIcon/EquipmentIcon';
import { FlowVisualisationVariant } from '@/constants/enums';
import { PieceOfEquipment } from '@/types';
import { cn } from '@/utils/cn';

type ComponentProps = {
    equipment: PieceOfEquipment;
    andon?: boolean;
    variant?: FlowVisualisationVariant;
    trucks?: Pick<TerminalTruckWithStatusV1, 'terminalTruckId'>[];
};

export const YardEquipment = ({
    equipment,
    andon = false,
    variant = FlowVisualisationVariant.LARGE,
    trucks = [],
}: ComponentProps) => {
    const isLargeVariant = variant === FlowVisualisationVariant.LARGE;
    const isSmallVariant = variant === FlowVisualisationVariant.SMALL;
    const hasTrucks = trucks && trucks.length > 0;

    return (
        <div
            className={cn('relative flex h-full flex-col items-center', {
                '-bottom-[24px]': isLargeVariant,
                '-bottom-[10px]': isSmallVariant,
            })}
        >
            <span
                className={cn('flex', {
                    'mt-2 items-center': isSmallVariant,
                })}
            >
                {andon && isLargeVariant ? (
                    <SvgExclamationTriangleSolid
                        color="var(--mdt-theme-palette-feedback-danger-dark)"
                        className="relative top-1 -mt-px mr-1 text-xl"
                    />
                ) : null}
                <span
                    className={cn({
                        'inline-block': isSmallVariant,
                        'mds-font--x-small h-full': isSmallVariant,
                        'mds-font--default--bold': isLargeVariant,
                        'text-feedback-danger': andon,
                        'mds-font--x-small--bold': andon && isSmallVariant,
                    })}
                >
                    {equipment.name}
                </span>
            </span>
            <div className="overflow-hidden text-gray-600">
                <EquipmentIcon
                    equipment={equipment}
                    iconSize={isLargeVariant ? 102 : 70}
                    className={cn({
                        '-my-5': isLargeVariant,
                        '-mb-4 -ml-1 -mr-0 -mt-2': isSmallVariant,
                        'text-feedback-danger': andon,
                    })}
                />
            </div>
            {isSmallVariant && (
                <div className="relative">
                    <TagContainerSmall>
                        {hasTrucks &&
                            trucks.map(truck => (
                                <TruckSmall
                                    key={truck.terminalTruckId}
                                    label={truck.terminalTruckId}
                                />
                            ))}
                    </TagContainerSmall>
                </div>
            )}

            {isLargeVariant && (
                <div className="relative">
                    <TagContainer className="min-h-singleTag">
                        {hasTrucks &&
                            trucks.map(truck => (
                                <Truck key={truck.terminalTruckId} label={truck.terminalTruckId} />
                            ))}
                    </TagContainer>
                </div>
            )}
        </div>
    );
};
