import { PropsWithChildren } from 'react';

import { TruckMapColors, TruckMapShapes } from '@/constants/enums';
import { cleanupContainerHandlingEquipmentId } from '@/utils/cleanupContainerHandlingEquipmentId';
import { cn } from '@/utils/cn';
import { badSignalSvg, getTruckShapeIcon } from '@/utils/getTruckShape';
export const TruckMarker = ({
    shape,
    color,
    truckId,
}: PropsWithChildren<{ shape: TruckMapShapes; color: string; truckId: string }>) => {
    const cleanedTruckId = cleanupContainerHandlingEquipmentId(truckId);
    const truckIcon = getTruckShapeIcon(shape);

    return (
        <div className="absolute size-8">
            <span className={cn('relative left-0 top-0 z-0 overflow-hidden', color)}>
                {truckIcon({ stroke: true })}
            </span>
            <span
                className={cn(
                    'mds-font--x-small--bold absolute top-0 z-10 flex h-full w-full items-center justify-center font-sansNumeric text-white',
                )}
            >
                {cleanedTruckId}
            </span>
        </div>
    );
};

export const InaccurateSignalTruckMarker = ({
    shape,
}: PropsWithChildren<{ shape: TruckMapShapes }>) => {
    const truckIcon = getTruckShapeIcon(shape);

    return (
        <div className="absolute z-10 size-8 animate-fadeInOut">
            <span
                className={cn(`relative left-0 top-0 z-0 overflow-hidden`, TruckMapColors.ORANGE)}
            >
                {truckIcon({ stroke: false })}
            </span>
            <span
                className={cn(
                    'mds-font--x-small--bold absolute top-0 z-10 flex h-full w-full items-center justify-center font-sansNumeric text-white',
                )}
            >
                {badSignalSvg}
            </span>
        </div>
    );
};
export const StaleSignalTruckMarker = ({ shape }: PropsWithChildren<{ shape: TruckMapShapes }>) => {
    const truckIcon = getTruckShapeIcon(shape);

    return (
        <div className="absolute z-10 size-8 animate-fadeInOut">
            <span className={cn('relative left-0 top-0 z-0 overflow-hidden', TruckMapColors.AMBER)}>
                {truckIcon({ stroke: false })}
            </span>
            <span
                className={cn(
                    'mds-font--x-small--bold absolute top-0 z-10 flex h-full w-full items-center justify-center font-sansNumeric text-white',
                )}
            >
                {badSignalSvg}
            </span>
        </div>
    );
};
