import { FakeButton } from '@maersk-global/apmt-react-components';
import { FeatureFlagV1 } from '@maersk-global/digital-pull-operating-system-spec';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { Title } from '@/components/atoms/Title/Title';
import { routes } from '@/routes/routes';
import { useTerminalStore } from '@/store/terminalStore';
import { cn } from '@/utils/cn';

export const FeatureFlagCard = () => {
    const { t } = useTranslation();
    const terminalId = useTerminalStore(useShallow(state => state.terminalId));

    const { locale } = useParams();

    return (
        <div
            className={cn(
                'relative flex h-full w-full flex-col rounded-lg border border-gray-300 bg-white py-4 pl-4 pr-8 shadow-card',
            )}
        >
            <div className="mb-6">
                <header>
                    <Title className="mds-font--display-1 mb-2">
                        {t(
                            `pages.settings.featureFlags.${FeatureFlagV1.VMT_INSTRUCTIONS}.QUAY_CRANE.title`,
                        )}
                    </Title>
                </header>
                <p className="mds-font--small">
                    {t(
                        `pages.settings.featureFlags.${FeatureFlagV1.VMT_INSTRUCTIONS}.QUAY_CRANE.description`,
                    )}
                </p>
            </div>

            <footer className="mt-auto">
                <Link to={routes.settings.featureFlags(terminalId, locale)}>
                    <FakeButton variant="secondary">{t('labels.configure')}</FakeButton>
                </Link>
            </footer>
        </div>
    );
};
