export default {
    appVersion: (typeof CONFIG_APP_VERSION !== 'undefined' && CONFIG_APP_VERSION) || 'unknown',
    backendApiUrl: () => {
        if (typeof CONFIG_BACKEND_URL !== 'undefined') {
            return CONFIG_BACKEND_URL;
        }
        throw new Error('Missing setting for backend api url');
    },
    showErrors: (typeof CONFIG_SHOW_ERRORS !== 'undefined' && CONFIG_SHOW_ERRORS) || false,
    isDev: (typeof CONFIG_IS_DEV !== 'undefined' && CONFIG_IS_DEV) || false,
};
