import clsx from 'clsx';
import type { HTMLAttributes } from 'react';

export const UtilityBar = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            className={clsx(
                className,
                'mds-font--small box-border flex h-14 items-center space-x-5 border-b border-gray-300 bg-gray-200 px-6 py-3',
            )}
            {...props}
        >
            {children}
        </div>
    );
};
