import { isDefined } from '@maersk-global/apmt-react-components/src/common/guards';
import type { ClassValue } from 'clsx';
import type { PropsWithChildren } from 'react';

import { cn } from '../../common/cn';

type menuItemProps = {
    text: string;
    className?: ClassValue;
    notificationCount?: number;
};

export const MenuItem = ({
    children,
    text,
    className,
    notificationCount,
}: PropsWithChildren<menuItemProps>) => {
    const showCount = isDefined(notificationCount) && notificationCount > 0;

    return (
        <div
            className={cn(
                'relative flex h-16 w-16 flex-col items-center justify-center text-current',
                className,
            )}
        >
            {showCount && (
                <div className="absolute right-[6px] top-1 size-4 rounded-full bg-blue-500 text-[8px] text-white">
                    {notificationCount > 99 ? '99+' : notificationCount}
                </div>
            )}
            {children}
            <span className="select-none">{text}</span>
        </div>
    );
};
