import { InstructionCycleType } from '@maersk-global/digital-pull-operating-system-spec';
import {
    array,
    boolean,
    Codec,
    date,
    enumeration,
    exactly,
    GetType,
    number,
    oneOf,
    string,
} from 'purify-ts';

import {
    FlowPositionName,
    WorkQueueKind,
    WorkQueueMode,
    WorkQueueStatus,
    WorkQueueType,
} from '@/constants/enums';
import { optionalNullToUndefined } from '@/types/util/optionalNullToUndefined';

export const moveInstructionDecoder = Codec.interface({
    truckId: string,
    instruction: enumeration(FlowPositionName),
    sentOn: string,
    estimatedMoveTime: optionalNullToUndefined(string),
    sequence: optionalNullToUndefined(number),
    hasReachedFlowPosition: boolean,
    isLocked: optionalNullToUndefined(boolean),
    expectedCraneTriggers: optionalNullToUndefined(number),
    handledCraneTriggers: optionalNullToUndefined(number),
});

export const dualCycleMoveInstructionDecoder = Codec.interface({
    truckId: string,
    instruction: enumeration(FlowPositionName),
    sentOn: string,
    estimatedMoveTime: optionalNullToUndefined(string),
    sequence: optionalNullToUndefined(number),
    hasReachedFlowPosition: boolean,
    isLocked: optionalNullToUndefined(boolean),
    cycleType: enumeration(InstructionCycleType),
    expectedCraneTriggers: optionalNullToUndefined(number),
    handledCraneTriggers: optionalNullToUndefined(number),
});
export type MoveInstruction = GetType<typeof moveInstructionDecoder>;
export type DualCycleMoveInstruction = GetType<typeof dualCycleMoveInstructionDecoder>;

export const moveInstructionsDecoder = array(moveInstructionDecoder);
export type MoveInstructions = GetType<typeof moveInstructionsDecoder>;

export const moveInstructionEventPayloadDecoder = Codec.interface({
    quayCraneName: string,
    moveInstructions: moveInstructionsDecoder,
});

export const spreaderActionDecoder = Codec.interface({
    lockOrUnlock: string,
    handledOn: string,
});
export type SpreaderAction = GetType<typeof spreaderActionDecoder>;

export const idsAndLoadTypeDecoder = Codec.interface({
    id: string,
    loadType: enumeration(WorkQueueType),
});

export const singleCycleWorkQueuesForQuayCraneDecoder = Codec.interface({
    kind: exactly(WorkQueueKind.SINGLECYCLE),
    id: string,
    name: string,
    loadType: enumeration(WorkQueueType),
    status: enumeration(WorkQueueStatus),
    loadMode: enumeration(WorkQueueMode),
    vesselName: string,
    vesselVisitId: string,
    sequence: number,
    instructions: array(moveInstructionDecoder),
    instructionsLeft: number,
    instructionsCompleted: number,
});
export type SingleCycleWorkQueuesForQuayCrane = GetType<
    typeof singleCycleWorkQueuesForQuayCraneDecoder
>;

export const dualCyclesWorkQueuesForQuayCraneDecoder = Codec.interface({
    kind: exactly(WorkQueueKind.DUALCYCLE),
    name: array(string),
    idsAndLoadType: array(idsAndLoadTypeDecoder),
    status: enumeration(WorkQueueStatus),
    loadMode: enumeration(WorkQueueMode),
    vesselName: string,
    vesselVisitId: string,
    sequence: number,
    instructions: array(dualCycleMoveInstructionDecoder),
    instructionsLeft: number,
    instructionsCompleted: number,
});
export type DualCyclesWorkQueuesForQuayCrane = GetType<
    typeof dualCyclesWorkQueuesForQuayCraneDecoder
>;

export const workQueuesForQuayCraneDecoder = oneOf([
    singleCycleWorkQueuesForQuayCraneDecoder,
    dualCyclesWorkQueuesForQuayCraneDecoder,
]);
export type WorkQueues = GetType<typeof workQueuesForQuayCraneDecoder>;

export const moveInstructionV2EventPayloadDecoder = Codec.interface({
    quayCraneName: string,
    isLongCrane: boolean,
    workQueues: array(workQueuesForQuayCraneDecoder),
    spreaderAction: optionalNullToUndefined(spreaderActionDecoder),
    consideredCompletedTruckNames: optionalNullToUndefined(array(string)),
    messageCreated: date,
});

export type MoveInstructionPayload = GetType<typeof moveInstructionV2EventPayloadDecoder>;

export const moveInstructionEventDecoder = Codec.interface({
    event: exactly('MOVE_INSTRUCTION_UPDATED'),
    data: moveInstructionEventPayloadDecoder,
});

export const moveInstructionV2EventDecoder = Codec.interface({
    event: exactly('MOVE_INSTRUCTION_UPDATED_V2'),
    data: moveInstructionV2EventPayloadDecoder,
});

export const flowPositionNameShortLabel = (flowPositionName: FlowPositionName) => {
    switch (flowPositionName) {
        case FlowPositionName.PULL:
            return 'pull';
        case FlowPositionName.STANDBY:
            return 'standby';
        case FlowPositionName.UNDER_CRANE:
            return 'under crane';
        default:
            // eslint-disable-next-line no-case-declarations
            const n: never = flowPositionName;
            throw new Error(`Exhaustiveness check failed [${n}]`);
    }
};

export const workInstructionDecoder = Codec.interface({
    workInstructionId: string,
    terminalTruckId: string,
    workQueueId: string,
    quayCraneId: string,
    moveStage: string,
    moveKind: string,
    moveInProgress: boolean,
    sourceDate: optionalNullToUndefined(string),
    updatedAt: string,
    consideredCompletedOn: optionalNullToUndefined(string),
    isoType: optionalNullToUndefined(string),
});

export type WorkInstruction = GetType<typeof workInstructionDecoder>;
export type LongCrane = {
    quayCraneName: string;
    sourceMessageCreatedAt: Date;
};
export type LongCranePerVesselVisit = {
    [vesselVisitId: string]: LongCrane;
};
export type MoveInstructionsPerQuayCrane = {
    [quayCraneName: string]: MoveInstructionsForQuayCrane;
};

export type MoveInstructionsForQuayCrane = Omit<
    MoveInstructionPayload,
    'isLongCrane' | 'messageCreated'
>;
export enum WorkType {
    FETCH = 'FETCH',
    PUT = 'PUT',
}
export enum AssignedPosition {
    YARD_BLOCK = 'YARD_BLOCK',
    STAGING = 'STAGING',
}
export enum YardTruckScopes {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}
export const interalJobDecoder = Codec.interface({
    type: exactly('InternalJob'),
    index: optionalNullToUndefined(number),
    fromPosition: string,
    toPosition: string,
    container: string,
    flowPosition: enumeration(FlowPositionName),
    work: enumeration(WorkType),
    serving: string,
    workQueueId: string,
    instructionSentOn: string,
    truckName: string,
});
export const truckHouseKeepingJobDecoder = Codec.interface({
    type: exactly('TruckHouseKeepingJob'),
    index: optionalNullToUndefined(number),
    fromPosition: string,
    toPosition: string,
    container: string,
    flowPosition: enumeration(FlowPositionName),
    instructionSentOn: string,
    work: enumeration(WorkType),
    truckName: string,
});
export const externalJobDecoder = Codec.interface({
    type: exactly('ExternalJob'),
    index: optionalNullToUndefined(number),
    fromPosition: string,
    toPosition: string,
    container: string,
    instructionSentOn: string,
    work: enumeration(WorkType),
    assignedPosition: enumeration(AssignedPosition),
    truckName: optionalNullToUndefined(string),
});
export const rtgHouseKeepingJobDecoder = Codec.interface({
    type: exactly('RTGHouseKeepingJob'),
    index: optionalNullToUndefined(number),
    fromPosition: string,
    toPosition: string,
    instructionSentOn: string,
    container: string,
    flowPosition: enumeration(FlowPositionName),
});
const jobWithFlowStatus = oneOf([
    interalJobDecoder,
    truckHouseKeepingJobDecoder,
    rtgHouseKeepingJobDecoder,
]);
export type JobWithFlowStatus = GetType<typeof jobWithFlowStatus>;

export enum CheStatus {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export const yardWorkInstructionsDecoder = Codec.interface({
    che: string,
    cheStatus: optionalNullToUndefined(enumeration(CheStatus)),
    workQueues: array(Codec.interface({ name: string, id: string })),
    jobs: array(
        oneOf([
            rtgHouseKeepingJobDecoder,
            externalJobDecoder,
            interalJobDecoder,
            truckHouseKeepingJobDecoder,
        ]),
    ),
});

export type YardWorkInstructions = GetType<typeof yardWorkInstructionsDecoder>;

export const yardWorkInstructionEventDecoder = Codec.interface({
    event: exactly('YARD_MOVE_INSTRUCTION_UPDATED'),
    data: yardWorkInstructionsDecoder,
});
