import { SharedSlugs, isActiveRoute } from '@maersk-global/apmt-dpos-common';
import { Table, TooltipProvider } from '@maersk-global/apmt-react-components';
import { SvgBucketMop } from '@maersk-global/apmt-react-icons';
import {
    FlowPositionEnum,
    InstructionCycleType,
} from '@maersk-global/digital-pull-operating-system-spec';
import type { Row, VisibilityState } from '@tanstack/react-table';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl/maplibre';
import { useLocation, useParams } from 'react-router-dom';
import { useShallow } from 'zustand/shallow';

import { triggerReset } from '@/api';
import { NoTruckInstructions } from '@/components/atoms/NoTruckInstructions/NoTruckInstructions';
import { FormattedInstruction } from '@/components/organisms/TruckInstructionsTable/FormattedInstruction';
import { FormattedTruckId } from '@/components/organisms/TruckInstructionsTable/FormattedTruckId';
import { TableCell } from '@/components/organisms/TruckInstructionsTable/TableCell';
import { TableHeader } from '@/components/organisms/TruckInstructionsTable/TableHeader';
import { useControlPageStore, useTruckPositionsStore } from '@/store';
import { flowPositionNameShortLabel, MoveInstruction, WorkQueues } from '@/types';
import { cn } from '@/utils/cn';
import { humanReadableTime } from '@/utils/dates';
import { shouldDisplayLock } from '@/utils/shouldDisplayLock';

import { FlowDetailDrawerButtonTooltip } from '../QuayCraneInstructions/QuayCraneInstructionsButtons';

export type InstructionsTableCellData = MoveInstruction & {
    cycleType: InstructionCycleType | undefined;
    isCompleted: boolean;
    formattedInstruction: [boolean | undefined, string];
};

export const TruckInstructionsTable = ({
    workQueue,
    consideredCompletedTruckNames,
    columnVisibilityOptions,
    isPaused = false,
    quayCraneName,
    terminalId,
    vesselVisitId,
}: {
    workQueue: WorkQueues;
    consideredCompletedTruckNames: string[];
    columnVisibilityOptions?: VisibilityState;
    isPaused: boolean;
    quayCraneName: string;
    terminalId: string;
    vesselVisitId: string;
}) => {
    const FLY_TO_ANIMATION_DURATION = 1000;

    const handleResetClicked = async (truckName: string) => {
        await triggerReset(terminalId, quayCraneName, vesselVisitId, [truckName]);

        // setSentResetCompleted(value => !value);

        setTimeout(() => {
            // setSentResetCompleted(value => !value);
        }, 1000);
    };
    const { t } = useTranslation();
    const { locale } = useParams();
    const { pathname } = useLocation();
    const isTerminalMapPage = isActiveRoute(pathname, SharedSlugs.terminalMap);
    const { default: map } = useMap();
    const truckPositions = useTruckPositionsStore(useShallow(state => state.truckPositions));
    const mapViewState = useControlPageStore(useShallow(state => state.mapViewState));
    const columnHelper = createColumnHelper<InstructionsTableCellData>();
    const defaultColumns = useMemo(
        () => [
            columnHelper.accessor('truckId', {
                header: () => (
                    <TableHeader title={t('pages.positions.detailDrawer.truckColumnTitle')} />
                ),
                cell: data => <FormattedTruckId data={data} isPaused={isPaused} />,
                size: 80,
            }),
            columnHelper.accessor('formattedInstruction', {
                enableSorting: true,
                sortingFn: (a, b) => {
                    return (
                        flowPositionOrder[a.original.instruction] -
                        flowPositionOrder[b.original.instruction]
                    );
                },
                header: () => (
                    <TableHeader title={t('pages.positions.detailDrawer.instructionColumnTitle')} />
                ),
                cell: data => <FormattedInstruction data={data} />,
                size: 120,
            }),
            columnHelper.accessor('sentOn', {
                header: () => (
                    <TableHeader title={t('pages.positions.detailDrawer.sentColumnTitle')} />
                ),
                cell: data => (
                    <TableCell className={`${data.row.original.isCompleted ? 'opacity-30' : ''}`}>
                        {data.getValue()}
                    </TableCell>
                ),
                size: 80,
            }),
            columnHelper.accessor('estimatedMoveTime', {
                enableSorting: true,
                header: () => (
                    <TableHeader title={t('pages.positions.detailDrawer.estimatedMoveTimeTitle')} />
                ),
                cell: data => (
                    <TableCell className={`${data.row.original.isCompleted ? 'opacity-30' : ''}`}>
                        {data.getValue()}
                    </TableCell>
                ),
            }),
            columnHelper.display({
                id: 'clearCompleted',
                header: () => <TableHeader title={'Clear'} />,
                cell: data =>
                    data.row.original.isCompleted ? (
                        <TableCell>
                            <TooltipProvider>
                                <FlowDetailDrawerButtonTooltip
                                    testId={`${data.row.original.truckId}-clearSingleTruck`}
                                    size="tiny"
                                    buttonIcon={<SvgBucketMop className="size-5" />}
                                    buttonLabel={t(
                                        'pages.positions.detailDrawer.removeCompletedButton.label',
                                    )}
                                    tooltipContent={t(
                                        'pages.positions.detailDrawer.removeCompletedButton.tooltip',
                                    )}
                                    loading={false}
                                    onClick={e => {
                                        void handleResetClicked(data.row.original.truckId);
                                        e.stopPropagation();
                                    }}
                                    showLabel={false}
                                />
                            </TooltipProvider>
                        </TableCell>
                    ) : null,
                size: 40,
            }),
        ],
        [],
    );

    const sortedInstructions: InstructionsTableCellData[] = [...workQueue.instructions].map(
        instruction => {
            const { estimatedMoveTime, sentOn, isLocked, instruction: flowPosition } = instruction;
            const isCompleted = consideredCompletedTruckNames.includes(instruction.truckId);
            const instructionTranslation = isCompleted
                ? t('pages.positions.detailDrawer.consideredCompleteInstruction')
                : t('pages.positions.detailDrawer.instruction', {
                      instruction: flowPositionNameShortLabel(instruction.instruction),
                  });
            return {
                ...instruction,
                isCompleted,
                formattedInstruction: [
                    shouldDisplayLock(flowPosition) && isLocked,
                    instructionTranslation,
                ],
                cycleType: 'cycleType' in instruction ? instruction.cycleType : undefined,
                sentOn: sentOn && humanReadableTime(sentOn, locale),
                estimatedMoveTime:
                    estimatedMoveTime && humanReadableTime(estimatedMoveTime, locale),
            };
        },
    );

    const flyToTruckOnMap = (truckId: string) => {
        const truck = truckPositions[truckId];

        if (!truck || !map) {
            return;
        }

        const { latitude, longitude } = truck;

        map.flyTo({
            center: [longitude, latitude],
            zoom: 18,
            duration: 2500,
            essential: true,
        });
    };

    const handleOnRowClicked = (row: Row<InstructionsTableCellData>) => {
        const { truckId } = row.original;
        flyToTruckOnMap(truckId);
    };
    const handleOnMouseLeaveCell = () => flyBackFromTruckToInitialMapState();

    const flyBackFromTruckToInitialMapState = () => {
        if (!map || !mapViewState) {
            return;
        }

        map.flyTo({
            center: [mapViewState.longitude, mapViewState.latitude],
            zoom: mapViewState.zoom,
            duration: FLY_TO_ANIMATION_DURATION,
            essential: true,
        });
    };

    if (sortedInstructions.length === 0) {
        return <NoTruckInstructions />;
    }

    return (
        <Table<InstructionsTableCellData>
            tableLayout="fixed"
            data={sortedInstructions}
            columns={defaultColumns}
            defaultVisibility={columnVisibilityOptions}
            rowClassName={cn({
                'text-gray-900 opacity-40': isPaused,
            })}
            defaultSorting={[
                {
                    id: 'formattedInstruction',
                    desc: true,
                },
            ]}
            {...(isTerminalMapPage && {
                onRowClicked: handleOnRowClicked,
            })}
            {...(isTerminalMapPage && {
                onMouseLeaveCell: handleOnMouseLeaveCell,
            })}
        />
    );
};
const flowPositionOrder = {
    [FlowPositionEnum.UNDER_CRANE]: 2,
    [FlowPositionEnum.STANDBY]: 1,
    [FlowPositionEnum.PULL]: 0,
} as const;
