import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { DelayCodeEventPayload } from '@/types/delayCodes';

export type DelayCodesPerQuayCrane = { [quayCraneName: string]: DelayCodeEventPayload };

type DelayCodesPerQuayCraneStore = {
    delayCodes: DelayCodesPerQuayCrane;
    setDelayCodes: (delayCodes: DelayCodeEventPayload) => void;
};

export const useDelayCodeStore = create<DelayCodesPerQuayCraneStore>()(
    devtools(
        set => {
            return {
                setDelayCodes: delayCodes => {
                    set(previousState => {
                        return {
                            ...previousState,
                            delayCodes: {
                                ...previousState.delayCodes,
                                [delayCodes.quayCraneId]: delayCodes,
                            },
                        };
                    });
                },
                delayCodes: {},
            };
        },
        { name: 'delayCodeStore' },
    ),
);
