import { applicationVersionDecoder } from '@/types/application';
import { safeDecode } from '@/utils/safeCodec';

export const getApplicationVersion = async () => {
    const response = await fetch('/version.json');
    const data = await response.json();

    return safeDecode(applicationVersionDecoder, data).caseOf({
        Left: e => {
            throw new Error(`Error fetching application version: ${e}`);
        },
        Right: r => r,
    });
};
