import { Maybe } from 'purify-ts';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { YardEquipmentLegend } from '@/components/organisms/YardEquipmentTable/YardEquipmentLegend';
import { YardEquipmentTable } from '@/components/organisms/YardEquipmentTable/YardEquipmentTable';
import { YardOverviewDrawer } from '@/components/organisms/YardOverviewDrawer/YardOverviewDrawer';
import { routes } from '@/routes/routes';
import { useTerminalStore } from '@/store';
import { useYardStore } from '@/store/yardStore';
import { CheStatus } from '@/types';

export const YardOverviewPage = () => {
    const { t } = useTranslation();
    const yardData = useYardStore(store => store.yardWorkInstructions);
    const terminalId = useTerminalStore(state => state.terminalId);
    const { equipmentName } = useParams();
    const navigate = useNavigate();

    const handleRowClick = (cheId: string) => {
        navigate(`${routes.yardOverview(terminalId)}/${cheId}`);
    };
    const noYardWork = Object.values(yardData).every(yardWork => yardWork.jobs.length === 0);
    if (noYardWork) {
        return (
            <div className="mx-auto w-full p-6">
                <span className="text-gray-900">
                    {t('pages.yard.noYardStackingEquipmentOverview')}
                </span>
            </div>
        );
    }
    const equipmentData = Maybe.fromNullable(equipmentName)
        .map(e => yardData[e])
        .extract();

    return (
        <div className="relative p-6">
            <YardEquipmentTable
                data={Object.values(yardData).filter(
                    yardWork => yardWork.cheStatus === CheStatus.ACTIVE,
                )}
                onRowClicked={handleRowClick}
            />
            <YardEquipmentLegend />
            <YardOverviewDrawer equipmentData={equipmentData} />
        </div>
    );
};
