import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiLockUnlocked(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            id="mi-lock-unlocked"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            {...props}
        >
            <path d="M14 2a1 1 0 0 0-1 1v2h3a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h8V3a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v.5h-1V3a1 1 0 0 0-1-1h-2zM4 6v10h12V6H4zm6 3a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 2.5 1.937V14h-1v-2.063A2 2 0 0 1 8 10z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiLockUnlocked);
export default ForwardRef;
