import { Maybe } from 'purify-ts';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import {
    FlowPositions,
    LongCrane,
    LongCranePerVesselVisit,
    MoveInstructionPayload,
    MoveInstructionsPerQuayCrane,
} from '@/types';
import { ApiResponseState } from '@/types/util/apiResponseState';

type QuayCraneStore = {
    flowPositions: ApiResponseState<FlowPositions>;
    workQueuesPerQuayCrane: MoveInstructionsPerQuayCrane;
    setDataPerQuayCrane: (message: MoveInstructionPayload) => void;
    longCranePerVesselVisit: LongCranePerVesselVisit;
};

export const useQuayCraneStore = create<QuayCraneStore>()(
    devtools(
        set => {
            return {
                flowPositions: {
                    standby: [],
                    underQuayCrane: [],
                    pointOfNoReturn: [],
                    quayArea: [],
                },
                stackingEquipment: {},
                longCranePerVesselVisit: {},
                setDataPerQuayCrane: ({
                    quayCraneName,
                    workQueues,
                    isLongCrane,
                    spreaderAction,
                    messageCreated,
                    consideredCompletedTruckNames = [],
                }: MoveInstructionPayload) => {
                    set(previousState => {
                        const newState = {
                            ...previousState,
                            workQueuesPerQuayCrane: {
                                ...previousState.workQueuesPerQuayCrane,
                                [quayCraneName]: {
                                    quayCraneName,
                                    workQueues,
                                    consideredCompletedTruckNames,
                                    isLongCrane,
                                    spreaderAction,
                                    messageCreated,
                                },
                            },
                        };

                        const vesselVisitIdM = Maybe.fromNullable(workQueues[0]?.vesselVisitId);

                        // Only update when long crane when message contains a vesselVisitId
                        return vesselVisitIdM.caseOf({
                            Just: vesselVisit => {
                                const previousLongCrane = vesselVisitIdM.chainNullable(
                                    vesselVisitId => {
                                        return previousState.longCranePerVesselVisit[vesselVisitId];
                                    },
                                );
                                const currentMessageQuayCrane = quayCraneName;
                                const currentMessageIsLongCrane = isLongCrane;
                                const newLongCrane = Maybe.fromPredicate(
                                    () => currentMessageIsLongCrane,
                                    {
                                        quayCraneName,
                                        sourceMessageCreatedAt: messageCreated,
                                    },
                                );

                                const newLongCraneOfVessel = previousLongCrane.caseOf({
                                    Nothing: newLongCraneIfPreviousNotSet(newLongCrane),
                                    Just: () =>
                                        deletePreviousLongCraneWhenCurrentMessageUnsets(
                                            previousLongCrane,
                                            currentMessageIsLongCrane,
                                            currentMessageQuayCrane,
                                        )
                                            .alt(
                                                pickNewestLongCrane(
                                                    previousLongCrane,
                                                    newLongCrane,
                                                ),
                                            )
                                            .extract(),
                                });

                                if (newLongCraneOfVessel === undefined) {
                                    delete newState.longCranePerVesselVisit[vesselVisit];
                                } else {
                                    newState.longCranePerVesselVisit[vesselVisit] =
                                        newLongCraneOfVessel;
                                }

                                return newState;
                            },
                            Nothing: () => {
                                return newState;
                            },
                        });
                    });
                },
                workQueuesPerQuayCrane: {},
            };
        },
        { name: 'quayCranesStore' },
    ),
);

function deletePreviousLongCraneWhenCurrentMessageUnsets(
    previousLongCrane: Maybe<LongCrane>,
    isLongCrane: boolean,
    currentMessageQuayCrane: string,
) {
    return previousLongCrane
        .filter(() => !isLongCrane)
        .map<LongCrane | undefined>(value => deleteLongCraneIfSame(currentMessageQuayCrane, value));
}

function pickNewestLongCrane(previousLongCrane: Maybe<LongCrane>, newLongCrane: Maybe<LongCrane>) {
    return previousLongCrane.chain(previous => {
        return newLongCrane.map(newLC =>
            newLC.sourceMessageCreatedAt > previous.sourceMessageCreatedAt ? newLC : previous,
        );
    });
}

function deleteLongCraneIfSame(
    currentMessageQuayCrane: string,
    previousLongCrane: LongCrane,
): LongCrane | undefined {
    return isSameQC(currentMessageQuayCrane, previousLongCrane) ? undefined : previousLongCrane;
}

const isSameQC = (currentMessageQuayCrane: string, previousLongCrane: LongCrane) =>
    previousLongCrane.quayCraneName === currentMessageQuayCrane;

const newLongCraneIfPreviousNotSet = (newLongCrane: Maybe<LongCrane>) => () =>
    newLongCrane.extract();
