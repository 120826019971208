import { useShallow } from 'zustand/shallow';

import { useQuayCraneStore } from '@/store';

export const useGetWorkQueuesPerQuayCrane = (quayCraneName: string) => {
    const workQueuesPerQuayCrane = useQuayCraneStore(
        useShallow(state => state.workQueuesPerQuayCrane),
    );

    return workQueuesPerQuayCrane[quayCraneName];
};
