import { SVGProps } from 'react';
import { Layer, LngLatBounds, Marker, Source } from 'react-map-gl/maplibre';

import { Coordinate } from '@/types';

export const TerminalMapDebug = ({
    bounds,
    polys,
}: {
    bounds?: LngLatBounds[];
    polys?: Coordinate[][];
}) => {
    const pinStyle = {
        cursor: 'pointer',
        stroke: 'none',
    };

    const markerColors = ['red', 'green', 'blue', 'orange', 'magenta'];
    const geoColors = ['#ff0000', '#00ff00', '#0000ff'];

    const geoJsonBoundsRectangles: GeoJSON.FeatureCollection[] | undefined = bounds?.map(
        (bounds, index) => ({
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    properties: {
                        name: `Fit bounds rectangle ${index}`,
                    },
                    geometry: {
                        type: 'MultiPolygon',
                        coordinates: [
                            [
                                [
                                    bounds.getSouthWest().toArray(),
                                    bounds.getNorthWest().toArray(),
                                    bounds.getNorthEast().toArray(),
                                    bounds.getSouthEast().toArray(),
                                    bounds.getSouthWest().toArray(),
                                ],
                            ],
                        ],
                    },
                },
            ],
        }),
    );

    const geoJsonPolygons: GeoJSON.FeatureCollection[] | undefined = polys?.map((poly, index) => ({
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',
                properties: {
                    name: `Polygon ${index}`,
                },
                geometry: {
                    type: 'MultiPolygon',
                    coordinates: [[poly.map(({ longitude, latitude }) => [longitude, latitude])]],
                },
            },
        ],
    }));

    return (
        <>
            {bounds &&
                bounds.map((bounds, boundsIndex) => {
                    return [
                        bounds.getSouthWest(),
                        bounds.getNorthWest(),
                        bounds.getNorthEast(),
                        bounds.getSouthEast(),
                    ].map((coord, index) => {
                        return (
                            <Marker
                                key={`${boundsIndex}_${index}`}
                                longitude={coord.lng}
                                latitude={coord.lat}
                                color={markerColors[index]}
                                anchor={'center'}
                                style={{
                                    width: 28,
                                    height: 28,
                                }}
                            >
                                <span className="mds-font--display-1 absolute -top-1 left-1 font-bold text-white">
                                    {index}
                                </span>
                                <Pin {...{ ...pinStyle, fill: markerColors[index] }} />
                            </Marker>
                        );
                    });
                })}
            {polys &&
                polys.map((poly, polyIndex) => {
                    return poly.map((coord, index) => {
                        return (
                            <Marker
                                key={`${polyIndex}_${index}`}
                                longitude={coord.longitude}
                                latitude={coord.latitude}
                                color={markerColors[index]}
                                anchor={'center'}
                                style={{
                                    width: 28,
                                    height: 28,
                                }}
                            >
                                <span className="mds-font--display-1 absolute -top-1 left-1 font-bold text-white">
                                    {index}
                                </span>
                                <Pin {...{ ...pinStyle, fill: markerColors[index] }} />
                            </Marker>
                        );
                    });
                })}
            {geoJsonBoundsRectangles?.map((boundsRectangle, index) => {
                return (
                    <Source type="geojson" data={boundsRectangle} key={`bounds-${index}`}>
                        <Layer
                            id={`geoJsonMapBoundariesRectangle_${index}`}
                            type="line"
                            paint={{
                                'line-color': geoColors[index % geoColors.length],
                                'line-opacity': 1,
                                'line-width': 2,
                                'line-dasharray': [2, 2],
                            }}
                        />
                    </Source>
                );
            })}
            {geoJsonPolygons?.map((poly, index) => {
                return (
                    <Source type="geojson" data={poly} key={`poly-${index}`}>
                        <Layer
                            id={`geoJsonPolygon_${index}`}
                            type="line"
                            paint={{
                                'line-color': geoColors[index % geoColors.length],
                                'line-opacity': 1,
                                'line-width': 2,
                                'line-dasharray': [2, 2],
                            }}
                        />
                    </Source>
                );
            })}
        </>
    );
};

const ICON = `M20.2,15.7L20.2,15.7c1.1-1.6,1.8-3.6,1.8-5.7c0-5.6-4.5-10-10-10S2,4.5,2,10c0,2,0.6,3.9,1.6,5.4c0,0.1,0.1,0.2,0.2,0.3
  c0,0,0.1,0.1,0.1,0.2c0.2,0.3,0.4,0.6,0.7,0.9c2.6,3.1,7.4,7.6,7.4,7.6s4.8-4.5,7.4-7.5c0.2-0.3,0.5-0.6,0.7-0.9
  C20.1,15.8,20.2,15.8,20.2,15.7z`;

const Pin = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg height={20} viewBox="0 0 24 24" {...props}>
            <path d={ICON} />
        </svg>
    );
};
