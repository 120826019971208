/* istanbul ignore file */
import { forwardRef, SVGProps } from 'react';
interface CustomIconProps extends SVGProps<SVGSVGElement> {
    size?: number;
}
const RtgIconIcon = forwardRef<SVGSVGElement, CustomIconProps>(({ size, ...props }, svgRef) => {
    if (size) {
        props.width = size;
        props.height = size;
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 269.5 141.5" ref={svgRef} {...props}>
            <path
                fill="currentColor"
                d="M22.672 123.9h224.294v2H22.672zm106.3-57.59l15.215-11.607.607.795-15.215 11.607zm4.568-8.055l11.222-8.692.613.79-11.222 8.693zm-39.891 55.644h14.267v2H93.649z"
            />
            <path
                fill="currentColor"
                d="M104.601 124.544h-7.638v-10.27h7.638zm-5.638-2h3.638v-6.27h-3.638zm98.027-98.322H90.12v-12.46h106.87zm-104.87-2h102.87v-8.46H92.12z"
            />
            <path
                fill="currentColor"
                d="M93.92 23.993l2-.038 1.742 91.3-2 .039zm9.397 91.228l4.888-91.3 1.997.107-4.888 91.3zm74.324-1.731h14.267v2h-14.267z"
            />
            <path
                fill="currentColor"
                d="M188.593 124.135h-7.638v-10.269h7.638zm-5.638-2h3.638v-6.269h-3.638z"
            />
            <path
                fill="currentColor"
                d="M187.896 114.847l1.74-91.3 1.999.038-1.74 91.3zm-12.541-91.228l1.997-.107 4.887 91.3-1.997.107zM89.284 3.992h107.581v1H89.284z"
            />
            <path
                fill="currentColor"
                d="M94.798 4.492h1v8.269h-1zm12.028 0h1v8.269h-1zm12.028 0h1v8.269h-1zm12.028 0h1v8.269h-1zm12.028 0h1v8.269h-1zm12.029 0h1v8.269h-1zm12.028 0h1v8.269h-1zm12.028 0h1v8.269h-1zm12.028 0h1v8.269h-1zm-36.085 26.496h-22.421l-2.684-6.927 1.865-.722 2.189 5.649h19.605l1.887-5.704 1.899.628-2.34 7.076z"
            />
            <path fill="currentColor" d="M134.843 30.336h1v19.099h-1zm16.424 0h1v19.099h-1z" />
            <path
                fill="currentColor"
                d="M158.325 82.619h-29.54V49.098h29.54zm-27.54-2h25.54V51.098h-25.54z"
            />
        </svg>
    );
});
export default RtgIconIcon;
