import { Just, Maybe, NonEmptyList } from 'purify-ts';
import { useTranslation } from 'react-i18next';

import { FlowPositionTruckBubbleContainer } from '@/components/atoms/FlowPositionTruckBubbleContainer/FlowPositionTruckBubbleContainer';
import { TruckBubble } from '@/components/atoms/TruckBubbles/TruckBubble';
import { FlowPosition, getTruckAppearance } from '@/components/molecules/FlowPosition/FlowPosition';
import { QuayCranePosition } from '@/components/molecules/QuayCranePosition/QuayCranePosition';
import {
    YardEquipmentCompact,
    YardWorkForVisualisation,
} from '@/components/molecules/YardEquipment/YardEquipmentCompact';
import { EquipmentRow } from '@/components/organisms/FlowVisualisation/EquipmentRow';
import { FlowDetailBackgroundLine } from '@/components/organisms/FlowVisualisation/FlowVisualisationBackgroundLine';
import {
    CheType,
    FlowPositionName,
    FlowStatusOption,
    FlowVisualisationVariant,
} from '@/constants/enums';
import { AssignedPosition, JobWithFlowStatus, MoveInstructions } from '@/types';
import { Andons } from '@/types/andons';
import { calculatePositionBackgroundColorYard } from '@/utils/calculateFlowPositionBackground';
import { cn } from '@/utils/cn';
import { yardStatusForYardWork } from '@/utils/getFlowStatus';

import { filterDuplicateTruckNames } from '../YardEquipmentTable/YardEquipmentTable';

export const FlowVisualisationSmall = ({
    quayCraneName,
    flowStatus,
    instructions,
    andons = [],
    yardWork,
}: {
    quayCraneName: string;
    flowStatus: FlowStatusOption;
    instructions: MoveInstructions;
    andons: Andons;
    yardWork: YardWorkForVisualisation;
}) => {
    const { t } = useTranslation();
    const variant = FlowVisualisationVariant.SMALL;

    const trucksInStandby = instructions
        .filter(({ instruction }) => instruction === FlowPositionName.STANDBY)
        .map(truck => {
            return {
                truckId: truck.truckId,
                appearance: getTruckAppearance(truck.instruction, truck.hasReachedFlowPosition),
            };
        });

    const trucksUnderCrane = instructions.filter(
        ({ instruction }) => instruction === FlowPositionName.UNDER_CRANE,
    );

    const trucksInPull = instructions
        .filter(({ instruction }) => instruction === FlowPositionName.PULL)
        .map(truck => {
            return {
                truckId: truck.truckId,
                appearance: getTruckAppearance(truck.instruction, truck.hasReachedFlowPosition),
            };
        });

    const isStopped = flowStatus === 'STOPPED';
    return (
        <div className="mt-16 font-sansNumeric">
            <FlowDetailBackgroundLine
                flowStatus={flowStatus}
                variant={variant}
                className={cn({
                    'min-h-[132px]': yardWork.yardStackingEquipment.length === 0,
                })}
            >
                <div
                    className={cn(
                        'relative z-20 -mr-6 -mt-14 grid grid-cols-flow_detail_cols_small grid-rows-flow_detail_rows_small',
                        {
                            'border-gray-400': !isStopped,
                            'border-feedback-danger': isStopped,
                        },
                    )}
                >
                    {/* ROW 1 */}
                    <EquipmentRow
                        fifthCell={
                            <QuayCranePosition
                                name={quayCraneName}
                                trucks={trucksUnderCrane}
                                variant={variant}
                                flowStatus={flowStatus}
                                andon={andons.some(andon => andon.cheType === CheType.QUAY_CRANE)}
                            />
                        }
                    />

                    {/* ROW 2 */}
                    <EquipmentRow
                        thirdCell={
                            <div className="relative">
                                <div className="absolute top-[-68px] w-full">
                                    <FlowPosition
                                        singleTag={trucksInPull.length === 1}
                                        trucks={trucksInPull}
                                        label={t('labels.positions.pull')}
                                        variant={variant}
                                        flowStatus={flowStatus}
                                        id={FlowPositionName.PULL}
                                    />
                                </div>
                            </div>
                        }
                        fourthCell={
                            <div className="relative">
                                <div className="absolute top-[-68px] w-full">
                                    <FlowPosition
                                        singleTag={trucksInStandby.length === 1}
                                        trucks={trucksInStandby}
                                        label={t('labels.positions.standby')}
                                        variant={variant}
                                        flowStatus={flowStatus}
                                        id={FlowPositionName.STANDBY}
                                    />
                                </div>
                            </div>
                        }
                    />

                    <EquipmentRow
                        fourthCell={<YardEquipmentCompact andons={andons} yardWork={yardWork} />}
                    />
                </div>
            </FlowDetailBackgroundLine>
            <div className="mt-3">
                {yardWork.yardStackingEquipment.map(equipment => {
                    const jobs = equipment.jobs.filter(filterDuplicateTruckNames);
                    const hasAndon = andons.some(andon => equipment.cheId === andon.cheName);
                    const jobsWithFlowStatus = jobs.filter(
                        (job): job is JobWithFlowStatus => job.type !== 'ExternalJob',
                    );

                    const flowStatus = yardStatusForYardWork(jobsWithFlowStatus);
                    const pullTrucks = jobs
                        .filter(job => {
                            if (job.type === 'ExternalJob') {
                                return job.assignedPosition === AssignedPosition.STAGING;
                            }

                            return (
                                job.flowPosition === FlowPositionName.PULL &&
                                job.type !== 'RTGHouseKeepingJob'
                            );
                        })
                        .map((t, i, list) => {
                            const pullAmount =
                                i >= 5 ? 'TooMany' : list.length > 2 ? 'Stretch' : 'Fits';
                            const last = i === list.length - 1;
                            const first = i === 0;
                            const isRelevant =
                                t.type === 'InternalJob' && t.serving === quayCraneName;
                            const truckBubble = (
                                <TruckBubble
                                    key={t.container}
                                    testid={`${FlowPositionName.PULL}-${t.container}`}
                                    className={cn(
                                        {
                                            'mr-1': !last,
                                            'opacity-20': !isRelevant,
                                        },
                                        ' flex flex-auto grow-0 shrink-0 bg-gray-200',
                                    )}
                                    truck={t}
                                />
                            );
                            const bg = calculatePositionBackgroundColorYard(flowStatus);
                            switch (pullAmount) {
                                case 'TooMany':
                                    return <span key={t.container}></span>;
                                case 'Stretch':
                                    // When there are more bubbles than fit, they need to come with there own background
                                    return (
                                        <div
                                            key={t.container}
                                            className={cn(
                                                bg,
                                                {
                                                    'rounded-l': first,
                                                    'rounded-r pr-2': last,
                                                },
                                                'h-full  flex items-center',
                                            )}
                                        >
                                            {truckBubble}
                                        </div>
                                    );
                                case 'Fits':
                                    return truckBubble;
                            }
                        });
                    const pullTrucksM = NonEmptyList.fromArray(pullTrucks);
                    const pullAmount = pullTrucksM
                        .map(trucks =>
                            trucks.length > 5 ? 'TooMany' : trucks.length > 2 ? 'Stretch' : 'Fits',
                        )
                        .orDefault('Fits');
                    const pullTrucksM2 = pullTrucksM.map(bubbles => {
                        if (bubbles.length >= 5) {
                            const leftOver = bubbles.length - 5;
                            return (
                                <>
                                    {bubbles}{' '}
                                    <div className="flex flex-row flex-nowrap whitespace-nowrap rounded-r bg-feedback-info-weak pr-2">
                                        + {leftOver}
                                    </div>
                                </>
                            );
                        }
                        return <>{bubbles}</>;
                    });
                    const standbyTrucks = jobs
                        .filter(
                            job =>
                                job.type !== 'RTGHouseKeepingJob' &&
                                job.type !== 'ExternalJob' &&
                                job.flowPosition === FlowPositionName.STANDBY,
                        )
                        .map(t => {
                            const isRelevant =
                                t.type === 'InternalJob' && t.serving === quayCraneName;
                            return (
                                <TruckBubble
                                    testid={`${FlowPositionName.STANDBY}-${t.container}`}
                                    key={t.container}
                                    className={cn({ 'opacity-20': !isRelevant })}
                                    truck={t}
                                />
                            );
                        });
                    const standbyTruckM = Maybe.fromNullable(standbyTrucks[0]);
                    const underCraneTrucks = jobs
                        .filter(job => {
                            if (job.type === 'ExternalJob') {
                                return job.assignedPosition === AssignedPosition.YARD_BLOCK;
                            }
                            return (
                                job.flowPosition === FlowPositionName.UNDER_CRANE &&
                                job.type !== 'RTGHouseKeepingJob'
                            );
                        })

                        .map(t => {
                            const isRelevant =
                                t.type === 'InternalJob' && t.serving === quayCraneName;

                            return (
                                <TruckBubble
                                    testid={`${FlowPositionName.UNDER_CRANE}-${t.container}`}
                                    key={t.container}
                                    className={cn({ 'opacity-20': !isRelevant })}
                                    truck={t}
                                />
                            );
                        });
                    const underCraneTruckM = NonEmptyList.fromArray(underCraneTrucks).map(
                        bubbles => <>{bubbles}</>,
                    );
                    return (
                        <FlowPositionTruckBubbleContainer
                            className="mt-1"
                            flowStatus={flowStatus}
                            key={equipment.cheId}
                            hasAndon={hasAndon}
                            pullAmount={pullAmount}
                            equipment={Just(equipment.cheId)}
                            pull={pullTrucksM2}
                            standby={standbyTruckM}
                            under={underCraneTruckM}
                        />
                    );
                })}
            </div>
        </div>
    );
};
