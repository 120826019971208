import { SharedCookieNames } from '@maersk-global/apmt-dpos-common';
import { useAuth } from '@maersk-global/apmt-flow-keycloak';
import { Card, Title, FakeButton } from '@maersk-global/apmt-react-components';
import { FeatureFlagV1 } from '@maersk-global/digital-pull-operating-system-spec';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useCookie } from 'react-use';

import { GridItem } from '@/components/atoms/GridItem/GridItem';
import { GridOfCards } from '@/components/atoms/GridOfCards/GridOfCards';
import { FeatureFlagCard } from '@/components/organisms/Cards/FeatureFlagCard/FeatureFlagCard';
import { SettingsCard } from '@/components/organisms/Cards/SettingsCard/SettingsCard';
import { CookieNames } from '@/constants/cookies';
import { routes } from '@/routes/routes';
import { useTerminalStore } from '@/store/terminalStore';
const craneLocalFlags = [FeatureFlagV1.NO_AD_TRIGGERS, FeatureFlagV1.OPPOSITE_DRIVING_DIRECTIONS];
export const SettingsPage = () => {
    const { t } = useTranslation();
    const { user } = useAuth();
    const setPageTitle = useTerminalStore(state => state.setPageTitle);
    const frontendFeatureFlags = Object.values(SharedCookieNames);
    const [gateSettingsEnabled] = useCookie(CookieNames.GateEnabled);
    const { locale = 'en' } = useParams<{ locale: string }>();

    const featureFlagOnlyAdminAccess = [FeatureFlagV1.VMT_INSTRUCTIONS];
    const backendFeatureFlags = Object.values(FeatureFlagV1).filter(
        flag =>
            !craneLocalFlags.includes(flag) &&
            (!featureFlagOnlyAdminAccess.includes(flag) || user.isAdmin),
    );

    useEffect(() => {
        setPageTitle(t('pages.settings.pageTitle'));
    }, [setPageTitle]);

    return (
        <div className="mx-auto size-full p-6">
            <GridOfCards>
                {frontendFeatureFlags.map(flag => {
                    return (
                        <GridItem key={flag}>
                            <SettingsCard
                                title={t(`pages.settings.featureFlags.${flag}.title`)}
                                description={t(`pages.settings.featureFlags.${flag}.description`)}
                                cookieName={flag}
                            />
                        </GridItem>
                    );
                })}
                {backendFeatureFlags &&
                    backendFeatureFlags.map(flag => {
                        return (
                            <GridItem key={flag}>
                                <FeatureFlagCard />
                            </GridItem>
                        );
                    })}
                {gateSettingsEnabled && (
                    <GridItem>
                        <GateSettingsCard
                            title={t(`pages.settings.workstationSettings.title`)}
                            description={t(`pages.settings.workstationSettings.description`)}
                            buttonText={'Configure'}
                            link={routes.gate.workstationSettings(locale)}
                        />
                    </GridItem>
                )}
                {gateSettingsEnabled && (
                    <GridItem>
                        <GateSettingsCard
                            title={t(`pages.settings.scenarioSettings.title`)}
                            description={t(`pages.settings.scenarioSettings.description`)}
                            buttonText={'Configure'}
                            link={routes.gate.scenarioSettings(locale)}
                        />
                    </GridItem>
                )}
                <GridItem>
                    <SettingsCard
                        title={t(`pages.settings.gateEnabled.title`)}
                        description={t(`pages.settings.gateEnabled.description`)}
                        cookieName={CookieNames.GateEnabled}
                    />
                </GridItem>
                {user.isAdmin && (
                    <>
                        <GridItem>
                            <SettingsCard
                                title={t(`pages.settings.debugMode.title`)}
                                description={t(`pages.settings.debugMode.description`)}
                                cookieName={CookieNames.DebugMode}
                            />
                        </GridItem>
                        <GridItem>
                            <SettingsCard
                                title={'SSE logs'}
                                description={
                                    'Show logs from SSE connections in the browser console.'
                                }
                                cookieName={CookieNames.SseLogs}
                            />
                        </GridItem>
                        <GridItem>
                            <SettingsCard
                                title={'TOS only crane triggers'}
                                description={'Show toggle to enable TOS only crane triggers'}
                                cookieName={CookieNames.TosOnlyCraneTriggers}
                            />
                        </GridItem>
                    </>
                )}
            </GridOfCards>
        </div>
    );
};
interface GateSettingsCardProps {
    title: string;
    description: string;
    buttonText: string;
    link: string;
}
const GateSettingsCard = (props: GateSettingsCardProps) => {
    const { title, description, buttonText, link } = props;
    return (
        <Card className="gap-2">
            <div className="mb-6">
                <header>
                    <Title className="mds-font--display-1">{title}</Title>
                </header>
                <p className="mds-font--small">{description}</p>
            </div>

            <footer className="mt-auto">
                <Link to={link} reloadDocument={true}>
                    <FakeButton variant="secondary">{buttonText}</FakeButton>
                </Link>
            </footer>
        </Card>
    );
};
