import { FlowStatusOption } from '@/constants/enums';

export const calculatePositionBackgroundColor = (
    flowStatus: FlowStatusOption,
    numberOfChildren: number,
) => {
    const isOverDeployed = flowStatus === 'OVER_DEPLOYED';
    const isStopped = flowStatus === 'STOPPED';
    const isAtRisk = flowStatus === 'RISK';

    const overDeployedPosition = isOverDeployed && numberOfChildren >= 2;
    const stoppedPosition = isStopped && numberOfChildren < 1;
    const atRiskPosition = isAtRisk && numberOfChildren < 1;

    const defaultBackgroundColor = 'bg-gray-200';

    if (overDeployedPosition) {
        return 'bg-blue-600 text-white';
    }

    if (stoppedPosition) {
        return 'bg-feedback-danger text-white';
    }

    if (atRiskPosition) {
        return 'bg-feedback-warning';
    }

    return defaultBackgroundColor;
};
export const calculatePositionBackgroundColorYard = (flowStatus: FlowStatusOption) => {
    switch (flowStatus) {
        case 'ACTIVE':
            return 'bg-gray-200';
        case 'OVER_DEPLOYED':
            return 'bg-feedback-info-weak';
        case 'RISK':
            return 'bg-feedback-warning-weak';
        case 'STOPPED':
            return 'bg-feedback-danger-weak';
        case 'UNKNOWN':
            return 'bg-gray-200';
    }
};
