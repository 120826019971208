import { forwardRef } from "react";
import type { SVGProps, Ref } from "react";
function SvgMiExclamationTriangleSolid(
    props: SVGProps<SVGSVGElement>,
    ref: Ref<SVGSVGElement>
) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 20 20"
            ref={ref}
            {...props}
        >
            <path d="M9.283 2a1 1 0 0 0-.857.486L1.234 14.47a1 1 0 0 0 .026 1.07l.676 1.014a1 1 0 0 0 .832.445h14.465a1 1 0 0 0 .832-.445l.676-1.014a1 1 0 0 0 .025-1.07L11.575 2.485A1 1 0 0 0 10.717 2H9.283zm.217 9V7h1v4h-1zm-.25 3v-1.5h1.5V14h-1.5z" />
        </svg>
    );
}
const ForwardRef = forwardRef(SvgMiExclamationTriangleSolid);
export default ForwardRef;
