import { SvgExclamationOctagon } from '@maersk-global/apmt-react-icons';
import clsx from 'clsx';
import type { PropsWithChildren } from 'react';
import React from 'react';

import { CriticalAlertProvider, useCriticalAlert } from '../CriticalAlertBanner';

export type LayoutProps = PropsWithChildren<{
    icon?: React.ReactNode;
    navigation: React.ReactNode;
    header: React.ReactNode;
    children: React.ReactNode;
}>;

const LayoutInner = ({ icon, navigation, header, children }: LayoutProps) => {
    const { criticalAlert } = useCriticalAlert();

    return (
        <>
            {criticalAlert && (
                <div
                    className="flex h-14 shrink-0 items-center bg-feedback-danger-light pl-[22px]"
                    role="alert"
                >
                    <span className="pr-4">
                        <SvgExclamationOctagon className="text-xl" />
                    </span>
                    {criticalAlert}
                </div>
            )}
            <div className="box-border flex h-16 shrink-0 flex-row items-center border-b border-gray-300 bg-white">
                {/* Top left icon slot */}
                <div className="box-border flex size-16 flex-col items-center justify-center border-r border-gray-300 text-gray-900">
                    {icon}
                </div>
                {/* header row */}
                <div className="grow">{header}</div>
            </div>
            <div
                className={clsx('flex flex-row', {
                    'h-screen-min-navigation': !criticalAlert,
                    'h-screen-min-navigation-and-alert': criticalAlert,
                })}
            >
                <div className="box-border w-16 shrink-0 border-r border-gray-300 bg-white">
                    {/* navigation rail */}
                    {navigation}
                </div>
                {/* Application body */}
                <div className="h-full w-screen-min-navigation grow overflow-auto">{children}</div>
            </div>
        </>
    );
};

export const Layout = ({ children, ...props }: PropsWithChildren<LayoutProps>) => {
    return (
        <div className="flex h-screen flex-col bg-gray-100">
            <CriticalAlertProvider>
                <LayoutInner {...props}>{children}</LayoutInner>
            </CriticalAlertProvider>
        </div>
    );
};
