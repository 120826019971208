import { PropsWithChildren } from 'react';

import { cn } from '@/utils/cn';

interface GridItemProps {
    as?: keyof HTMLElementTagNameMap;
    className?: string;
}

export const GridItem = ({ as = 'li', children, className }: PropsWithChildren<GridItemProps>) => {
    const Tag = as;

    return <Tag className={cn(className)}>{children}</Tag>;
};
