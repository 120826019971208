import { FlowStatusOption } from '@/constants/enums';
type Base = {
    className?: string;
    label: string | React.ReactNode;
};
export type CustomSquareTagColor = Base & { backgroundColor?: string };
export type FlowStatusSquareTag = Base & { flowStatus: FlowStatusOption };

export const SquareTag = (props: CustomSquareTagColor | FlowStatusSquareTag) => {
    const { label, className } = props;
    const backgroundColor = isCustomColor(props)
        ? props.backgroundColor ?? 'bg-gray-100'
        : getFlowStatusColor(props.flowStatus);
    return (
        <div
            className={`rounded p-1 text-center font-mono text-sm ${backgroundColor} ${
                className ?? ''
            }`}
        >
            {label}
        </div>
    );
};

const isCustomColor = (
    input: CustomSquareTagColor | FlowStatusSquareTag | unknown,
): input is CustomSquareTagColor => {
    // eslint-disable-next-line no-prototype-builtins
    return typeof input === 'object' && input !== null && !input.hasOwnProperty('flowStatus');
};

const getFlowStatusColor = (flowStatus: FlowStatusOption) => {
    switch (flowStatus) {
        case 'ACTIVE':
        case 'UNKNOWN':
            return 'bg-gray-100';
        case 'OVER_DEPLOYED':
            return 'bg-feedback-info-weak';
        case 'RISK':
            return 'bg-feedback-warning-weak';
        case 'STOPPED':
            return 'bg-feedback-danger-weak';
    }
};
