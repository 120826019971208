import type { ComponentPropsWithRef, PropsWithChildren } from 'react';
import { forwardRef } from 'react';

import { cn } from '../common/cn';

type ComponentProps = ComponentPropsWithRef<'div'>;
type CardProps = ComponentProps & {
    className?: string;
};

export const Card = forwardRef<HTMLDivElement, PropsWithChildren<CardProps>>(
    ({ children, className, ...props }, ref) => {
        return (
            <div
                ref={ref}
                className={cn(
                    'shadow-card relative flex h-full w-full flex-col rounded-lg border bg-white p-4',
                    className,
                )}
                {...props}
            >
                {children}
            </div>
        );
    },
);
