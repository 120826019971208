import type { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import { forwardRef } from 'react';

import { cn } from '../common/cn';

export const buttonVariantsClassNames = {
    primary: 'bg-blue-900 text-white hover:bg-blue-800 focus:bg-blue-800 active:bg-gray-900',
    primaryOutlined: 'border-blue-900 border text-blue-900 hover:bg-blue-25 active:bg-blue-200',
    secondary: 'bg-white border border-gray-400 text-gray-900 hover:bg-gray-200 active:bg-gray-400',
    plain: 'bg-transparent text-gray-900 hover:bg-gray-200 focus:bg-gray-400 active:bg-gray-400',
    // TODO: separate alt into its own appearance type
    plainAlt: 'bg-transparent text-blue-600 hover:bg-gray-200 focus:bg-gray-400 active:bg-gray-400',
    delete: 'bg-feedback-danger text-white hover:bg-red-800 focus:bg-red-800 active:bg-gray-900',
    deleteSecondary: 'border-red-700 border text-red-700 hover:bg-gray-200 active:bg-gray-900',
    link: 'bg-transparent text-blue-600 pointer hover:underline',
} as const;

export const buttonFitClassNames = {
    tiny: 'px-[6px] py-[1px]',
    small: 'px-[11px] py-[5px] text-sm leading-5 h-8',
    medium: 'leading-6 px-4 py-2',
} as const;

export type ButtonVariants = keyof typeof buttonVariantsClassNames;
export type ButtonFit = keyof typeof buttonFitClassNames;

export interface BaseButtonBaseProps {
    variant?: ButtonVariants;
    loading?: boolean;
    fit?: ButtonFit;
    children: React.ReactNode;
    icon?: React.ReactNode;
    trailingIcon?: React.ReactNode;
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & BaseButtonBaseProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            variant = 'primary',
            loading = false,
            children,
            fit = 'medium',
            className,
            disabled,
            icon,
            trailingIcon,
            ...props
        },
        ref,
    ) => {
        return (
            <button
                ref={ref}
                className={cn(
                    'box-border flex items-center gap-1 rounded transition-all disabled:cursor-not-allowed disabled:opacity-50',
                    buttonVariantsClassNames[variant],
                    buttonFitClassNames[fit],
                    className,
                )}
                disabled={loading || disabled}
                {...props}
            >
                {icon && icon}
                {children}
                {trailingIcon && trailingIcon}
            </button>
        );
    },
);

type FakeButtonProps = HTMLAttributes<HTMLSpanElement> & BaseButtonBaseProps;

export const FakeButton = forwardRef<HTMLSpanElement, FakeButtonProps>(
    ({ variant = 'primary', children, fit = 'medium', className, icon, ...props }, ref) => {
        return (
            <span
                ref={ref}
                className={cn(
                    'box-border inline-block select-none rounded transition-all',
                    buttonVariantsClassNames[variant],
                    buttonFitClassNames[fit],
                    className,
                )}
                {...props}
            >
                {icon && <span className="mr-1">{icon}</span>}
                {children}
            </span>
        );
    },
);
