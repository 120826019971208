import { Children, PropsWithChildren } from 'react';

import { FlowStatusOption } from '@/constants/enums';
import { calculatePositionBackgroundColor } from '@/utils/calculateFlowPositionBackground';
import { cn } from '@/utils/cn';

type ComponentProps = {
    flowStatus?: FlowStatusOption;
};

export const TagContainerSmall = ({
    children,
    flowStatus = 'UNKNOWN',
}: PropsWithChildren<ComponentProps>) => {
    const numberOfChildren = Children.count(children);
    const empty = numberOfChildren < 1;
    const childrenToRender = numberOfChildren >= 1 ? children : '-';

    return (
        <div
            className={cn(
                'rounded text-center leading-none',
                calculatePositionBackgroundColor(flowStatus, numberOfChildren),
                {
                    'min-h-[20px] min-w-[36px]': numberOfChildren === 1 || empty,
                    'p-0': empty,
                    'p-1': !empty,
                },
            )}
        >
            {childrenToRender}
        </div>
    );
};
