import { SvgTimes } from '@maersk-global/apmt-react-icons';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';

type DrawerProps = {
    open: boolean;
    children: React.ReactNode;
    onClose?: () => void;
    className?: string;
    canBeClosed?: boolean;
};

export const Drawer = ({ className, open, children, onClose, canBeClosed = true }: DrawerProps) => {
    const [opened, setOpened] = useState(open);

    const onLocalClose = () => {
        setOpened(false);
        onClose?.();
    };

    useEffect(() => {
        setOpened(open);
    }, [open]);

    return (
        <div
            className={clsx(
                {
                    ['-translate-x-0 opacity-100']: opened,
                    [`translate-x-[100%] opacity-0`]: !opened,
                },
                `relative right-0 z-40 w-full overflow-y-auto border border-gray-200 bg-white shadow-lg transition-all duration-300 ease-in-out`,
                className,
            )}
        >
            {canBeClosed && (
                <button
                    className="absolute right-3 top-6 size-fit"
                    onClick={() => onLocalClose()}
                    aria-label="Close"
                >
                    <SvgTimes className="text-2xl" />
                </button>
            )}
            {children}
        </div>
    );
};
