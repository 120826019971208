import { SegmentedControl } from '@maersk-global/apmt-react-components';
import { SvgPin, SvgRotate } from '@maersk-global/apmt-react-icons';
import { useTranslation } from 'react-i18next';

import { FlowDetailMapOptions } from '@/constants/enums';

interface FlowDetailMapToggleProps {
    active: FlowDetailMapOptions;
    onClick: (type: FlowDetailMapOptions) => void;
}

export const FlowDetailMapToggle = ({ active, onClick }: FlowDetailMapToggleProps) => {
    const { t } = useTranslation();

    return (
        <SegmentedControl
            options={[
                {
                    value: FlowDetailMapOptions.MAP,
                    label: t('pages.flows.detailToggle.map'),
                    icon: <SvgPin className="mr-1" />,
                },
                {
                    value: FlowDetailMapOptions.VISUALISATION,
                    label: t('pages.flows.detailToggle.visualisation'),
                    icon: <SvgRotate className="mr-1" />,
                },
            ]}
            value={active}
            onValueChange={onClick}
        />
    );
};
