import { VisibilityState } from '@tanstack/react-table';

import { Title } from '@/components/atoms/Title/Title';
import { TruckInstructionsTable } from '@/components/organisms/TruckInstructionsTable/TruckInstructionsTable';
import { WorkQueueKind, WorkQueueMode, WorkQueueType } from '@/constants/enums';
import { YardWork } from '@/store/yardStore';
import { WorkQueues } from '@/types';

import { WorkQueueTitle, WorkQueueWrapper } from '../QuayCraneInstructions/WorkQueuWrapper';
import { RtgIntructionsList } from '../TruckInstructionsTable/RtgInstructionsList';
export type YardWorkQueues = { id: string; name: string }[];
export const WorkQueuesOverview = ({
    workQueues,
    yardWork,
    columnVisibilityOptions,
    quayCraneName,
    yardWorkQueues,
    consideredCompletedTruckNames,
    terminalId,
    vesselVisitId,
}: {
    workQueues: WorkQueues[];
    yardWorkQueues: YardWorkQueues;
    consideredCompletedTruckNames: string[];
    quayCraneName: string;
    yardWork: YardWork;
    vesselVisitId: string;
    terminalId: string;
    columnVisibilityOptions?: VisibilityState;
}) => {
    const workQueueIds = workQueues
        .map(wq =>
            wq.kind === WorkQueueKind.SINGLECYCLE ? [wq.id] : wq.idsAndLoadType.map(({ id }) => id),
        )
        .flat(1);
    const yardWorkNotInAnyQCQueue = Object.fromEntries(
        Object.entries(yardWork)
            .map(([k, cheWork]) => {
                return [
                    k,
                    cheWork.filter(c => {
                        return !workQueueIds.includes(c.workQueueId!);
                    }),
                ] as [string, YardWork[string]];
            })
            .filter(([, cheWork]) => {
                return cheWork.length > 0;
            }),
    );

    const workQueuesNotForQC = Object.values(yardWorkNotInAnyQCQueue).reduce((acc, n) => {
        n.forEach(({ workQueueId }) => {
            workQueueId && acc.add(workQueueId);
        });
        return acc;
    }, new Set<string>());

    return (
        <>
            {workQueues.map((workQueue, index) => {
                const { loadMode, kind } = workQueue;
                const isDualCycling = kind === WorkQueueKind.DUALCYCLE;
                const loadType = isDualCycling ? WorkQueueType.LOAD : workQueue.loadType;
                const isStrict =
                    loadType === WorkQueueType.LOAD && loadMode === WorkQueueMode.STRICT;
                const columnVisibilityDefaults = {
                    truckId: true,
                    instruction: true,
                    sentOn: true,
                    // Only for STRICT OR Dual Cycling with a STRICT workqueue
                    estimatedMoveTime: isStrict,
                };
                const columnVisibility = {
                    ...columnVisibilityDefaults,
                    ...columnVisibilityOptions,
                };
                const previousWorkQueue: WorkQueues | undefined = workQueues[index - 1];
                // The current dual cycle queue is paused when
                // the previous workqueue still has instructions that need to be handled
                const isPaused =
                    workQueue.kind === WorkQueueKind.DUALCYCLE &&
                    workQueues.length > 1 &&
                    previousWorkQueue?.instructionsLeft !== undefined &&
                    previousWorkQueue?.instructionsLeft >= 1;
                const id =
                    workQueue.kind === WorkQueueKind.DUALCYCLE
                        ? workQueue.idsAndLoadType.map(it => it.id).join('_')
                        : workQueue.id;
                const workQueuesForQueue =
                    workQueue.kind === WorkQueueKind.DUALCYCLE
                        ? workQueue.idsAndLoadType.map(wq => wq.id)
                        : [workQueue.id];
                return (
                    <div key={`${id}-${index}`} className="mb-5 last:mb-0">
                        <WorkQueueWrapper quayCraneName={quayCraneName} workQueue={workQueue}>
                            <TruckInstructionsTable
                                isPaused={isPaused}
                                quayCraneName={quayCraneName}
                                terminalId={terminalId}
                                vesselVisitId={vesselVisitId}
                                workQueue={workQueue}
                                consideredCompletedTruckNames={consideredCompletedTruckNames}
                                columnVisibilityOptions={columnVisibility}
                            />
                            <RtgInstructionsForWorkQueue
                                yardWork={yardWork}
                                workQueues={workQueuesForQueue}
                            />
                        </WorkQueueWrapper>
                    </div>
                );
            })}
            {new Array(...workQueuesNotForQC).map(workQueueId => {
                const title = yardWorkQueues?.find(({ id }) => workQueueId === id);
                return (
                    <div key={workQueueId}>
                        {title && (
                            <WorkQueueTitle
                                title={title.name}
                                isLoad={false}
                                isDischarge={false}
                                loadMode={WorkQueueMode.FLEXIBLE}
                                isDualCycling={false}
                            />
                        )}
                        <RtgInstructionsForWorkQueue
                            workQueues={[workQueueId]}
                            yardWork={yardWork}
                        />
                    </div>
                );
            })}
        </>
    );
};

const RtgInstructionsForWorkQueue = ({
    yardWork,
    workQueues,
}: {
    yardWork: YardWork;
    workQueues: string[];
}) => {
    const filteredYardWork: YardWork = Object.fromEntries(
        Object.entries(yardWork)
            .map(([k, cheWork]) => {
                return [
                    k,
                    cheWork.filter(c => {
                        return workQueues.includes(c.workQueueId!);
                    }),
                ] as [string, YardWork[string]];
            })
            .filter(([, cheWork]) => {
                return cheWork.length > 0;
            }),
    );

    return (
        <>
            {Object.entries(filteredYardWork).map(([che, work]) => {
                return (
                    <div key={che}>
                        <Title as="h3" className="text-sm uppercase text-gray-700">
                            {che}
                        </Title>
                        <RtgIntructionsList yardWork={work} />
                    </div>
                );
            })}
        </>
    );
};
