import { Andons } from '@/types/andons';
import { getAndonsForEquipment } from '@/utils/getAndonsForEquipment';

export const filterAllAndonsForQuayCrane = (
    andons: Andons,
    quayCraneName: string,
    stackingEquipment: { name: string }[],
): Andons => {
    const quayCraneAndon = andons.find(andon => andon.cheName === quayCraneName);
    const andonsForQuayCraneEquipment = getAndonsForEquipment(andons, stackingEquipment);
    const andonsForQuayCrane = quayCraneAndon
        ? [...andonsForQuayCraneEquipment, quayCraneAndon]
        : andonsForQuayCraneEquipment;

    return andonsForQuayCrane;
};
