import { FlowPositionName } from '@/constants/enums';
import { WorkType, YardWorkInstructions } from '@/types';
import { cn } from '@/utils/cn';

export enum TruckBubbleColors {
    InternalFull = '#38bdf8',
    InternalEmpty = '#bae6fd',
    ExternalFull = '#172554',
    ExternalEmpty = '#9ca3af',
    HousekeepingFull = '#00786B',
    HousekeepingEmpty = '#AAEAE0',
}

export const TruckBubble = ({
    truck,
    className,
    testid,
}: {
    truck: YardWorkInstructions['jobs'][number];
    className?: string;
    testid?: string;
}) => {
    return (
        <span
            data-testid={testid}
            key={truck.container}
            className={cn(
                'inline-block h-3 w-3 rounded-full border-2',
                getBubbleBackGround(truck),
                className,
            )}
            style={{
                borderColor: getBubbleBorder(truck),
                backgroundColor: getBubbleBackGround(truck),
            }}
        >
            <span className="sr-only">Truck</span>
        </span>
    );
};
export const TruckBubbles = ({
    position,
    trucks,
}: {
    position: FlowPositionName;
    trucks: YardWorkInstructions['jobs'];
}) => {
    return (
        <div data-testid={position} className="flex items-center gap-1">
            {trucks.map(truck => {
                if (truck.type === 'RTGHouseKeepingJob') {
                    return '-';
                }
                return (
                    <span
                        key={truck.container}
                        className={cn(
                            'inline-block h-3 w-3 rounded-full border-2',
                            getBubbleBackGround(truck),
                        )}
                        style={{
                            borderColor: getBubbleBorder(truck),
                            backgroundColor: getBubbleBackGround(truck),
                        }}
                    >
                        <span className="sr-only">Truck</span>
                    </span>
                );
            })}
        </div>
    );
};
const getBubbleBorder = (truck: YardWorkInstructions['jobs'][number]): string => {
    switch (truck.type) {
        case 'RTGHouseKeepingJob':
            return '#fff';
        case 'ExternalJob':
            return TruckBubbleColors.ExternalFull;
        case 'InternalJob':
            return TruckBubbleColors.InternalFull;
        case 'TruckHouseKeepingJob':
            return TruckBubbleColors.HousekeepingFull;
        default:
            // eslint-disable-next-line no-case-declarations
            const n: never = truck;
            throw new Error(`Exhaustiveness check failed [${n}]`);
    }
};
const getBubbleBackGround = (truck: YardWorkInstructions['jobs'][number]): string => {
    switch (truck.type) {
        case 'RTGHouseKeepingJob':
            return 'border-white bg-white';
        case 'ExternalJob':
            switch (truck.work) {
                case WorkType.PUT:
                    return TruckBubbleColors.ExternalFull;
                case WorkType.FETCH:
                    return TruckBubbleColors.ExternalEmpty;
                default:
                    // eslint-disable-next-line no-case-declarations
                    const n: never = truck.work;
                    throw new Error(`Exhaustiveness check failed [${n}]`);
            }
        case 'InternalJob':
            switch (truck.work) {
                case WorkType.PUT:
                    return TruckBubbleColors.InternalFull;
                case WorkType.FETCH:
                    return TruckBubbleColors.InternalEmpty;
                default:
                    // eslint-disable-next-line no-case-declarations
                    const n: never = truck.work;
                    throw new Error(`Exhaustiveness check failed [${n}]`);
            }
        case 'TruckHouseKeepingJob':
            switch (truck.work) {
                case WorkType.PUT:
                    return TruckBubbleColors.HousekeepingFull;
                case WorkType.FETCH:
                    return TruckBubbleColors.HousekeepingEmpty;
                default:
                    // eslint-disable-next-line no-case-declarations
                    const n: never = truck.work;
                    throw new Error(`Exhaustiveness check failed [${n}]`);
            }
        default:
            // eslint-disable-next-line no-case-declarations
            const n: never = truck;
            throw new Error(`Exhaustiveness check failed [${n}]`);
    }
};
