import { SecondaryInfo } from '@/components/atoms/SecondaryInfo/SecondaryInfo';
import { Title } from '@/components/atoms/Title/Title';

export const QuayCraneTitle = ({
    quayCraneName,
    vesselVisitId,
}: {
    quayCraneName: string;
    vesselVisitId?: string;
}) => {
    return (
        <div className="flex items-baseline gap-2">
            <Title className="mds-font--display-1">{quayCraneName}</Title>
            {vesselVisitId && <SecondaryInfo size="large">{vesselVisitId}</SecondaryInfo>}
        </div>
    );
};
